
// file methods.ts
import { FIELD } from "./models/fields/field.model";
import { AlinoPDF, SPACER } from "./pdf.class";
import { FIELD_TYPE } from "./config"
import { TEXTFIELD } from "./models/fields/textfield.model";
import { ADDRESS } from "./models/fields/address.model";
import { CONTACT } from "./models/fields/contact.model";
import { INVENTORY } from "./models/fields/inventory.model";
import { IMAGE } from "./models/fields/image.model";
import { BOOLEAN } from "./models/fields/boolean.model";
import { PDF_DATA } from "./models/data.model";
import { CHECKLIST } from "./models/fields/checklist.model";
import { DATETIME } from "./models/fields/datetime.model";
import { GROUP } from "./models/fields/group.model";
import { SCHEDULE } from "./models/fields/schedule.model";
import { CHECKBOX } from "./models/fields/checkbox.model";
import { RADIO } from "./models/fields/radio.model";
import { SELECTOR } from "./models/fields/selector.model";


interface PREVIOUS_ELEMENT {
    boolean: boolean, 
    index: number;
    group: boolean

}

export async function renderData(this: AlinoPDF, data: PDF_DATA, prevId: (string)): Promise<void> {

    const prevData: PREVIOUS_ELEMENT = {
        boolean: false,
        index: 0,
        group: false
    }

    for (let index = 0; index < data.children.length; index++) {
        await this.wrapper(data.children[index], prevData, prevId)
      }

}
export async function wrapper(this: AlinoPDF, child: FIELD, prev: PREVIOUS_ELEMENT, prevId: (string)): Promise<void> {

    if (child == null || child.type == FIELD_TYPE.SIGNATURE) return;
    if (child != null && prev.group == true && child.type != FIELD_TYPE.GROUP) {

        if (this.position.y > this.layout.innerTop && this.position.y + 10 < this.layout.outerHeight - this.layout.innerBottom) {
            this._divider(this.config.color.secondary);
            this.position.y += SPACER.VERTICAL + 2;
        }

        prev.group = false;
    }

    this.position.y += 1;
    
    // const startY = this.position.y; 
    switch (child.type) {


        case FIELD_TYPE.INFORMATION: 
            await this.information((child as any)); 
            break;

        case FIELD_TYPE.RADIOS: 
            await this.radio((child as RADIO)); 
            break;

        case FIELD_TYPE.SELECTOR: 
            await this.selector((child as SELECTOR)); 
            break;
        case FIELD_TYPE.CHECKBOX: 
            await this.checkbox((child as CHECKBOX))
            break;

        case FIELD_TYPE.SCHEDULE: 
            await this.schedule((child as any));
            break;

        case FIELD_TYPE.GROUP: 

            prev.index += 1; 
            
            await this.group((child as GROUP), prevId, prev.index); 
            prev.group = !prev.group;
            break;
            
        // textfield & textarea
        case FIELD_TYPE.TEXTAREA:
        case FIELD_TYPE.TEXTFIELD:
            await this.textfield((child as TEXTFIELD), this.layout.innerWidth)
            break;
        
        case FIELD_TYPE.DATE: 
            await this.datetime((child as DATETIME));
            break;
        
        case FIELD_TYPE.ADDRESS: 
            await this.address((child as ADDRESS));
            break;

        case FIELD_TYPE.CONTACT: 
            await this.contact((child as CONTACT));
            break;
        
        case FIELD_TYPE.USER: 
            await this.user(child as any);
            break;

        case FIELD_TYPE.INVENTORY: 
            await this.inventory((child as INVENTORY))
            break;

        case FIELD_TYPE.IMAGE: 
            await this.image((child as IMAGE))
            break;

        case FIELD_TYPE.CHECKLIST: 
            await this.checklist((child as CHECKLIST))
            break;
        
        case FIELD_TYPE.BOOLEAN: 
            await this.boolean((child as BOOLEAN), prev.boolean);
            prev.boolean = !prev.boolean;

            break;

        
    
        default:
            break;
    }
    
  return;
}

// function setValue(this: AlinoPDF, value: string ) {
//    this.value = value;
// }
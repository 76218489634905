
import { Vue, Watch, Component } from 'vue-property-decorator';
import TextButton from "@components/button/TextButton.vue";

@Component({
    components: {
        TextButton
    }
})

export default class OrganisationLoadingScreen extends Vue {

    // Flag to show or hide the reload button
    showLoadingTimeout=false;
    // Timer to control when to show the reload button
    loadingTimer: number|undefined=undefined;


    @Watch('$store.getters.loadingPage', { immediate: true })
    onLoadingChange(): void {
        if (this.$store.getters.loadingPage) {
            // Start the timer
            this.showLoadingTimeout=false;
            this.loadingTimer=setTimeout(() => {
                if (this.$store.getters.loadingPage) {
                    this.showLoadingTimeout=true;
                }
            }, 6500) as never;
        } else {
            // Clear the timer
            clearTimeout(this.loadingTimer as number);
            this.showLoadingTimeout=false;
        }
    }

    // Reload the page
    reloadPage(): void {
        location.reload();
    }
}

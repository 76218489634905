
import { Prop, Vue, Component } from 'vue-property-decorator';
import IconButton from '@components/button/IconButton.vue';
import UserProfilePicture from '../auth/UserProfilePicture.vue';
// import VariableLink from '@/components/editor/configurator/helper/VariableLink.vue';


@Component({
    components: {
        UserProfilePicture,
        IconButton
    },
})

export default class LogDateAuthor extends Vue {

    @Prop() author!: string;
    @Prop() uid!: string
    @Prop() lastEdited!: string | number
    @Prop() email!: string;
    @Prop() variable!: string
    @Prop() finishedAt!: Date;
    formatDate(date: number | string): string {
        const event = new Date(date);

        const options = {
            year: "numeric",
            month: 'short',
            day: "numeric",
        };

        return event.toLocaleTimeString(
            `de-de`,
            { ...options, hour: "2-digit", minute: "2-digit" } as any
        ) + ' Uhr'
    }

    get userName(): string {

const user=this.$store.getters.members.user[this.author]
console.log(user)
if (user == null || user.displayName == null) return '-'
const name = user.displayName;

if (name.length<16) return name;
return `${name.split(' ')[0][0].toUpperCase()}. ${name.split(' ')[(name.split(' ').length-1)]}`
}
}

import { CONTACT } from "../models/fields/contact.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";


const CONTACT_BREAK_HEIGHT = 60

/**
 * address 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (ADDRESS) address input object and config data
 */
export async function contact(this: AlinoPDF, data: CONTACT):  Promise<void> {

  // address field is always showed as one field; if remaining space less than max field height
  // page is broken and field is displayed on new page
  if (this.position.y + CONTACT_BREAK_HEIGHT > (this.layout.outerHeight - this.layout.innerBottom)) {
    
    // create new page;
    await this.create(null, true)
    this.position.y = this.layout.innerTop;
  }
  
  this.position.y += 1; 
  this.position.x = this.config.page.padding.left; 

  // field header
  if (data.header != null && data.header.length > 0) {
    this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
    this.docObj.text(data.header.toUpperCase(), this.position.x, this.position.y);

    this.position.y += 7;
  } else {
    this.position.y += 2.75
  }
  

  // contact name textfield
  await this.textfield(({header: 'Ansprechpartner', value: data.value.contactName} as TEXTFIELD))
  this.position.y -= 2.5; // reduce height between fields in combined field 
  

  // company name textfield
  if (data.value.company != null && data.value.company.length > 0) {
    await this.textfield(({header: 'Unternehmen', value: data.value.company} as TEXTFIELD))
    this.position.y -= 2.5; // reduce height between fields in combined field 
  }
  


  const prevYPosition = this.position.y; // save y-position as a row is creeated


  const textfieldWidth = this.layout.innerWidth/2 - SPACER.HORIZONTAL/2
  // phone textfield
  await this.textfield(({header: 'Telefonnummer', value: (data.value.phone || '-')} as TEXTFIELD), textfieldWidth)

  
  this.position.y = prevYPosition; // reset to previous row y-position
  this.position.x = this.config.page.padding.left + textfieldWidth + SPACER.HORIZONTAL; // offset to mail field

  // city textfield
  await this.textfield(({header: 'Mail-Adresse', value: (data.value.mail || '-')} as TEXTFIELD), textfieldWidth)

  this.position.y -= 2;
  
  // add margin to file position
  this.position.y +=  SPACER.VERTICAL;
  this.position.x = this.config.page.padding.left;


}

import { ORGANISATION_ELEMENT } from "@/models/api/organisation.model";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        

        organisations: [],


        loadingPage: false,

        organisation: {},

        members: {
            user: {},
            groups: {}
        },

        report: {},

        styling: {
            id: "",
            logo: "",
            colors: "",
            legal: [],
            name: "",
            address: {
                city: "",
                street: "",
                zip: "",
            },
        },

        templateErrors: [],

        logo: "",
    },

    mutations: {
        setUserOrganisations(
            state: any,
            organisations: ORGANISATION_ELEMENT[]
        ): void {
            console.log("[VUEX] set user organisations: " + organisations.length)
            state.organisations = organisations || [];
        },

        setOrganisationData(
            state: any,
            organisation: ORGANISATION_ELEMENT
        ): void {
            state.organisation = JSON.parse(JSON.stringify(organisation));
            delete state.organisation.members;

            state.members.user = organisation.members
        },


        setTemplateErrors(state, errors): void {
            state.templateErrors = errors;
        },
        setLoadingPage(state, loadingStatus): void {
            console.log("SET PAGE", loadingStatus);
            state.loadingPage = loadingStatus;
        },
        report(state, reportObj): void {
            state.report = reportObj;
        },

        setOrganisationMemberGroups(state, memberGroups) {
            state.members.groups = memberGroups;
        },

        setOrganisationStyling(state, styling) {
            state.styling = styling;
        },

        setOrganisationLogo(state, logoUrl): void {
            state.logo = logoUrl;
        },
    },
    actions: {
        
    },
    getters: {
        templateErrors(state): any[] {
            return state.templateErrors;
        },
        loadingPage(state): boolean {
            return state.loadingPage;
        },
        organisations(state): any {
            return state.organisations;
        },

        report(state): any {
            return state.report;
        },

        styling(state): any {
            return state.styling;
        },


        logo(state): string {
            return state.logo;
        },
        
        organisation(state): ORGANISATION_ELEMENT {
            return state.organisation as ORGANISATION_ELEMENT;
        },

        
        members(state): any {
            return state.members;
        },

        colors(state): any {
            return (state.organisation as any).general.colors;
        },
    },
    modules: {},
});

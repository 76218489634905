<template>
  <div  style="height: 100%; width: 100%; position: relative;" :style="readonly ? '  user-drag: none!important' : ''">
    <v-overlay opacity=".7" absolute :value="!center">

      <NoDataTemplate iconColor="white" text="Keine Kartendaten vorhanden." icon="mdi-map-marker-off"/>
    </v-overlay>
      <!-- <div v-show="address != null" class="elevation-2 pl-3 pr-4" style="height: 40px; user-select: none;  background-color: rgba(240, 240, 240, 1); z-index: 10; top: 10px; left: 10px;  position: absolute; border-radius: 10px; line-height: 40px; text-align: center;">
        <v-icon class="mr-1" style="margin-bottom: 3px">mdi-map-marker</v-icon><span class="font-weight-bold">{{address.street}}</span>, <span class="ml-1">{{address.zip}} {{address.city}}</span>
      </div> -->
      <!-- {{ path }} -->
  
  <GmapMap
  ref="mapRef"

                :center="centerPos == null ? { lat: 48.135029, lng: 11.581935 } : centerPos"
                :zoom="zoomLevel"
                :options="{
                    draggable: !readonly,
                    scrollwheel: !readonly,
                    fullscreenControl: !readonly,
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  disableDefaultUi: false,
                  styles: mapStyle,
                }"
                map-type-id="terrain"
                :style="!center ? 'opacity: .3' : ''"
                style=" border-radius: 10px!important; height: 100%; width: 100%;"
              >

              <gmap-polyline
              v-if="path != null && path.length > 0"
              v-bind:options="{ strokeColor:'#CC202955'}"
      :path="path"
      :editable="false"
      ref="polyline">
    </gmap-polyline>

    <gmap-custom-marker
              
              v-for="(center, index) in (path || [])" :key="index"
              :marker="center"
              v-show="path.length > 0"
              class="mt-2 ml-0"
            >


            <v-menu offset-x offset-y nudge-bottom="10" open-on-hover nudge-right="10">
  <template v-slot:activator="{ on, attrs }">
              <div
                style=""
                class="primary white--text font-weight-bold path-marker"
      v-bind="attrs"
      v-on="on"
      v-on:click="focusPathMarker(center)"
              >
            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                {{ index + 1 }}
                </div>
            </div>
            </template>
            <div class="ma-0 white" style="max-width: 250px">
            <slot name="tooltip" :item="center"/>
            </div>
            </v-menu>
            </gmap-custom-marker>
                <gmap-custom-marker
                  v-if="(path == null || path.length == 0) && marker == true"
                  :marker="center"
                >
                  <div
                    style="
                      height: 30px;
                      width: 30px;
                      border-radius: 15px;
                      cursor: default;
                    "
                    class="primary"
                  ></div>
                </gmap-custom-marker>


               
              </GmapMap>

      </div>

</template>

<script>
import NoDataTemplate from '../page/NoDataTemplate.vue'
import GmapCustomMarker from "vue2-gmap-custom-marker";

import {gmapApi} from 'vue2-google-maps' 

  export default {
    props: {
        path: {
            default: null
        },
      address: {
        type: Object
      },
      marker: {
        type: Boolean,
        default: false
      },
      center: {
        type: Object,
        default: undefined
      },
      readonly: {
        type: Boolean, 
        default: false
      },

      zoom: {
        type: Number,
        default: 12
      },
      animation: {
        type: Boolean, 
        default: false
      }
    },
    components: {
      NoDataTemplate,
    //   VueGoogleMaps,
      "gmap-custom-marker": GmapCustomMarker,
      // GmapCustomMarker
    },

    mounted() {
        this.zoomLevel = this.zoom - (this.animation == true ? 2 : 0);
        this.centerPos = this.center
        this.$refs.mapRef.$mapPromise.then(async (map) => {
            this.map = map;
            
            setTimeout(() => {
                this.zoomLevel = this.zoom;
            }, 100);
        });
    },

    data() {
      return {

        centerPos: null,
        zoomLevel: 12,

        map: null,
        mapStyle: [
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.attraction",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.government",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.medical",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi.place_of_worship",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.school",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      }
    },
   
    methods: {
        async focusPathMarker(marker) {
            
            this.centerPos = marker

            this.zoomLevel = 17; 
            await this.$nextTick()
            this.zoomLevel = 18;
            // let b = new this.google.maps.LatLngBounds();

            // b.extend({
            //   lat: 33.972,
            //   lng: 35.4054
            // });
            // b.extend({
            //   lat: 33.7606,
            //   lng: 35.64592
            // });
            console.log(this.map, this.$refs.map, marker, gmapApi)
        }
    }

  }

</script>


<style scoped>
.path-marker {
    height: 20px;
    width: 20px;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    transition: transform 150ms linear;

}

.path-marker:hover {
    transform: scale(1.2);
    transition: transform 150ms linear;
}
</style>
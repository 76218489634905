<template>
  <div style="position: relative; width: 100%; max-width: 500px;" >

    <v-menu :disabled="(readonly == true)" style="position: relative; width: 100px!important" >
      <template v-slot:activator="{ on, attrs }">
        <div class="role-selector" :style="(readonly == true) ? 'cursor: default!important; pointer-events: none' : ''" v-bind="attrs" v-on="on">

          <v-hover v-slot="{ hover }">
            <v-row class="ma-0" style="padding-top: 10px" align="center">
              <span class="ml-5 font-weight-bold" style="font-size: 15px; user-select: none; color: rgba(0, 0, 0, .8)">
                <div  style=" position: absolute; top: 50%; transform: translateY(-50%); left: 20px">

                <span v-if=" noSelection == true && selectedItem == 0" class="grey--text" style="font-weight: 500; text-transform: uppercase; font-size: 14px; letter-spacing: 1px;">{{noSelectionText}}</span>

                  <span v-else  :class="highlight == true ? 'primary--text' : 'black--text'" style="overflow: hidden;">
               
                    <v-icon v-if="icon == true && data[selectedItem + (noSelection == true ? -1 : 0)].icon.startsWith('mdi-')" class="mr-2" style="margin-bottom: 2px" :color="highlight == true ? 'primary' : 'black'" size="20">{{data[selectedItem + (noSelection == true ? -1 : 0)].icon}}</v-icon>
                    
                    <span style="position: relative; width: 100%; overflow: hidden!important;  white-space: nowrap;  text-overflow: ellipsis;">{{data[selectedItem + (noSelection == true ? -1 : 0)] != null ? data[selectedItem + (noSelection == true ? -1 : 0)].text : ''}}</span> </span>
                </div>
                <!-- <div v-else>
                  <v-icon v-if="icon == true" class="mr-2" style="margin-bottom: 2px" :color="highlight == true ? 'primary' : 'black'" size="20">{{data[selectedItem].icon}}</v-icon>

                  <span :class="highlight == true ? 'primary--text' : 'black--text'" style="font-size: 14px" class=" ml-1 sub-text">{{data[selectedItem].text}}</span>
                </div> -->
              </span>
              <v-spacer />
              <v-icon v-if="(readonly != true)" size="20" class="mr-4" :class="hover ? 'primary--text' : ''">mdi-chevron-down</v-icon>
            </v-row>
          </v-hover>

        </div>
      </template>
      <div style="position: relative; max-width: 500px; ">
      <v-list nav  style="padding-top: 5px!important; padding-bottom: 5px!important; width: 100%!important; position: relative;  max-height: 90vh; overflow-y: auto;">


        <v-list-item-group v-model="selectedItem" style="width: 100%">
          <v-list-item v-if="noSelection == true"
            :style="selectedItem == 0 ? 'cursor: default!important; pointer-events: none!important' : '' " dense
            :ripple="false">
            <v-list-item-title class="ml-1 grey--text" style="font-size: 15px">Keine Auswahl</v-list-item-title>
          </v-list-item>

          <v-list-item
          :disabled="type.disabled"
          style="width: 100%!important;"
            :style="selectedItem == (noSelection == true ? index + 1 : index) ? 'cursor: default!important; pointer-events: none!important' : ''"
            dense :ripple="false" v-for="(type, index) in data" :key="index">
        
            
            <v-list-item-content>
                <div class="row ma-0 align-center">
                    <v-icon v-if="icon == true && type.icon.startsWith('mdi-')" class="mr-2 ml-1 mt-1" :color="selectedItem == (noSelection == true ? index + 1 : index) ? 'primary' : ''" style="margin-bottom: 2px"  size="20">{{type.icon}}</v-icon>
<slot v-else-if="icon == true" name="list-icon" :icon="type.icon" />
            <div class="ml-1 pb-0"
              :class="selectedItem == (noSelection == true ? index + 1 : index)  ? 'primary--text font-weight-bold' : ''"
              style="font-size: 15px">{{type.text}} 
                </div>
            </div>

            <v-list-item-icon v-show="outerIcon == true">
             <slot name="append" :value="type" /> 
            </v-list-item-icon>

            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </div>
    </v-menu>
  </div>
</template>


<script>

export default {
  props: {
    value: {
      default: null
    },
    data: {
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    noSelection: {
      type: Boolean,
      default: true
    },
    outerIcon: {
      type: Boolean, 
      default: false
    },
    noSelectionText: {
      type: String,
    }, 
    highlight: {
      type: Boolean, 
      default: false
    }, 
    icon: {
      type: Boolean, 
      default: false
    }
  },

  watch: {
    value: {
      handler() {

        if (this.value != null) {
          var index = this.data.findIndex(datum => datum.id == this.value)

          if (index == null || index == -1) return (this.selectedItem = 0)

          if (!this.noSelection) return (this.selectedItem = index)
          else return (this.selectedItem = index + 1)
        } else {
          this.selectedItem = 0
        }
      }, immediate: true, deep: true
    },
    selectedItem() {
      if (this.selectedItem == null || (!this.noSelection && (this.data[this.selectedItem] == null || this.data[this.selectedItem].id == null)) || (this.noSelection == true && (this.data[this.selectedItem - 1] == null || this.data[this.selectedItem - 1].id == null))) return this.$emit('update')
      if (!this.noSelection) return this.$emit('update', this.data[this.selectedItem].id || null)
      else return this.$emit('update', this.data[this.selectedItem - 1].id || null)
    }
  },
  data() {
    return {
      selectedItem: 0,

    }
  }

}

</script>


<style>
.role-selector {
  height: 40px;
  width: 100%;
  min-width: 100%;
  background-color: #F0F0F0;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 200ms linear;
}

.role-selector:hover {
  background-color: #E0E0E0;
  transition: background-color 200ms linear;
}
</style>
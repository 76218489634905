

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import TextButton from '@components/button/TextButton.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue'
@Component({
  components: {
    TextInput,
    TextButton,
    FieldHeader
  },
})


export default class TimeSelectorField extends Vue {

  @Prop() value!: any
  @Prop() showMissing!: boolean;
  @Prop() readonly!: boolean

  dateValue: any | null = null;
  dateDialog = false;
  dateDialogValue = null;

  update(): void {
    // this.value.value = eventHandle; 
    this.value.lastEdited = (+ new Date());

    const user = this.$firebase.auth.user
    this.value.lastEditor = { email: user?.email, displayName: user?.displayName, uid: user?.uid }
  }
  formatDateRange(start: number, end: number): string {
    var startDate = this.formatDate(start, false, false);

    if (new Date(start).getFullYear() === new Date(end).getFullYear()) {
      startDate = startDate.replace(
        String(new Date(start).getFullYear()),
        ""
      );
    }
    var endDate = this.formatDate(end, false, false);

    return `${startDate} - ${endDate}`;
  }

  formatDate(dateHandle: number, weekday = true, time = true): string {
    const event = new Date(dateHandle);

    var options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };



    if (time == true) {
      return event.toLocaleTimeString(
        `de-de`,
        { ...options, hour: "2-digit", minute: "2-digit" } as any
      )
    } else {
      return event.toLocaleDateString(
        `de-de`,
        { ...options, weekday: "short" } as any
      );
    }
  }

  isValidDate(d: Date): boolean {
    return d instanceof Date && !isNaN(d as any);
  }

  get selectedDate(): string {

    if (this.value.options.isRange == false) {
      if (this.value.value.date == null) return '';
      const dateString = String(this.formatDate(this.value.value.date, false, false))
      if (dateString == 'Invalid Date') return ''
      else return dateString;
    }
    else {

      if (this.value.value.date.start == null || this.value.value.date.start.length == 0 || this.value.value.date.end == null || this.value.value.date.end.length == 0) return '';

      const dateRangeString = String(this.formatDateRange(this.value.value.date.start, this.value.value.date.end));
      if (dateRangeString.includes('Invalid')) return ''
      else return dateRangeString;

    }
  }

  @Watch('value', { immediate: true })
  onInputChange(): void {
    if (this.value == null || this.value.value == null) return;

    if (this.value.options.isRange == true) {
      this.dateValue = {
        start: new Date(this.value.value.date.start),
        end: new Date(this.value.value.date.end)
      } as any
    } else {
      this.dateValue = new Date(this.value.value.date) as any
    }
  }

  @Watch('dateValue', { deep: true, immediate: true })
  onValueChange(): void {
    this.dateDialogValue = this.dateValue;
    if (this.dateValue == null || this.value == null) return;

    if (this.value.options.isRange == true) {
      this.value.value.date = {
        start: (+ new Date((this.dateValue as any).start as Date)),
        end: (+ new Date((this.dateValue as any).end as Date))
      }
    } else {

      this.value.value.date = (+ new Date(this.dateValue as any))
    }


  }
}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { getRegFromString } from '@/plugins/helper';
import { TEXTINPUT_FIELD } from '@/models/exports/pdf/fields/textinput.model';

@Component({
  components: {
    TextInput,
    FieldHeader

  },
})


export default class TextInputField extends Vue {

  @Prop() value!: TEXTINPUT_FIELD
  @Prop() readonly!: boolean
  @Prop() showMissing!: boolean

  get isValidTest(): Array<string> {

    let isValidResult = false;
    try {
      const res = (String(this.value.value)
        .toLowerCase()
        .match(
          getRegFromString(this.value.options.regex
          )))

      if (res != null) isValidResult = true
      else isValidResult = false

    } catch {
      isValidResult = false;
    }


    if (isValidResult) {
      return [this.value.value]
    }
    else return []
  }

  async update(eventHandle: string): Promise<void> {

    if (this.readonly == true) return; 
    
    this.$report.updateField({...this.value, value: eventHandle})
  }


  
}

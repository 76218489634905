

import Vue from 'vue';
import OrganisationLoadingScreen from '@/components/organisation/OrganisationLoadingScreen.vue';

export default Vue.extend({

    name: "App",
    components: { OrganisationLoadingScreen }
});

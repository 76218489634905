

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as PinchZoom from '../../../../node_modules/pinch-zoom-js/dist/pinch-zoom.min.js';
import imageZoom from 'vue-image-zoomer';



@Component({
    components: {
        imageZoom
    },
})




export default class ImageViewer extends Vue {
    @Prop() src!: string
    @Prop() zoom!: number



    mobileZoom: any | null = null; 

    mounted(): void {
        if (!(this.$vuetify.breakpoint.xsOnly||this.$vuetify.breakpoint.smOnly)) return;
        this.mobileZoom = new PinchZoom.default(document.getElementById('pinch-zoom-image'));
    }
   

    beforeDestroy() {
        if ((this.$vuetify.breakpoint.xsOnly||this.$vuetify.breakpoint.smOnly)) this.mobileZoom.destroy(); 
    }
}

import { ORGANISATION_ELEMENT } from "@/models/api/organisation.model";
import { imageBase64 } from "@/plugins/helper";
import store from "@/store";
import Vue from "vue";
import { nextTick } from "vue/types/umd";
import { register } from '@/plugins/firebase/firebase.messaging'
export async function loadUserOrganisationData(to: any, from: any, next: any, resetLoading = true): Promise<void> {


    console.log("KOMME DURCH", to.params)

    try {

          
            
        if (!(from.params.organisationId == to.params.organisationId)){
        

            store.commit("setLoadingPage", true);

            // get all organisation of the current user
            const userOrganisationsArr = await Vue.prototype.$organisations.get();

            // verify if route organisation is present in user organisations list; otherwise 
            // user is forwarded to organisations overview page
            if (!userOrganisationsArr.some((org: ORGANISATION_ELEMENT) => org.id == to.params.organisationId)) throw new Error('Organisation not found')


            const organisation = await Vue.prototype.$organisations.getById(to.params.organisationId);
        
            if (organisation == null || organisation.members == null || organisation.members[Vue.prototype.$firebase.auth.uid as string] == null) throw new Error()
            
            document.title = document.title.split(' - ')[0] + " - " + store.getters.organisation.general.name
            await Vue.prototype.$labels.getMemberLabels(to.params.organisationId)
       
            if (organisation.members[Vue.prototype.$firebase.auth.uid as string] == null || organisation.members[Vue.prototype.$firebase.auth.uid as string].role == -1) return next({name: 'organisations'})
            if (organisation.members[Vue.prototype.$firebase.auth.uid as string].invite == true && to.name != 'organisation-inbox') return next({name: 'organisation-inbox'})

            
            if (resetLoading == true) await Vue.prototype.$organisations.styling(to.params.organisationId)
            

            // register()
        }

        next();

    } catch (error) {
        console.log(error)
        store.commit("setLoadingPage", false);
        return next({name: 'organisations', query: {error: 'organisation/network'}})
    }
       
   
    
    if (!resetLoading) return; 
    setTimeout(() => {
        store.commit("setLoadingPage", false);

    }, 500);


}
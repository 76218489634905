

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { getRegFromString } from '@/plugins/helper';
import { TEXTINPUT_FIELD } from '@/models/exports/pdf/fields/textinput.model';
import UserSelector from '@/components/helper/UserSelector.vue';
import { USER_FIELD } from '@/models/exports/pdf/fields/userselector.model';

@Component({
  components: {
    TextInput,
    FieldHeader,
    UserSelector

  },
})


export default class UserSelectionField extends Vue {

  @Prop() value!: USER_FIELD;
  @Prop() readonly!: boolean
  @Prop() showMissing!: boolean

  update(eventHandle: string): void {
    this.$report.updateField({...this.value, value: eventHandle})

  }
}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { RADIOS_FIELD } from '@/models/exports/pdf/fields/radios.model';
import { replaceTextVariables } from '@/plugins/variables.helper';

@Component({
  components: {
    FieldHeader
  },
})


export default class RadioSelectionField extends Vue {

  @Prop() value!: RADIOS_FIELD
  @Prop() readonly!: boolean
  @Prop() showMissing!: boolean;
  
  

  get elements(): any {
    return this.value.options.elements.map((ele: any) => ({...ele, text: (this.$store.getters.report == null || this.$store.getters.report.id == null ? ele.text : replaceTextVariables(this.$store.getters.report, ele.text))}))
  }

  update(id: number): void {
    this.$report.updateField({...this.value, value: id})

  }
}

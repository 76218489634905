import _Vue from "vue";
import { uuidv4 } from "@firebase/util";

import {
    getDocs,
    collection,
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    doc,
    getDoc,
    setDoc,
    onSnapshot,
    query,
    where,
    runTransaction,
} from "firebase/firestore";
import { currentUser, firebaseFirestore } from "../firebase";
import store from "@/store/index";
import { ORGANISATION_ELEMENT } from "@/models/api/organisation.model";
import { getAuth, updateProfile, User } from "firebase/auth";
import axios from "axios";
import router from "@/router";
import Vue from "vue";
import { imageBase64 } from "../helper";
import { ORGANISATION_ROLE } from "@/config/organisation.roles";

declare module "vue/types/vue" {
    export interface Vue {
        $organisations: OrganisationsPlugin;
    }
}

class OrganisationsPlugin {
    groupCollection = collection(
        firebaseFirestore,
        "organisations"
    ).withConverter(new OrganisationsConverter());

    unsubscribeWatcher = null;

    static install(Vue: typeof _Vue) {
        Vue.prototype.$organisations = new OrganisationsPlugin();
    }

    async styling(organisationId: string): Promise<void> {
        // if (
        //     store.getters.styling.id ==
        //     router.currentRoute.params.organisationId
        // )
        //     return;

        const resp = await axios.get(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/${organisationId}/styling`,
            {
                headers: {
                    Authorization:
                        await Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );

        if ((resp as any).status != 200 || (resp as any).data.error == true) {
            throw new Error();
        }
        

        let logo = null;
        if (
            !(
                resp.data.logo == null ||
                resp.data.logo.path == null ||
                resp.data.logo.path.length <= 1
            )
        ) {
            logo = await this.loadOrganisationLogo(resp.data.logo.path);
        } else {
            store.commit("setOrganisationLogo", null);
        }

        store.commit("setOrganisationStyling", {
            ...resp.data,
            logo: { ...resp.data.logo, url: logo },
        });
    }

    async loadOrganisationLogo(logoPath: string): Promise<any> {
   
        if (logoPath == null || logoPath.length == 0) return null;
        let logo = null;
        try {
            logo = await Vue.prototype.$firebase.storage.get(logoPath);

            if (logo != null) {
                store.commit("setOrganisationLogo", logo);
            }
        } catch (error) {
            store.commit("setOrganisationLogo", null);
            console.error(error);
        }

        return logo;
    }
    async get(): Promise<any[]> {
        const q = query(
            this.groupCollection,
            where("users", "array-contains", _Vue.prototype.$firebase.auth.uid)
        );

        const snapshot = await getDocs(q);

        const data =
            snapshot.docs.map((doc) => ({
                name: (doc.data() as ORGANISATION_ELEMENT).general.name,
                members: doc.data().members,
                id: (doc.data() as ORGANISATION_ELEMENT).id,
                logo: (doc.data() as ORGANISATION_ELEMENT).general.logo,
            })) || [];

        store.commit("setUserOrganisations", data as any[]);
        return data;
    }

    async create(id: string, name: string): Promise<void> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations"
        ).withConverter(new OrganisationsConverter());

        if ((await getDoc(doc(organisationCollection, id))).exists())
            throw new Error("id-exists");
        setDoc(doc(organisationCollection, id), {
            inventory: {
                file: null,
                identifier: {
                    id: "",
                    group: "",
                    name: "",
                    type: "",
                },
            },
            general: {
                name: name,
                descr: "",
                avatar: "personas",
                colors: {
                    grey: "",
                    primary: "",
                    secondary: "",
                },
                address: {
                    street: "",
                    city: "",
                    zip: "",
                },
                logo: null,

                export: {
                    padding: {
                        top: 20,
                        bottom: 20,
                        left: 20,
                    },
                    legal: [
                        { header: "", 0: "", 1: "", 2: "", 3: "", 5: "" },
                        { header: "", 0: "", 1: "", 2: "", 3: "", 5: "" },
                        { header: "", 0: "", 1: "", 2: "", 3: "", 5: "" },
                        { header: "", 0: "", 1: "", 2: "", 3: "", 5: "" },
                    ],
                },
            },

            members: {
                [_Vue.prototype.$firebase.auth.uid as string]: {
                    displayName:
                        _Vue.prototype.$firebase.auth.user.displayName || "",
                    invite: false,
                    inviteTime: +new Date(),
                    mail: _Vue.prototype.$firebase.auth.user.email || "",
                    role: ORGANISATION_ROLE.ADMIN,
                },
            },
        } as any);
    }

    async inviteUser(userObj: any): Promise<void> {
        const resp = await axios.get(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/auth?role=${
                userObj.role
            }&mail=${userObj.mail}&organisationId=${
                router.currentRoute.params.organisationId
            }&url=${String(
                new URL("/organisations", window.location.origin).href
            )}`,
            {
                headers: {
                    Authorization:
                        await Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );

        if ((resp as any).status != 200 || (resp as any).data.error == true)
            throw new Error();
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations"
        ).withConverter(new OrganisationsConverter());
        // console.log(resp)
        const organisationData = {
            ...store.getters.organisation,
            members: {
                ...store.getters.members.user,
                [(resp as any).data.uid as string]: {
                    mail: userObj.mail,
                    role: userObj.role,
                    invite: true,
                    inviteTime: +new Date(),
                    // deleted: true,
                    displayName: (resp as any).data.displayName,
                },
            },
        };
        setDoc(
            doc(
                organisationCollection,
                router.currentRoute.params.organisationId
            ),
            organisationData as any
        );

        store.commit(
            "setOrganisationData",
            organisationData as ORGANISATION_ELEMENT
        );
    }

    async messaging(token: string): Promise<void> {
        const resp = await axios.post(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/${router.currentRoute.params.organisationId}/messaging/${_Vue.prototype.$firebase.auth.uid}/token`,
            {
                token: token,
            },
            {
                headers: {
                    Authorization:
                        await Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );

        if ((resp as any).status != 200 || (resp as any).data.error == true) {
            throw new Error();
        }
    }

    async acceptInvite(accept: boolean): Promise<void> {
        const resp = await axios.post(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/invite/${router.currentRoute.params.organisationId}/${_Vue.prototype.$firebase.auth.uid}`,
            {
                accept: accept,
            },
            {
                headers: {
                    Authorization:
                        await Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );

        if ((resp as any).status != 200 || (resp as any).data.error == true) {
            throw new Error();
        }
    }

    async updateMemberInformation(name: string): Promise<void> {
        // const auth = getAuth();
        // updateProfile(auth.currentUser as User, {
        //     displayName: name,
        // });

        const resp = await axios.post(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/auth/${router.currentRoute.params.organisationId}/${_Vue.prototype.$firebase.auth.uid}`,
            {
                displayName: name,
            },
            {
                headers: {
                    Authorization:
                        await Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );

        if ((resp as any).status != 200 || (resp as any).data.error == true) {
            throw new Error();
        }
    }

    async getById(id: string): Promise<ORGANISATION_ELEMENT | null> {
        const snapshot = await getDoc(doc(this.groupCollection, id));
        const organisationData = snapshot.data() as any;

        if (organisationData == null) return null;

        store.commit(
            "setOrganisationData",
            organisationData as ORGANISATION_ELEMENT
        );

        return organisationData || null;
    }

    unsubscribe(): void {
        if (this.unsubscribeWatcher != null) this.unsubscribeWatcher();
    }

    watch(
        organisationId: string,
        callback: (organisationId: ORGANISATION_ELEMENT | null) => void
    ): void {
        this.unsubscribe();
        this.unsubscribeWatcher = onSnapshot(
            doc(this.groupCollection, organisationId),
            {
                next: (snapshot) => {
                    callback(snapshot.data() as ORGANISATION_ELEMENT);
                },

                error: (error) => {
                    console.log("WATCH ORGANISATION: ", error);
                },
            }
        );
    }

    //   async create(): Promise<ORGANISATION_ELEMENT> {
    //     const organisationObj: ORGANISATION_ELEMENT = {
    //       id: uuidv4(),
    //       createdAt: new Date(),
    //       createdBy: _Vue.prototype.$firebase.auth.uid,

    //       general: {
    //         name: "",
    //         descr: "",
    //         logo: null,
    //         colors: {
    //           grey: "#C0C0C0",
    //           primary: "#CC2029",
    //           secondary: "#A0A0A0",
    //         },
    //       },

    //       members: {
    //         [_Vue.prototype.$firebase.auth.uid as string]: {
    //           displayName: _Vue.prototype.$firebase.auth.user.displayName || "",
    //           email: _Vue.prototype.$firebase.auth.user.email || "",
    //           role: 1,
    //         },
    //       },
    //     };

    //     await setDoc(
    //       doc(this.groupCollection, organisationObj.id),
    //       organisationObj
    //     );

    //     return organisationObj;
    //   }

    async update(project: ORGANISATION_ELEMENT): Promise<void> {
        const projectHandle = project;

        try {
            await setDoc(
                doc(this.groupCollection, projectHandle.id),
                projectHandle
            );

            store.commit(
                "setOrganisationData",
                projectHandle as ORGANISATION_ELEMENT
            );

            // store.dispatch("organisation", project as ORGANISATION_ELEMENT);
        } catch (error) {
            if (error.code == "permission-denied")
                _Vue.prototype.$toast.error("Fehlende Nutzerrechte");
            console.log(error.code);
        }
    }
}

class OrganisationsConverter
    implements FirestoreDataConverter<ORGANISATION_ELEMENT>
{
    toFirestore(organisation: ORGANISATION_ELEMENT): DocumentData {
        const json = {
            ...organisation,
            users: Object.keys(organisation.members).filter(
                (key: any, index: any) => organisation.members[key].role >= 0
            ),
        } as any;

        delete json.memberGroups;
        delete json.id;
        return json;
    }

    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>
    ): ORGANISATION_ELEMENT {
        const json = snapshot.data();
        delete json.users;
        return { ...json, id: snapshot.id } as ORGANISATION_ELEMENT;
    }
}

_Vue.use(OrganisationsPlugin);

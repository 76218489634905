

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component({
    components: {
    },
})


export default class MarkupText extends Vue {

    @Prop() text!: string
    @Prop() fieldId!: string

    @Watch('text', { immediate: true, deep: true })
    onDescrChange(): void {

        if (this.text==null||this.text.length==0) {
            const doc=document.getElementById(`markup-${this.fieldId}`)

            if (doc!=null) (doc as any).replaceChildren()
        }





        const doc=document.getElementById(`markup-${this.fieldId}`)

        if (doc==null) return;

        (doc as any).replaceChildren()
        doc.innerHTML=this.text


        const pElements=document.querySelectorAll('p');

        for (const pElement of pElements) {
            pElement.style.marginBottom='5px';
        }
    }

    created(): void {
        this.$nextTick(() => {

            this.onDescrChange()
        })
    }


}

import _Vue from "vue";
import {
  getDocs,
  where,
  orderBy,
  startAfter,
  limit,
  collection,
  query,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { firebaseFirestore } from "../firebase";
import { TEMPLATE_ELEMENT } from "@/models/api/template.model";
import { User } from "@firebase/auth";
import { uuidv4 } from "@firebase/util";
import router from "@/router";

declare module "vue/types/vue" {
  export interface Vue {
    $templates: TemplatesPlugin;
  }
}

class TemplatesPlugin {
  lastTemplateElement: QueryDocumentSnapshot | null = null;
  prevTemplateElement: QueryDocumentSnapshot | null = null;

  static install(Vue: typeof _Vue) {
    Vue.prototype.$templates = new TemplatesPlugin();
  }

  resetPagination(): void {
    this.lastTemplateElement = null;
    this.prevTemplateElement = null;
  }

  async get(
    organisationId: string,
    searchValue = "",
    labelId: string | null = null,
    archived = false
  ): Promise<any[]> {
    this.prevTemplateElement = this.lastTemplateElement;
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates"
    ).withConverter(new TemplatesConverter());

    let q = query(organisationCollection, orderBy("archived"),);

    if (archived == false) {
        q = query(q,
            );

    }
    if (labelId != null) {
      q = query(q, where("labelRef", "array-contains", labelId));
    }

    
    if (searchValue.length > 0) {
      q = query(
        q,
        orderBy("options.title"),
        where("options.title", ">=", searchValue),
        where("options.title", "<=", searchValue + "\uf8ff"),
        orderBy("lastEdited", "desc")
      );
    } else if (this.lastTemplateElement == null) {
      q = query(q, orderBy("lastEdited", "desc"));
    } else {
      q = query(
        q,
        orderBy("lastEdited", "desc"),
        startAfter(this.lastTemplateElement)
      );
    }

    q = query(q, limit(25));
    const snapshot = await getDocs(q);

    if (searchValue.length > 0) {
      this.lastTemplateElement = null;
    } else {
      this.lastTemplateElement =
        snapshot.docs[snapshot.docs.length - 1] || null;
    }

    return snapshot.docs.map((doc) => ({
      status: doc.data().status,
      title: doc.data().options.title,
      lastEditor: doc.data().lastEditor,
      lastEdited: doc.data().lastEdited,
      id: doc.data().id,
      labels: doc.data().labels,
      descr: doc.data().options.descr,
      steps: doc.data().steps.length,
      shortLink: (doc.data() as any).shortLink
    }));
  }

  async delete(
    organisationId: string,
    templateId: string
  ): Promise<void> {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates"
    )

    await setDoc(doc(organisationCollection, templateId), {
        archived: true,
        lastEdited: (+new Date()),
        lastEditor: (_Vue.prototype.$firebase.auth.uid),
      }, { merge: true });
  }

  async getById(
    organisationId: string,
    id: string
  ): Promise<TEMPLATE_ELEMENT | undefined> {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates"
    ).withConverter(new TemplatesConverter());

    const snapshot = await getDoc(doc(organisationCollection, id));
    return snapshot.data();
  }

  watchTemplateById(
    organisationId: string,
    id: string,
    callback: (template: TEMPLATE_ELEMENT | undefined) => void
  ): void {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates"
    ).withConverter(new TemplatesConverter());

    onSnapshot(doc(organisationCollection, id), {
      next: (snapshot) => {
        callback(snapshot.data());
      },
    });
  }

  async create(organisationId: string): Promise<TEMPLATE_ELEMENT> {
    const user = _Vue.prototype.$firebase.auth.user;

    const template: any = {
      id: uuidv4(),

      archived: false,
      labels: [],
      lastEditor: user.uid,
      lastEdited: +new Date(),

      createdAt: +new Date(),
      createdBy: user.uid,

      steps: [],
      shortLink: '',

      options: {
        status: "",
        title: "Unbekannter Name",
        descr: "",


        geolocation: false,

        share: {
          shareable: true,
          public: false,
        },

        identifier: null,

        roles: {
          [user.uid as string]: 1 as number,
        },

        docs: [],
      },

      exports: [],
    };

    console.log(template);
    const shortLink = await _Vue.prototype.$firebase.link(`https://report.alinocam.com/${router.currentRoute.params.organisationId}/overview?templateId=${template.id}`, 'AlinoReport Berichtvorlage')
    // template.roles[user.uid] = 1;

    template.shortLink = shortLink;
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates"
    ).withConverter(new TemplatesConverter());

    await setDoc(doc(organisationCollection, template.id), template);
    return template;
  }

  async updateTemplate(
    organisationId: string,
    template: TEMPLATE_ELEMENT
  ): Promise<void> {
    const projectHandle = template;
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates"
    ).withConverter(new TemplatesConverter());

    await setDoc(doc(organisationCollection, projectHandle.id), {
      ...projectHandle,
      lastEdited: (+new Date()),
      lastEditor: (_Vue.prototype.$firebase.auth.uid),
    });
  }
}

class TemplatesConverter implements FirestoreDataConverter<TEMPLATE_ELEMENT> {
  toFirestore(template: TEMPLATE_ELEMENT): DocumentData {
    const json = {
      ...template,
      labelRef: template.labels.map((label: any) => label.id),
    } as TEMPLATE_ELEMENT;
    delete json.id;
    return json;
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): TEMPLATE_ELEMENT {
    const json = snapshot.data();
    delete json.labelRef;

    return { ...json, id: snapshot.id } as TEMPLATE_ELEMENT;
  }
}



_Vue.use(TemplatesPlugin);

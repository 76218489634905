
import { TEMPLATE_ELEMENT } from '@/models/api/template.model';
import { BASE_FIELD } from '@/models/exports/pdf/fields/field';
import { getVariable, getReturnType } from '@/plugins/variables.helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CONFIGURATOR_MODES } from '../../modes/configurator.modes';


@Component({
  components: {
    
  },
})


export default class VariableLink extends Vue {
    @Prop() variable!: string; 
    @Prop() template!: TEMPLATE_ELEMENT; 
    @Prop() noClick!: boolean;
    @Prop() limit!: string | null

  CONFIGURATOR_MODES = CONFIGURATOR_MODES;
    
    variableObj: BASE_FIELD | null = null;
    hideTooltip = false; 
    

    getVariableData(): void {
        this.hideTooltip = false;
        if (this.variable == null) {
            this.hideTooltip;
            return;
        }

        
        const resp = getVariable({children: this.template.steps}, this.variable, this.limit, false);
        
        if (resp != null && resp.variable == this.variable) {
            this.variableObj = resp;
        } else {
            this.hideTooltip = true
        }
    }


    openVariable(): void {
        if (this.noClick == true) return;
        // console.log(this.variableObj)
        this.$router.replace({name: this.$route.name as string, query: {...this.$route.query, mode: String(CONFIGURATOR_MODES.EDITOR),  ref: (this.variableObj as BASE_FIELD).id, step: this.$route.query.step}})
    }
    
    mounted(): void {
        this.getVariableData()
    }

    
    get returnType(): string  {
        if (this.variableObj == null) return '-'
        return getReturnType(this.variableObj.type); 
    }
}

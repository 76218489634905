import { IMAGE } from "../models/fields/image.model";
import { AlinoPDF, SPACER } from "../pdf.class";


const CONTACT_BREAK_HEIGHT = 60

/**
 * address 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (ADDRESS) address input object and config data
 */
export async function image(this: AlinoPDF, data: IMAGE):  Promise<void> {

    // console.log("IAMGe")
    if ((this.position.y + 30) > this.layout.outerHeight - this.layout.innerBottom) {
        this._textStyle(this.config.font.regular, this.config.color.secondary, 9.5);
    
    
        await this.create(null, true)
        this.position.y = this.layout.innerTop;
    
      }

  // field header
  if (data.header != null && data.header.length > 0) {
    this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
    this.docObj.text(data.header.toUpperCase(), this.position.x, this.position.y);

    this.position.y += 7;
  } else {
    this.position.y += 2.75
  }

  for (let index = 0; index < data.value.length; index++) {
    if (data.value[index].type.startsWith('image/')) {
        console.log(data.value[index])
   const imageData = await this._imageBase64(data.value[index].url, data.value[index].type) ;

//    if (index != 0) {
//     this.position.y += 15
//    }
   
//    if (data.value[index] == null || data.value[index].width == null || data.value[index].height == null)
    let imageHeight = 160;
    let imageWidth = ((data.value[index] as any).width / (data.value[index] as any).height) * imageHeight;
    if ((data.value[index] as any).width > (data.value[index] as any).height) {
      imageWidth = this.layout.innerWidth;
      imageHeight = ((data.value[index] as any).height / (data.value[index] as any).width) * imageWidth;
    }

    if (imageHeight + this.position.y > this.layout.outerHeight - this.layout.innerBottom) {
        this._textStyle(this.config.font.regular, this.config.color.secondary, 9.5);

        this.docObj.text('Bildaufnahme auf der nächsten Seite'.toUpperCase(), this.config.page.padding.left, this.position.y)
      await this.create(null, true)
      this.position.y = this.layout.innerTop + 5; 
   

    }
    

    this.docObj.addImage(
      imageData as string,
      data.value[index].type.split("/")[1].toUpperCase(),
      this.config.page.padding.left,
      this.position.y,
      imageWidth,
      imageHeight,
      undefined,
      "FAST"
    );


  this.docObj.setDrawColor('#ffffff');
  this.docObj.setLineWidth(.5);

  this.docObj.roundedRect(
    this.config.page.padding.left - .25,
    this.position.y - .25, 
    imageWidth + .5, imageHeight + .5, .82, .82);


  this.docObj.setDrawColor(this.config.color.grey);
  this.docObj.setLineWidth(.15);

  this.docObj.roundedRect(
    this.config.page.padding.left,
    this.position.y, 
    imageWidth, imageHeight, .75, .75);

    this.position.y += imageHeight + 5
  }
    // const dateText = 'Erstellungsdatum: ' +  (formatDate(data.value[index].createdAt as Date, true));
    // const dateTextWidth = this.docObj.getTextWidth(dateText)


    this._textStyle(this.config.font.bold, this.config.color.secondary, 9.5);

    let typeText = 'Datei'
    if (data.value[index].type.startsWith('image/')) {
        typeText = 'Aufnahme'
    } else if (data.value[index].type.startsWith('video/')) {
        typeText = 'Video'
    } else if (data.value[index].type.startsWith('audio/')) {
        typeText = 'Audioaufnahme'
    } else if (data.value[index].type.startsWith('application/pdf')) {
        typeText = 'Dokument';

        (this.appendFile as any).push((data as any).value[index])

    }

    const imageTitle = `${typeText} ${index + 1}`.toUpperCase();



    

    // this._textStyle(this.config.font.light, this.config.color.secondary, 8.5);

   
    if (data.value[index].type.startsWith('video/') || data.value[index].type.startsWith('audio/')) {

       
   
        const imageTitleHeight = this.docObj.getTextDimensions(imageTitle).h; // text height (of all rows!)
        this._textStyle(this.config.font.regular, this.config.color.primary, 8);

        const effectiveMaxText = (this.layout.innerWidth - this.config.page.padding.right );
        const url = this.docObj.splitTextToSize(`${( data.value[index].url || '-')}`, effectiveMaxText);
        const videoUrlHeight = this.docObj.getTextDimensions(url[0]).h * (url.length); // text height (of all rows!)

        console.log("VUH", videoUrlHeight, (this.position.y +  imageTitleHeight + 2 + videoUrlHeight + 5 + 10) ,  this.layout.outerHeight - this.layout.innerBottom)
        if ((this.position.y +  imageTitleHeight + 2 + videoUrlHeight ) >( this.layout.outerHeight - this.layout.innerBottom)) {
            await this.create(null, true)
            this.position.y = this.layout.innerTop + 5; 
        }

        this._textStyle(this.config.font.bold, this.config.color.secondary, 9);
        this.docObj.textWithLink(imageTitle, this.position.x, this.position.y, {url: data.value[index].url})


        this.position.y += imageTitleHeight + 2;
        this._textStyle(this.config.font.regular, this.config.color.primary, 9);
        this.docObj.textWithLink(url, this.position.x, this.position.y, {url: data.value[index].url})

        this.position.y += videoUrlHeight 
    } else {
        this.docObj.textWithLink(imageTitle, this.position.x, this.position.y, {url: data.value[index].url})


        if (data.value[index].type.startsWith('application/pdf')) {
        this.position.y += 4;
        this._textStyle(this.config.font.regular, this.config.color.primary, 9);
        this.docObj.textWithLink('siehe Anhang', this.position.x, this.position.y, {url: data.value[index].url})
        
        }
    }
    this.position.y += 8



    
    // this.docObj.text(dateText, this.position.x + imageTitleWidth + imageWidth - dateTextWidth , this.position.y)

  
  }



  this.position.y += 4



}


export function formatDate(dateHandle: number | Date, short = false): string {
  const event = new Date(dateHandle);

  const options = {
    year: "numeric",
    month: short ? 'short' : "long",
    day: "numeric",
  };

  

    return event.toLocaleDateString(
      `de-de`,
      (options as any)
    );

}


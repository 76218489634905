export const FIELD_TYPE = {
    TEXTFIELD: 'textfield',
    GROUP: 'group',
    TEXTAREA: 'textarea',
    INFORMATION: 'information',
    BOOLEAN: 'boolean',
    RADIOS: 'radios',
    CHECKBOX: 'checkbox',
    IMAGE: 'images', 
    DATE: 'date', 
    TIME: 'time',
    INVENTORY: 'inventory',
    SELECTOR: 'selector',
    CONTACT: 'contact',
    ADDRESS: 'address',
    CHECKLIST: 'checklist',
    SIGNATURE: 'signature',
    SCHEDULE: 'schedule',
    USER: 'user'
}
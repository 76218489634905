import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'font-awesome/css/font-awesome.min.css' // Ensure you are using css-loader


Vue.use(Vuetify);

export default new Vuetify({

    
    theme: {
      options: {
        customProperties: true
    },
      themes: {
        light: {
          primary: '#cc2029',
          secondary: '#424242',
          accent: '#82B1FF',
          info: '#2196F3',
          warning: '#FFC107',
          error: '#FF5252',
          success: '#4CAF50',
          background: '#FAFAFA'
        },
      },
    }
  });
  




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RequiredField from '@/components/helper/RequiredField.vue';

@Component({
  components: {
    RequiredField
  },
})


export default class RadioSelectionField extends Vue {

  @Prop() value!: any
  @Prop() readonly!: boolean
  @Prop() showMissing!: boolean;


  update(eventHandle: boolean): void {
    this.$report.updateField({...this.value, value: eventHandle})
  }
}

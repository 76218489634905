

import { FIELDS } from '@/fields.types';
import { TEMPLATE_ELEMENT } from '@/models/api/template.model';
import { BASE_FIELD } from '@/models/exports/pdf/fields/field';
import { RADIOS_FIELD } from '@/models/exports/pdf/fields/radios.model';
import { getById, getVariable } from '@/plugins/variables.helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VariableLink from '@/components/editor/configurator/helper/VariableLink.vue';
import { formatDate } from '@pdf/fields/image.field';



// import VariableSelector from '@/components/helper/VariableSelector.vue';
@Component({
    components: {
        VariableLink
    },
})


export default class ConditionText extends Vue {
    @Prop() condition!: any; 
    @Prop() template!: TEMPLATE_ELEMENT;
    @Prop() limit!: string | null;

    @Prop() noClick!: boolean
    get fieldObj(): BASE_FIELD {
        return getById({children: this.template.steps}, this.condition.fieldId) as BASE_FIELD;
    }

    // @Watch('condition', {immediate: true, deep: true})
    // onTemplateUpdate(): void {
    //     console.log("H")
    //     this.updateVariable = !this.updateVariable
    // }


    get conditionValue(): string {
        if (this.variableObj == null) return '-'
        switch (this.variableObj.type) {

            // case FIELDS.DATE: 
            //     return ((this.variableObj as any).value.date)
            case FIELDS.SELECTOR:
                if (this.condition.value == null || typeof this.condition.value != 'number') return '-'
                return `${(this.variableObj as any).options.elements[this.condition.value - 1].text} (#${this.condition.value})`
                // break;

            case FIELDS.RADIOS:
                if (this.condition.value == null || typeof this.condition.value != 'number') return '-'
                return `${(this.variableObj as any).options.elements[this.condition.value].text} (#${this.condition.value})`
                // break;

            case FIELDS.CHECKBOX:
                console.log((this.variableObj as any), this.condition.value)
                if ((this.variableObj as any) == null || this.condition.value == null || this.condition.value.length == 0) return '-'
                return this.condition.value.map((val: any, index: number) => val == true ? `${(this.variableObj as any).options.elements[index] ? (this.variableObj as any).options.elements[index].text : ''}` : null).filter(Boolean).join(' && ')
                // break;

            case FIELDS.CHECKLIST: 
            if (this.condition.value == true) return 'DONE'
                else return 'NICHT DURCHGEFÜHT'

            case FIELDS.BOOLEAN: 
                if (this.condition.value == true) return 'JA'
                else if (this.condition.value == false) return 'NEIN'
                else return 'KEIN WERT'
        
            case FIELDS.TEXTAREA: 
            case FIELDS.TEXTFIELD: 
                if (this.condition.value.length == 0) return 'KEIN WERT'
                else return this.condition.value
            default:
                return 'ss'
                // break;
        }
    }

    get variableObj(): BASE_FIELD | null {
        if (this.variable == null) return null;
        return JSON.parse(JSON.stringify(getVariable({children: this.template.steps}, this.variable as string)))
    }
    
    get variable(): string | null {
        if (this.fieldObj != null && this.fieldObj.variable != null) return this.fieldObj.variable
        else return null;
    }

}

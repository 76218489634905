// import { BOOLEAN } from "../models/fields/boolean.model";
import { CHECKBOX } from "../models/fields/checkbox.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";




/**
 * checkbox 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function checkbox(this: AlinoPDF, data: CHECKBOX):  Promise<void> {

  let text = '-'
  if (data.value != null && data.value.length > 0) {
    const listSeperated = data.value.map((val: number) => data.options.elements[val]?.text).filter(Boolean)
    text =  listSeperated.join(',  ')
  }

  this._textStyle(this.config.font.regular, 'black', 11); // set used textstyle for correct calc

  let textWidth = this.docObj.getTextWidth(text) + 6
  if (data.value == null || data.value.length == 0)  textWidth += 5
  await this.textfield(({header: data.header, value: text} as TEXTFIELD), textWidth)

}

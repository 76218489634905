

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
//   import SwitchSubtext from '@/components/helper/SwitchSubtext.vue';
import IconButton from '@components/button/IconButton.vue';
import { LABEL_ELEMENT } from '@/models/api/labels.model';
import Information from '@/components/helper/Information.vue';
//   import OptionsTemplate from '@/components/helper/OptionsTemplate.vue';

@Component({
    components: {
        Information,
        TextInput,
        //   SwitchSubtext,
        IconButton,
        //   OptionsTemplate
    },
})


export default class SelectorMenu extends Vue {

    @Prop() titleKey!: string;
    @Prop() subtitleKey!: string;
    @Prop() typeKey!: string;
    @Prop() highlight!: boolean;
    @Prop() data!: any;
    @Prop() title!: string; 
    @Prop() icon!: string; 


    @Prop() selection!: string[] | string
    @Prop() labels!: string[];
    
    @Prop() disabled!: boolean;
    @Prop() single!: boolean;
    @Prop() showHeader!: boolean;
    @Prop() headerKey!: string;
    @Prop() loading!: boolean
    @Prop() loadingText!: string
    @Prop() searchText!: string

    @Prop() searchKeys!: string[];
    searchVariable = '';
    menu = false;
    notFound = false;


    get formattedData(): any {
        return ([this.selection].flat()).map((selected) => ({id: selected, name: this.getHeader(selected as string), icon: this.getIcon(selected as string) }))
    }

    getIcon(id: string): string {
        
        if (this.loading == true || this.showHeader == null) return id;
         
        try {
            
            const dataObj = this.data.reduce((elementA: any, elementB: any) => elementA.id == id ? elementA : elementB)
            
            if (dataObj.id != id) throw new Error(); 
            
            return dataObj['icon'] || ''


        } catch  {
            return '';
        }
    }

    getHeader(id: string): string {
        
        if (this.loading == true || this.showHeader == null) return id;
         
        try {
            
            const dataObj = this.data.reduce((elementA: any, elementB: any) => elementA.id == id ? elementA : elementB)
            
            if (dataObj.id != id) throw new Error(); 
            this.notFound = false;
            return dataObj['header'] || (dataObj[this.headerKey] || id);


        } catch  {
            return id;
        }
    }

    get searchData(): any[] {
        let filteredData = this.data;
        
        if (this.searchVariable.length > 0) {
            filteredData = filteredData
                .filter((element: any) => 
                    this.searchKeys
                        .map((keyHandle: string) => 
                            (element[keyHandle] || '').toLowerCase().includes(this.searchVariable.toLowerCase())
                        ).some((res: boolean) => res == true)
                    )
        } 
        

        return filteredData;
    }

    updateSelection(obj: any): void {

        const selectionRef = [this.selection].flat()
        if (this.single == true) {
            const refObj = obj.id;

            this.$emit('change', refObj);
            // this.selection.splice(0, this.selection.length); 
            // this.selection.push(obj.id)
            this.menu = false;
            return;
        } else {
            if (selectionRef.some((id: string) => id == obj.id)) {
                selectionRef.splice(selectionRef.findIndex((id: string) => (id == obj.id)), 1)
            // this.$labels.updateReference(this.$route.params.organisationId, this.$route.params.templateId, labelObj, true)

        } else {
            selectionRef.push(obj.id)
            // this.$labels.updateReference(this.$route.params.organisationId, this.$route.params.templateId, labelObj)
        // console.log("UPDATE")
        }


        this.$emit('change', selectionRef);
        }

        
    }
    @Watch('selectedItem')
    onSelectionChange(): void {
        this.menu = false;
    }

    @Watch('menu')
    onMenuChange(): void {
        if (this.menu == false) setTimeout(() => {
            this.searchVariable = ''
        }, 250);
    }

}

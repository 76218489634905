

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { User } from '@firebase/auth'; 
import SelectorMenu from '../editor/sidebar/SelectorMenu.vue';
import Information from './Information.vue';
import { LABEL_ELEMENT } from '@/models/api/labels.model';
import { LABEL_ROLE } from '@/config/labels.roles';
//   import OptionsTemplate from '@/components/helper/OptionsTemplate.vue';

interface USER_LIST_ELEMENT extends Partial<User> {
    id: string;
}

@Component({
    components: {
        Information,
        SelectorMenu,
        // TextInput,
        // IconButton,
        // UserList
    },
})




export default class UserSelector extends Vue {
    @Prop() highlight!: boolean;
    @Prop() user!: string
    @Prop() disabled!: string[];
    @Prop() allowed!: string[];
    @Prop() filterAllowed!: boolean;
    @Prop() multiple!: boolean
    @Prop() includeLabelGroups!: boolean;
    @Prop() overviewSelected!: boolean
    @Prop() readonly!: boolean; 
    @Prop() includeLabelMembers!: boolean


    
    LABEL_ROLES = LABEL_ROLE;
    get selectedGroups(): LABEL_ELEMENT[] {
        return []//this.$store.getters.members.groups.filter((label: LABEL_ELEMENT) => this.multiple==true? this.user.includes(label.id as string||''):this.user==label.id)
    }

    get memberData(): any[] {
    

        // if (this.includeLabelGroups==true) {
            const users =[...(this.members || []), ...(this.memberGroups || [])].flat()


const data = ([...new Set(users.map((user) => user.id))]).map((userId: string) => users.reduce((a: any, b: any) => a.id == userId ?  a : b))
        // }

        
        return data//.filter((listObj: any) => ((this.filterAllowed==false||this.allowed==null)||(this.filterAllowed==true&&(this.allowed || []).includes(listObj.id))))
    }

    get members(): USER_LIST_ELEMENT[] {
        return Object.keys(this.$store.getters.members.user||{}).filter((labelId: string) => !this.filterAllowed || (this.allowed || []).includes(labelId)).map((id: string) => ({ ...this.$store.getters.members.user[id], type: 'NUTZER', id: id, disabled: false })).sort((a, b) => Number(a.disabled)-Number(b.disabled))
    }

    get memberGroups(): USER_LIST_ELEMENT[] {

        if (this.includeLabelMembers == true){
            const users = Object.keys(this.$store.getters.members.groups || {}).filter((labelId: string) => !this.filterAllowed || (this.allowed || []).includes(labelId)).map((labelId: string) => (Object.keys(this.$store.getters.members.groups[labelId].members).map(((userId: string) => ({...this.$store.getters.members.user[userId], type: 'NUTZER', id: userId, disabled: false }))).flat())).flat();
             return users
        }
        // console.log("GET MEMBER GROUPS", this.$store.getters.members)
        else return Object.keys(this.$store.getters.members.groups || {}).filter((labelId: string) => !this.filterAllowed || ( this.allowed || []).includes(labelId)).map((labelId: string) => ({ id: labelId, displayName: this.$store.getters.members.groups[labelId].header, type: 'LABEL', mail: this.$store.getters.members.groups[labelId].descr, disabled: false, icon: this.$store.getters.members.groups[labelId].icon}))
    }


}

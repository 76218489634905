
import TextButton from '@components/button/TextButton.vue'
import { missingDataCheck } from '@/plugins/helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { getVariableValue } from '@/plugins/export/report.export';
import { BASE_FIELD } from '@/models/exports/pdf/fields/field';
import { uuidv4 } from '@firebase/util';

@Component({
    components: {
        FieldHeader,
        TextButton
    },
})


export default class GroupEditField extends Vue {
    expandStatus=[]
    @Prop() value!: any
    @Prop() isReadonly!: string;
    @Prop() showMissing!: boolean

    addPatternElement(): void {
        
        this.$report.updateField({...this.value, mirror: this.value.id, children: [this.value.children, JSON.parse(JSON.stringify(this.value.pattern)).map((field: BASE_FIELD) => ({...field, id: uuidv4()}))].flat()})
    }

    get report(): any {
        return this.$store.getters.report;
    }

    @Watch('report', { immediate: true, deep: true })
    onValueChange(): void {
        console.log("CA", this.report)
    }

    get isMissing(): boolean {
        if (!this.showMissing) return false;
        return missingDataCheck(this.value)
    }
}

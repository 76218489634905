


export const LABEL_ROLE = {
    MANAGER: 1,
    USER: 2
}

export const LABEL_ROLES_TEXT = [
  
    {
        id: LABEL_ROLE.MANAGER,
        text: 'Label Manager:in',
        descr: 'Betrachter:innen habeng lediglich Lesezugriff auf die Vorlage. Sie können alle Berichte von der Vorlage ansehen.'
    },
    {
        id: LABEL_ROLE.USER,
        text: 'Label Nutzer:in',
        descr: 'Betrachter:innen fhaben lediglich Lesezugriff auf die Vorlage. Sie können alle Berichte von der Vorlage ansehen.'
    }
]
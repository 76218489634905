// import { BOOLEAN } from "../models/fields/boolean.model";
import { SELECTOR } from "../models/fields/selector.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";

import * as htmlparser from 'htmlparser2';


/**
 * checkbox 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function information(this: AlinoPDF, data: any):  Promise<void> {
     // Initialize current tag, text, and style

  let currentText = '';
  let currentStyle = '';
const vm = this
  // Create a parser instance
  const parser = new htmlparser.Parser({
    onopentag(tagName: string) {

        if (currentText.length > 0) {

            vm._textStyle(vm.config.font.regular, vm.config.color.secondary, 12);
            const textDimensions = vm.docObj.getTextDimensions(currentText);

            const maxYPosition = vm.layout.innerWidth
            if (textDimensions.w > maxYPosition) {
                const words = currentText.split(/\s+/) 
                
                const lengthWhitespace = vm.docObj.getTextWidth('\u00A0');
                vm.position.x = vm.config.page.padding.left;

                let currentLine = ''
                for (let index = 0; index < words.length; index++) {
                    
                    // console.log(words[index])
                    const splitTextDimensions = vm.docObj.getTextDimensions(currentLine + ' ' + words[index] );

                    // if (splitTextDimensions.w > maxYPosition) console.log(splitTextDimensions.w, maxYPosition, vm.position.y + splitTextDimensions.w, currentLine, words[index], vm.docObj.getTextWidth(words[index]), vm.position.y + splitTextDimensions.w - vm.docObj.getTextWidth(words[index]))
                    
                 
                    if (splitTextDimensions.w > maxYPosition) {

                        let fillSpaces = ((maxYPosition - vm.docObj.getTextWidth(currentLine)) / lengthWhitespace);

                        if ((fillSpaces % 1) <= 0.5) fillSpaces = Math.round(fillSpaces) + 1
                        else fillSpaces = Math.ceil(fillSpaces)

                        const words = currentLine.split(/\s/g)
                        for (let whitespaceIndex = 0; whitespaceIndex <= fillSpaces; whitespaceIndex++) {

                            
                            words[Math.floor(Math.random() * (words.length))] += '\u00A0';

                            
                        } 

                        currentLine = words.join('\u00A0')
                        
                        vm.docObj.text(( currentLine ), vm.position.x, vm.position.y, {align: 'left'});
                        currentLine = ''; 
                        vm.position.y += splitTextDimensions.h; 
                        vm.position.x = vm.config.page.padding.left;
                    }

                    if (currentLine.length == 0) currentLine = words[index]
                    else currentLine += (' ' + words[index])

                    currentText = ''
                    
                }
            } else {
                vm.docObj.text(currentText, vm.position.x, vm.position.y); 
            }

     
        }

      // Update current style based on tag
      if (tagName === 'em') {
        currentStyle += 'italic';
      } else if (tagName === 'strong') {
        currentStyle += 'bold';
      } else if (tagName === 'u') {
        currentStyle += 'underline';
      } else if (tagName === 's') {
        currentStyle += 'line-through';
      }
    },
    ontext(text: string) {
        // console.log(text)
      // Save current text
      currentText += text;
    },
    onclosetag(tagName: string) {
      // If closing tag is "p", add a page break
      if (tagName === 'p') {
        vm.position.y += 5; 
        vm.position.x = vm.config.page.padding.left;
      }


   

      // Remove style from current style based on tag
      if (tagName === 'em') {
        currentStyle = currentStyle.replace('italic', '');
      } else if (tagName === 'strong') {
        currentStyle = currentStyle.replace('bold', '');
      } else if (tagName === 'u') {
        currentStyle = currentStyle.replace('underline', '');
      } else if (tagName === 's') {
        currentStyle = currentStyle.replace('line-through', '');
      }


      // Set text style based on current style

    //   // Print current text
    //   let currentX = vm.position.x;
    //   let currentY = vm.position.y;
    //   console.log(currentText)
    //   // Split text into lines
    //   const lines = currentText.split('\n');
    //   for (const line of lines) {
    //     // Check if line fits on current page
    //     const textDimensions = vm.docObj.getTextDimensions(line);
    //     if (currentY + textDimensions.h > vm.layout.innerHeight) {
    //       // Add a page break and reset X and Y position
    //       vm.docObj.addPage();
    //       currentY = vm.config.page.padding.top;
    //       currentX = vm.config.page.padding.left;
    //     }

    //     if (currentX + textDimensions.w > vm.layout.innerWidth + vm.config.page.padding.left) {
            
    //       currentX = vm.config.page.padding.left;
    //       currentY += 5;
    //       console.log
    //     }
    //     // Print line
    //     vm.docObj.text(line, currentX, currentY);
    //     currentX += vm.docObj.getTextWidth(line)
    //     // Update Y position
    //     // currentY += textDimensions.h;
    //   }
    //   // Update X and Y position
    //   vm.position.x = currentX;
    //   vm.position.y = currentY;

    //   // Clear current tag, text, and style
    //   currentTag = '';
    //   currentText = '';
    //   currentStyle = '';
    }
  }, {decodeEntities: true});
    // Write data to parser
    parser.write(`<p>${data.options.info}</p><p></p>`);
    // End parsing
    parser.end();
  
    return Promise.resolve();
  }
  
    
    

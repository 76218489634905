import _Vue from "vue";
import { uuidv4 } from "@firebase/util";

import {
    getDocs,
    runTransaction,
    where,
    orderBy,
    startAfter,
    limit,
    collection,
    query,
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    doc,
    getDoc,
    setDoc,
    onSnapshot,
    updateDoc,
    arrayUnion,
} from "firebase/firestore";

import { firebaseFirestore } from "../firebase";
import store from "@/store/index";
import { LABEL_ELEMENT } from "@/models/api/labels.model";
import { CHANGELOG } from "@/models/changelog.model";
import router from "@/router";
import axios from "axios";
import Vue from "vue";

declare module "vue/types/vue" {
    export interface Vue {
        $changelog: ChangelogPlugin;
    }
}

class ChangelogPlugin {
    lastTemplateElement: QueryDocumentSnapshot | null = null;
    prevTemplateElement: QueryDocumentSnapshot | null = null;
    unsubscribeWatcher = null;
    groupCollection = collection(
        firebaseFirestore,
        "organisations"
    ).withConverter(new LabelsConverter());

    static install(Vue: typeof _Vue) {
        Vue.prototype.$changelog = new ChangelogPlugin();
    }

    resetPagination(): void {
        this.lastTemplateElement = null;
        this.prevTemplateElement = null;
    }

    async object(
        organisationId: string,
        labelId: string,
        referenceId: string
    ): Promise<any> {
        console.log(organisationId, labelId);
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new LabelsConverter());

        const snapshot = await getDoc(doc(organisationCollection, referenceId));

        return snapshot.data();
    }

    async objects(organisationId: string, labelId: string): Promise<any> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new LabelsConverter());

        const snapshot = await getDocs(organisationCollection);

        return snapshot.docs.map(
            (ele: QueryDocumentSnapshot) => ele.data() as any
        );
    }

    async get(
        organisationId: string,
        labelId: string,
        searchValue: string,
        all = false
    ): Promise<LABEL_ELEMENT[]> {
        this.prevTemplateElement = this.lastTemplateElement;
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new ChangelogConverter());

        let q = query(organisationCollection);

        if (searchValue.length > 0) {
            q = query(
                q,
                orderBy("header"),
                where("header", ">=", searchValue),
                where("header", "<=", searchValue + "\uf8ff"),
                orderBy("lastEdited", "desc")
            );
        } else if (this.lastTemplateElement == null) {
            q = query(q, orderBy("lastEdited", "desc"));
        } else {
            q = query(
                q,
                orderBy("lastEdited", "desc"),
                startAfter(this.lastTemplateElement)
            );
        }

        if (all == false) q = query(q, limit(25));
        const snapshot = await getDocs(q);

        if (searchValue.length > 0) {
            this.lastTemplateElement = null;
        } else {
            this.lastTemplateElement =
                snapshot.docs[snapshot.docs.length - 1] || null;
        }

        return snapshot.docs.map(
            (ele: QueryDocumentSnapshot) =>
                ({
                    id: ele.data().id,
                    status: ele.data().status,
                    logs: ele.data().log.length,
                    lastEdited: ele.data().lastEdited,
                } as any)
        );
    }

    async getById(
        organisationId: string,
        labelId: string,
        refId: string
    ): Promise<LABEL_ELEMENT | null> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new ChangelogConverter());

        try {
            const snapshot = await getDoc(doc(organisationCollection, refId));
            const organisationData = snapshot.data();
            organisationData.log.reverse();
            return organisationData || null;
        } catch (error) {
            return null;
        }
    }

    unsubscribe(): void {
        if (this.unsubscribeWatcher != null) this.unsubscribeWatcher();
    }

    watch(
        organisationId: string,
        labelId: string,
        refId: string,
        callback: (project: any | undefined) => void
    ): void {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new ChangelogConverter());

        this.unsubscribe();
        this.unsubscribeWatcher = onSnapshot(
            doc(organisationCollection, refId),
            {
                next: (snapshot) => {
                    console.log(snapshot.data());
                    callback(snapshot.data());
                },
            }
        );
    }

    async update(
        organisationId: string,
        labelRefs: any,
        changelog: Partial<CHANGELOG>
    ): Promise<void> {
        const resp = await axios.post(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/${organisationId}/${router.currentRoute.params.reportId}`,
            {
                labels: labelRefs,
                changelog: changelog,
            },
            {
                headers: {
                    Authorization:
                        await Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );
    }
}

class ChangelogConverter implements FirestoreDataConverter<any> {
    toFirestore(organisation: any): DocumentData {
        const json = { ...organisation } as any;
        delete json.id;
        return json;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): any {
        const json = snapshot.data();
        return { ...json, id: snapshot.id } as any;
    }
}

class LabelsConverter implements FirestoreDataConverter<LABEL_ELEMENT> {
    toFirestore(organisation: LABEL_ELEMENT): DocumentData {
        const json = {
            ...organisation,
            users: Object.keys(organisation.members),
        } as any;
        delete json.id;
        return json;
    }

    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>
    ): LABEL_ELEMENT {
        const json = snapshot.data();
        delete json.users;
        return { ...json, id: snapshot.id } as LABEL_ELEMENT;
    }
}

_Vue.use(ChangelogPlugin);

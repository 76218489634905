
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GroupArea from '@/components/editor/modes/GroupArea.vue';
import { editorEventBus } from "@/main";
import { TEMPLATE_ELEMENT } from '@/models/api/template.model';

@Component({
  components: {
    GroupArea
  },
})


export default class GroupEditField extends Vue {

  @Prop() value!: any
  @Prop() disabled!: boolean
  @Prop() template!: TEMPLATE_ELEMENT;


  @Prop() selectable!: boolean;

  @Watch('value', { deep: true })
  onValueUpdate(): void {
    if (this.value.options != null && this.value.options.repeatable == true) {
      this.value.pattern = this.value.children;
    }
  }

}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CHECKLIST_FIELD } from '@/models/exports/pdf/fields/checklist.model';
import MarkupText from '../MarkupText.vue';


@Component({
  components: {
    MarkupText
  },
})


export default class RadioSelectionField extends Vue {

  showCamera = false;
  @Prop() value!: CHECKLIST_FIELD
  @Prop() showMissing!: boolean;
  @Prop() readonly!: boolean
 
  update(eventHandle: boolean): void {
    
    this.$report.updateField({...this.value, value: eventHandle})
  }
}

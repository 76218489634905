import jsPDF from "jspdf";
import { FIELD_TYPE } from "../config";
import { PDF_DATA } from "../models/data.model";
import { GROUP } from "../models/fields/group.model";
import { AlinoPDF, SPACER } from "../pdf.class";
import { formatDate } from "./datetime.field";


/**
 * textfield 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function group(this: AlinoPDF, value: GROUP, prependId: string, id: number, dense = false):  Promise<void> {


  

  // create nested section id
  const sectionId = prependId + (String(id));

  const sectionLevel = (sectionId.match(/\./g) || []).length; 

  
  if (value.children.length == 0) return; 
  

  if (this.position.y + 50 > this.layout.outerHeight - this.layout.innerBottom) {

    await this.create(null, true)
    this.position.y = this.layout.innerTop;
  }

  console.log((this.docObj as any).page)
  switch (sectionLevel) {
    case 0:
      console.log("###################### ID", id, ((this.docObj as any).page == 1 && id == 1))
      if (!((this.docObj as any).page == 1 && id == 1)) {
        await this.create(null, true);
        console.log("ADD PAGE")
      }
      
      this.position.y += 5
      this._textStyle(this.config.font.bold, this.config.color.primary, 14);

      break;

    case 1: 
      if (!dense && this.position.y > this.layout.innerTop) this.position.y += 10
      this._textStyle(this.config.font.bold, 'black', 13);
      break;

    case 2: 
      if (!dense && this.position.y > this.layout.innerTop) this.position.y += 9
      this._textStyle(this.config.font.bold, 'black', 12.5);
      break;

    case 3: 
      if (!dense && this.position.y > this.layout.innerTop) this.position.y += 8
      this._textStyle(this.config.font.bold, this.config.color.secondary, 12);
      break;

  
    case 4:
      if (!dense && this.position.y > this.layout.innerTop)  this.position.y += 8
      this._textStyle(this.config.font.bold, this.config.color.secondary, 11.5);
      break;


    default: 
      if (!dense && this.position.y > this.layout.innerTop) this.position.y += 7
      this._textStyle(this.config.font.regular, this.config.color.secondary, 11);
      break;
  }

  this.position.x = this.config.page.padding.left;
  

  

  const headerIds = (prependId +  String(id) + (prependId.length > 0 ? '.' : ''))
  this.docObj.text(headerIds, this.position.x, this.position.y);
  this.position.x += this.docObj.getTextWidth(headerIds) + SPACER.HORIZONTAL;

  const headerText = (value.header.toUpperCase());
  const headerArr = this.docObj.splitTextToSize(headerText, this.layout.innerWidth - this.position.x + this.config.page.padding.left)
  this.docObj.text(headerArr, this.position.x, this.position.y)


  this.position.y += 6 + this.docObj.getTextDimensions(headerArr).h; 
  this.position.x = this.config.page.padding.left;
  // else this.position.y += 6;

  const signatureFields = value.children.filter((child: any) => child.type == FIELD_TYPE.SIGNATURE)
  if (value.options.repeatable == true) {
    let groupIdIndex = 1;
    for (let index = 0; index < value.children.length; index += value.pattern.length) {
      await this.group(({header: `${value.options.repeatHeader} ${groupIdIndex}`, options: {repeatable: false, repeatHeader: ''}, children: value.children.slice(index, index + value.pattern.length)} as GROUP),prependId + String(groupIdIndex) + '.' , groupIdIndex, index == 0);     
      groupIdIndex += 1; 
    }
  } else {
    await this.renderData(value as any, sectionId + '.')

  }

  if (signatureFields != null && signatureFields.length > 0) {
  if ((this.position.y + (signatureFields.length /2) * 30) + 30  > this.layout.outerHeight - this.layout.innerBottom) {
    this._textStyle(this.config.font.regular, this.config.color.secondary, 9.5);

    this.docObj.text(`${signatureFields.length > 0 ? 'Unterschriten' : 'Unterschrift'} vom Kapitel ${id} auf der nächsten Seite. `.toUpperCase(), this.config.page.padding.left, this.layout.outerHeight - this.layout.innerBottom - 5)

    await this.create(null, true)
    this.position.y = this.layout.innerTop;

  }
  this.position.y = this.layout.outerHeight - this.layout.innerBottom - 30

  
  for (let signatureIndex = 0; signatureIndex < signatureFields.length; signatureIndex += 2) {
    console.log(signatureFields[signatureIndex])
    
    this.position.x = this.layout.outerWidth - this.config.page.padding.right - 75;
    // this.position.y = this.position.y - ((signatureIndex) * 19);

    if (signatureFields[signatureIndex] != null) {
        
        if ((signatureFields[signatureIndex] as any) == null || (signatureFields[signatureIndex] as any).value.id == null) continue
        const imageHandle = localStorage.getItem((signatureFields[signatureIndex] as any).value.id)

        this.docObj.setLineWidth(.2).setDrawColor(this.config.color.grey);
        this.docObj.line(
            this.position.x, (this.position.y + 16),
            this.position.x + 75, (this.position.y + 16)
        );
        
        if (imageHandle != null) this.docObj.addImage(
            imageHandle as string,
            'image/png',
            this.position.x, this.position.y,
            75,
            20,
            undefined,
            "FAST"
        );

      




        this._textStyle(this.config.font.regular, 'black', 10);

    this.docObj.text((signatureFields[signatureIndex] as any).value.name || '', this.position.x, (this.position.y + 21))


    this._textStyle(this.config.font.regular, this.config.color.secondary, 9);

    if (imageHandle != null) this.docObj.text(formatDate((+ new Date()), true), this.position.x, (this.position.y + 25))
    } 

    this.position.x -= (75 + 10)
    if (signatureFields[signatureIndex + 1] != null) {
        this.position.x = this.config.page.padding.left;


        this.docObj.setLineWidth(.2).setDrawColor(this.config.color.grey);
        this.docObj.line(
            this.position.x, (this.position.y + 16),
            this.position.x + 75, (this.position.y + 16)
        );

        const imageHandle = localStorage.getItem((signatureFields[signatureIndex + 1] as any).value.id)
        if (imageHandle != null) this.docObj.addImage(
            imageHandle as string,
            'image/png',
            this.position.x, this.position.y,
            75,
        
            20,
            undefined,
            "FAST"
        );


        this._textStyle(this.config.font.regular, 'black', 10);
        this.docObj.text((signatureFields[signatureIndex + 1] as any).value.name || ' ', this.position.x, (this.position.y + 21))

        this._textStyle(this.config.font.regular, this.config.color.secondary, 9);

        if (imageHandle != null) this.docObj.text(formatDate((+ new Date()), true), this.position.x, (this.position.y + 25))
    } 

    this.position.y -= 30


    
  }
  
}
//   this.position.y -= 10
  this._textStyle(this.config.font.regular, this.config.color.secondary, 10);

//   this.docObj.text(this.docObj.splitTextToSize(`Mit meiner Unterschrift bestätige ich, dass ich die vorstehenden Inhalte überprüft habe und diese korrekt sind.`, this.layout.outerWidth - this.config.page.padding.left - this.config.page.padding.right), this.config.page.padding.left,this.position.y)

  
  

// this.position.y += 5
  // this.position.y += 8;
  //   this.docObj.setDrawColor(this.config.color.grey);
  // this.docObj.setLineWidth(0.2);

  // const widthHeight = 7.88


  // // draw square box rectangle (no fill)
  // this.docObj.roundedRect(this.position.x, this.position.y, widthHeight , widthHeight, .75, .75);
  // this.position.y += 3.3

  
  // if (value == true) {
  //   this._textStyle(this.config.font.light, this.config.color.secondary, 20);

  //   this.docObj.text('x', this.position.x + widthHeight/2 - 1.6, this.position.y - 1.6  + widthHeight/2)
  // }

  // this.position.y += widthHeight;


}

// import { BOOLEAN } from "../models/fields/boolean.model";
import { RADIO } from "../models/fields/radio.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";




/**
 * checkbox 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function radio(this: AlinoPDF, data: RADIO):  Promise<void> {

  let text = '-'; 
  if (data.value != null) text =  data.options.elements[data.value].text
  let textWidth = this.docObj.getTextWidth(text) + 6
  // const headerWidth = this.docObj.getTextWidth(data.header) + 6;
  
  if (data.value == null)  textWidth += 5
  await this.textfield(({header: data.header, value: text} as TEXTFIELD), textWidth)

}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { CONTACT_FIELD } from '@/models/exports/pdf/fields/contact.model';


@Component({
  components: {
    TextInput,
    FieldHeader
  },
})


export default class ContactField extends Vue {

  @Prop() value!: CONTACT_FIELD;
  @Prop() showMissing!: boolean;
  @Prop() readonly!: boolean

  update(keyValue: string, eventHandle: string): void {
    if (keyValue == null) return;
    (this.value.value as any)[keyValue] = eventHandle;
    
    this.$report.updateField({...this.value, value: this.value.value})
  }
}

import { FIELDS } from "@/fields.types";
import { TEMPLATE_ELEMENT } from "@/models/api/template.model";
import { CONDITION } from "@/models/condition.model";
import { CONTACT_FIELD } from "@/models/exports/pdf/fields/contact.model";
import { BASE_FIELD } from "@/models/exports/pdf/fields/field";
import store from "@/store";

interface VARIABLE_FIELD extends BASE_FIELD {
    variable: string
}


export function getVariable(node: any, varId: string, limit: string | null = null, isLimit = false): VARIABLE_FIELD | null {
    const list = node.children
        .map((child: any) => {
            if (limit != null && child.variable == limit) isLimit = true;
            if (isLimit == true) return [];

        if (child.type == FIELDS.GROUP && child.children.length > 0)
          return getVariable(child, varId, limit, isLimit);
  
        return child;
      }).flat()
      
      if (list == null || list.length == 0) return null
      else return list.reduce((a: VARIABLE_FIELD, b: VARIABLE_FIELD) => (a.variable == varId ? a : b));
  }


  function formatDate(range: boolean, date: any, time: boolean, timeObj = null): string {
    console.log(range, date, time, timeObj)
    if (range == true) {
        let startDate = new Date(date.start)
        let endDate = new Date(date.end)


        if (time == true) {
            startDate = new Date(`${startDate.toISOString().split('T')[0]}T${timeObj.start}`)

            endDate = new Date(`${endDate.toISOString().split('T')[0]}T${timeObj.end}`)
            return `${startDate.toLocaleTimeString(
                'de-de',
                { year: "numeric", month: 'short', day: "numeric", hour: "2-digit", minute: "2-digit" } as any
              ) + ' Uhr'} - ${endDate.toLocaleTimeString(
                'de-de',
                { year: "numeric", month: 'short', day: "numeric", hour: "2-digit", minute: "2-digit" } as any
              ) + ' Uhr'}`
        } else {
            return `${startDate.toLocaleDateString(
                'de-de',
                { year: "numeric", month: 'short', day: "numeric" } as any
              ) + ' Uhr'} - ${endDate.toLocaleDateString(
                'de-de',
                { year: "numeric", month: 'short', day: "numeric" } as any
              ) + ' Uhr'}`
        }
    } else {
        let dateObj = new Date(date)


        if (time == true) {
            dateObj = new Date(`${dateObj.toISOString().split('T')[0]}T${timeObj.start}`)
            console.log(dateObj, `${dateObj.toISOString().split('T')[0]}T${timeObj.start}`)
            return `${dateObj.toLocaleTimeString(
                'de-de', 
                { year: "numeric", month: 'short', day: "numeric", hour: "2-digit", minute: "2-digit" } as any
              ) + ' Uhr'}`
        }else {
            return `${dateObj.toLocaleDateString(
                'de-de',
                { year: "numeric", month: 'short', day: "numeric" } as any
              ) + ' Uhr'}`
        }
    }

    
  }


  export function replaceTextVariables(report: any, textStr: string): string  {
    const tempValue = textStr.replaceAll('{{', '{{$');
    let formattedArr= tempValue.split('{{').map(ele => ele.split('}}')).flat().filter(Boolean); 


    formattedArr = formattedArr.map((textPart: string) => {
      if (textPart != null && textPart.startsWith('$')) {
        const isHeader = textPart.split('$')[1].startsWith('#');
        const variableObj = getVariable({children: report.steps}, textPart.split('$')[1].replace('#', '')); 
        if (variableObj == null || (variableObj as any).value == null) {
          return '';
        } else {

            if (isHeader == true) return variableObj.header || '';

            let tempVar = null;
            switch (variableObj.type) {
                case FIELDS.TEXTAREA:
                case FIELDS.TEXTFIELD: 
                    return String((variableObj as any).value);
            
                case FIELDS.USER: 
                if ((variableObj as any).options.multiple == false) {
                    tempVar = store.getters.members.user[(variableObj as any).value as string];
                    
                    if (tempVar == null || tempVar.displayName == null) return ''
                    else return tempVar.displayName || tempVar.mail || ''
                } else {
                    return (variableObj as any).value.map((userId: string) => store.getters.members.user[userId] ? store.getters.members.user[userId].displayName || store.getters.members.user[userId].mail || '-' : null ).filter(Boolean).join(', ')
                }
                  


                case FIELDS.DATE:
                    return  formatDate((variableObj as any).options.isRange, (variableObj as any).value.date, (variableObj as any).options.hasTime, (variableObj as any).value.time)//"daterange & time"

                case FIELDS.CONTACT:
                    if ((variableObj as any).value.contactName.length == 0) return '-'

                    return `${(variableObj as any).value.contactName}${(variableObj as any).value.company != null && (variableObj as any).value.company.length > 0 ? `, ${(variableObj as any).value.company}` : ''}${(variableObj as any).value.mail != null && (variableObj as any).value.mail.length > 0 ? ` (${(variableObj as any).value.mail})` : ''}`
         

                case FIELDS.ADDRESS:
                    if ((variableObj as any).value.streetName.length == 0 || (variableObj as any).value.city.length == 0) return '-'

                    return `${(variableObj as any).value.streetName}, ${(variableObj as any).value.zipCode} ${(variableObj as any).value.city}`
         

                case FIELDS.RADIOS:
                    if ((variableObj as any).value == null || (variableObj as any).options.elements[(variableObj as any).value] == null) return; 
                    else return `${(variableObj as any).options.elements[(variableObj as any).value].text}`
                  

                case FIELDS.CHECKBOX:
                    if ((variableObj as any).value == null || !(variableObj as any).value.some(ele => ele == true)) return '-'; 

                    tempVar =(variableObj as any).value.map((ele, index) => ele == true ? index : null).filter(Boolean);

                    console.log("CHECKBOX: ", tempVar, variableObj, (variableObj as any).options.elements.map((element: any, index: number) => tempVar.includes(index ) ? element.text : null).filter(Boolean).join(','))
                    return (variableObj as any).options.elements.map((element: any, index: number) => (variableObj as any).value[index] == true ? element.text : null).filter(Boolean).join(', ')
                    // else return `${(variableObj as any).options.elements[(variableObj as any).value].text}`
                  
                

                
                case FIELDS.SELECTOR:
                    if ((variableObj as any).value == null || (variableObj as any).options.elements[(variableObj as any).value] == null) return; 
                    else return `${(variableObj as any).options.elements[(variableObj as any).value].text}`
                  
                default:
                    
          return String((variableObj as any).value)
            }
        }
      } 
        return textPart
      
    });

    return formattedArr.join('');


  }


  export function checkCondition(condition: CONDITION, recursive = true): boolean {



    // console.log("CHECK CONDITION", condition, store.getters.report)
    try {
      if (condition != null && condition.check == true && condition.fieldId != null && condition.fieldId.length > 0) {

        // TODO: only filter until current step; reduce data
        const fieldObj = getById({children: store.getters.report.steps}, (condition.fieldId as string)); 
        
        // console.log(fieldObj)
        if (fieldObj == null || fieldObj.id != (condition.fieldId as string)) return true; 
        
        if (recursive == true && checkCondition((fieldObj).condition) == false) return false

        if (fieldObj.type == FIELDS.CHECKBOX) {
            return !condition.value.map((val: boolean, index: number) => val == false ? null : val == true && (fieldObj as any).value[index] == val ? true : false ).filter((ele: any) => ele != null).some((ele: boolean) => ele == false)
        } else return ((fieldObj as any).value == condition.value)
      } else {
        return true
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    
  }

  export function getVariables(node: any, varId: string): VARIABLE_FIELD {
    
    return node.children
        .map((child: any) => {
        if (child.type == FIELDS.GROUP && child.children.length > 0)
          return getVariables(child, varId);
  
        return child;
      }).flat().filter((a: VARIABLE_FIELD) => (a.variable == varId));
  }


  export function getById(tree: any, id: string): BASE_FIELD | null {
    let result = null
    if (id === tree.id) {
      return tree
    } else {
      if (tree.children) {
        tree.children.some((node: BASE_FIELD) => result = getById(node, id));
      }
      return result;
    }
  }


  export function getIds(node: any): string[] {
    return node.children
        .map((child: any) => {
        if (child.type == FIELDS.GROUP && child.children.length > 0)
          return getIds(child);
  
        return child.id;
      }).flat().filter(Boolean);
  }



export function getReturnType(type: string): string {
    switch (type) {

        case FIELDS.TEXTAREA:
        case FIELDS.TEXTFIELD:
            return 'STRING';

        case FIELDS.ADDRESS:
        case FIELDS.CONTACT:
            return 'OBJECT';
        
        case FIELDS.IMAGE: 
            return 'OBJECT[]';
        
        case FIELDS.BOOLEAN: 
        case FIELDS.CHECKLIST:
            return 'BOOLEAN';
        
        case FIELDS.RADIOS: 
            return 'NUMBER';
        
        case FIELDS.CHECKBOX: 
            return 'NUMBER[]'
    
        default:
            return '-'
    }
}
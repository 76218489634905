import "../fonts/Lato/Lato-Black-normal.js";
import "../fonts/Lato/Lato-Regular-normal.js";

import "../fonts/Lato/Lato-Bold-normal.js";
import "../fonts/Lato/Lato-Thin-normal.js";
import "../fonts/Lato/Lato-Light-normal.js";

import jsPDF, { jsPDFOptions } from "jspdf";
import { PDF_CONFIG } from "./models/config.model";
import { PDFDocument, PDFPage } from "pdf-lib";
import axios from "axios";
import { Buffer } from 'buffer';
import { PDF_DATA } from "./models/data.model";
import { wrapper, renderData } from "./pdf.wrapper";
import { textfield } from "./fields/textfield.field";
import { address } from "./fields/address.field";
import { contact } from "./fields/contact.field";
import { inventory } from "./fields/inventory.field";
import { image } from "./fields/image.field";

import { box } from "./fields/box.field";
import { boolean } from "./fields/boolean.field";
import { checklist } from "./fields/checklist.field";
import { datetime } from "./fields/datetime.field";
import { group } from "./fields/group.field";
import { schedule } from "./fields/schedule.field";
import { checkbox } from "./fields/checkbox.field";
import { radio } from "./fields/radio.field";
import { selector } from "./fields/selector.field";
import { user } from "./fields/user.field";
import { information } from "./fields/information.field";

export const SPACER = {
    HORIZONTAL: 5,
    VERTICAL: 5,
};
interface DOC_POSITION {
    x: number;
    y: number;
}

export class AlinoPDF {
    public docObj: jsPDF = new jsPDF();
    private filename: string | null = null;

    public config: PDF_CONFIG = {
        header: "",
        subheader: "",
        color: {
            primary: "#cc2029",
            secondary: "#808080",
            grey: "#c0c0c0",
        },

        font: {
            bold: "Lato-Bold",
            regular: "Lato-Regular",
            light: "Lato-Light",
        },

        encryption: {
            allowEncryption: false,
            passwords: {
                user: "",
                master: "",
            },
        },

        page: {
            width: 210,
            height: 297,

            padding: {
                left: 20,
                right: 20,
                bottom: 5,
                top: 15,
            },

            header: {
                logo: {
                    render: true,
                    url: "https://firebasestorage.googleapis.com/v0/b/alinoreport.appspot.com/o/9566d676-ab65-4a01-ba06-94b8f9f07200%2Fgeneral%2Fcompany-logo?alt=media&token=db875e32-0ade-42f8-9f9b-f4f8f28f7b4b",
                    fileType: "image/png",
                    width: 100,
                    height: 10,
                },
                pagination: false,
            },
            footer: {
                fontSize: 8,
                legal: [],
            },
        },
    };

    public layout = {
        outerHeight: 0,
        outerWidth: 0,
        innerHeight: 0,
        innerWidth: 0,
        innerTop: 0,
        innerBottom: 0,
    };

    public position = {
        x: 0,
        y: 0,
    };

    private content = {
        width: 0,
        height: 0,
    };

    public appendFile = []
    private logoURL: string | null = null;

    public inventoryObj: any = {elements: [], identifier: []}
    public userList: any = {}
    /**
     * constructor
     * @param filename string desired filename of PDF
     * @param configuration pdf config
     */
    constructor(filename: string, configuration: PDF_CONFIG) {
        // pdf config (page size, format, etc.)
        let fileConfig: jsPDFOptions = {
            orientation: "p",
            unit: "mm",
            format: "a4",
            putOnlyUsedFonts: true,
            floatPrecision: 16,
        };

      
        // setting up file encryption if specified
        if (
            configuration.encryption != null &&
            configuration.encryption?.allowEncryption == true
        ) {
            const encryp = configuration.encryption; // temp object reference

            fileConfig = {
                ...fileConfig,
                encryption: {
                    userPassword: encryp.passwords.user,
                    ownerPassword: encryp.passwords.master,
                    userPermissions: ["print", "copy"],
                },
            };
        }

        // create global jsPDF reference doc
        this.docObj = new jsPDF(fileConfig);
        (this.docObj as any).page = 0;
        this.filename = filename.replace(".pdf", "") + ".pdf";

        this.config = configuration;

        const docWidth = this.docObj.internal.pageSize.getWidth();
        const docHeight = this.docObj.internal.pageSize.getHeight();
        this.layout = {
            outerWidth: docWidth,
            outerHeight: docHeight,
            innerBottom: 0,
            innerTop: 0,

            innerWidth:
                docWidth -
                configuration.page.padding.left -
                configuration.page.padding.right,
            innerHeight: 0,
        };
    }

    public schedule = schedule;
    public group = group;
    public checkbox = checkbox;
    public box = box;
    public boolean = boolean;
    public textfield = textfield;
    public selector = selector;
    public radio = radio;
    public address = address;
    public contact = contact;
    public user = user;
    public inventory = inventory;
    public image = image;
    public checklist = checklist;
    public datetime = datetime;
    public information = information

    /**
     * _export
     *
     * private function to export the created PDF file from the global jspdf variable
     *
     * @param download boolean whether a file shall be downloaded as blob
     * @returns string datauri of pdf
     */
    public async export(download = false): Promise<string> {
        const filenameStr = String(this.filename || "");

        if (this.appendFile != null && this.appendFile.length > 0) {
            console.log("APPEND:", this.appendFile)
        
            const bufferArr = []

            for (let appendIndex = 0; appendIndex < this.appendFile.length; appendIndex++) {
                if ((this.appendFile as any)[appendIndex] == null || (this.appendFile as any)[appendIndex].url == null) continue
                const resp = await axios.get(
                    (this.appendFile as any)[appendIndex].url, //hosted with serve
                    { responseType: 'arraybuffer' })
                    console.log(resp)

                    if (resp == null || resp.status != 200) continue;
                    else bufferArr.push(resp.data)
                
            }
            
    

            
    
                const data = this.docObj.output("arraybuffer" as any, { filename: filenameStr }); 

                // console.log(resp.data, data)
                return await this.merge([data, ...bufferArr].flat())
        }

        // file is downloaded (BLOB) if user sets the download flag;
        if (download == true) this.docObj.save(filenameStr);

        // file is returned as datauri
        return String(this.docObj.output("datauristring", { filename: filenameStr }));
    }

    /**
     * merge
     *
     * merges multiple files together into one base64 file
     *
     * @param fileList ArrayBuffer[] array with all files to be merged as buffer
     * @returns string merged files as base64 URI
     */
    public async merge(fileList: ArrayBuffer[]): Promise<string> {
        // pdf handler of merged files
        const pdfHandle: PDFDocument = await PDFDocument.create();

        // inner promise
        const createInnerPromise = async (
            arrayBuffer: ArrayBuffer
        ): Promise<PDFPage[]> => {
            const pdf: PDFDocument = await PDFDocument.load(arrayBuffer);
            return await pdfHandle.copyPages(pdf, pdf.getPageIndices());
        };

        // outer promise
        const outerPromise: Promise<PDFPage[]>[] = fileList.map(
            (arrayBuffer) => {
                const innerPromise: Promise<PDFPage[]> =
                    createInnerPromise(arrayBuffer);
                return innerPromise;
            }
        );

        const resultOuterPromise: PDFPage[][] = await Promise.all(outerPromise);

        resultOuterPromise.forEach((pageArray: PDFPage[]) => {
            pageArray.forEach((page: PDFPage) => {
                pdfHandle.addPage(page);
            });
        });

        return await pdfHandle.saveAsBase64({ dataUri: true });
    }

    public async _imageBase64(
        url: string,
        type: string
    ): Promise<string | null> {
        
        try {
            const base64 = await axios
                .get(url, { responseType: "arraybuffer" })
                .then((response) =>
                    Buffer.from(response.data, "binary").toString("base64")
                );
                

            return `data:${type};base64,${base64}`;
        } catch (err) {
            console.error(err)
            return null;
        }
    }

    _divider(color: string, stroke = 0.1): void {
        if (this.config == null) return;

        // add divider between spacer (can be realted to as spacer with line)
        this.position.y += SPACER.VERTICAL / 2;

        this.docObj.setLineWidth(stroke).setDrawColor(color);
        this.docObj.line(
            this.config.page.padding.left,
            this.position.y,
            this.layout.innerWidth + this.config.page.padding.left,
            this.position.y
        );

        this.position.y += SPACER.VERTICAL;
        this.position.x = this.config.page.padding.left;
    }

    _textStyle(textStyle: string, color: string, size: number, fontType = 'normal'): void {

        this.docObj.setTextColor(color);
        this.docObj.setFont(textStyle, fontType).setFontSize(size);

        
  }

    private _setFooter(): void {
        const legalArr = this.config?.page.footer.legal;


        if (legalArr == null || legalArr.length == 0) return;

        this.layout.innerBottom =
            this.config.page.padding.bottom + 20 + 2 + SPACER.VERTICAL;

        this.layout.innerHeight =
            this.layout.outerHeight -
            this.layout.innerBottom -
            this.layout.innerTop;

        this.position.y = this.layout.outerHeight - this.layout.innerBottom;

        // this.docObj.text('jkdslmf', 50, this.position.y)
        this._divider(this.config.color.secondary);

        this._textStyle(
            this.config.font.regular,
            this.config.color.secondary,
            this.config.page.footer.fontSize
        );

        const rowsMaxWidth = legalArr.map((col: any) =>
            this.docObj.getTextWidth(
                col.data.reduce((a: any, b: any) =>
                    a.length > b.length ? a : b
                )
            )
        );

        // calculate the average spacing between the cols to expand footer to full innerWidth
        const colSpacing =
            (this.layout.innerWidth -
                rowsMaxWidth.reduce((pv: number, cv: number) => pv + cv, 0)) /
            (legalArr.length - 1);

        const footerYStart = this.position.y;
        legalArr.forEach((footerCol: any, index: number) => {
            // footer column header
            this._textStyle(
                this.config.font.bold,
                this.config.color.secondary,
                this.config.page.footer.fontSize + 0.5
            );
            this.docObj.text(
                footerCol.header,
                this.position.x,
                this.position.y
            );

            this.position.y += 4;

            // footer column data
            this._textStyle(
                this.config.font.regular,
                this.config.color.secondary,
                this.config.page.footer.fontSize
            );

            // add each footer row
            footerCol.data.forEach((rowText: string) => {
                if (rowText.length > 0) {
                    this.docObj.text(rowText, this.position.x, this.position.y);
                    this.position.y += 2;
                }

                this.position.y += 1.5;
            });
            // add calculated spacer between cols & reset y position to footer top y
            this.position.x += rowsMaxWidth[index] + colSpacing;
            this.position.y = footerYStart;
        });
    }

    private async _setHeaderLogo(): Promise<number | void> {
        const logoObj = this.config.page.header.logo;

        if (logoObj == null || logoObj.render != true || logoObj.url == null)
            return;


        if (this.logoURL == null)
            this.logoURL = await this._imageBase64(
                logoObj.url,
                logoObj.fileType
            );

        // console.log(this.logoURL)
        // console.log(this.logoURL)
        if (this.logoURL != null) {
            // const img: HTMLImageElement = await new Promise(
            //     (resolve, reject) => {
            //         const img = new Image();
            //         img.onload = () => resolve(img);
            //         img.onerror = reject;
            //         img.src = this.logoURL as string;
            //     }
            // );

            const imageHeight = 8;
            const imageWidth = (logoObj.width / logoObj.height) * imageHeight;

            // add logo to document
            this.docObj.addImage(
                this.logoURL as string,
                logoObj.fileType.split("/")[1].toUpperCase(),
                this.layout.innerWidth +
                    this.config.page.padding.left -
                    imageWidth,
                this.config.page.padding.top,
                imageWidth,
                imageHeight,
                undefined,
                "FAST"
            );


        

            this.position.x =
                this.config.page.padding.top +
                imageHeight +
                SPACER.VERTICAL / 2;
            return imageWidth;
        }


        return 0;
    }

    private async _setHeader(): Promise<void> {
    

       
        const imageWidth = await this._setHeaderLogo();
        
        const maxHeaderTextLength =
            this.layout.innerWidth - (imageWidth || 50) - SPACER.HORIZONTAL;

        this._textStyle(
            this.config.font.regular,
            this.config.color.secondary,
            10
        );

        const subheaderRowArr = this.docObj.splitTextToSize(
            this.config.subheader,
            maxHeaderTextLength
        );

        this.docObj.text(
            subheaderRowArr,
            this.config.page.padding.left,
            this.config.page.padding.top + 1.5
        );
        const subheaderRowHeight =
            this.docObj.getTextDimensions(subheaderRowArr).h;

        this._textStyle(this.config.font.bold, this.config.color.primary, 14);

        const headerRowArr = this.docObj.splitTextToSize(
            this.config.header,
            maxHeaderTextLength
        );

        this.docObj.text(
            headerRowArr,
            this.config.page.padding.left,
            this.config.page.padding.top + subheaderRowHeight + 4
        );

        const headerRowHeight = this.docObj.getTextDimensions(headerRowArr).h;

        this.layout.innerTop =
            headerRowHeight +
            subheaderRowHeight +
            this.config.page.padding.top +
            SPACER.VERTICAL * 2 + 4
    }

    public wrapper = wrapper;
    public renderData = renderData;

    public async create(
        data: PDF_DATA | null,
        addPage = false
    ): Promise<string | void> {
        // console.log("ADD PAGE", this.docObj.internal.pages.length)
        // if (this.docObj.internal.pages.length > 1) this.docObj.addPage();
        (this.docObj as any).page += 1;
        if (addPage == true) this.docObj.addPage();

     
        await this._setHeader();
        this._setFooter();

        this.position.x = this.config.page.padding.left;
        this.position.y = this.layout.innerTop;

        // await this.boolean(true);
        // this.position.y += 5;

        // await this.boolean(false);
        if (data == null) return;

        if ((data as any).inventory != null) {
            this.inventoryObj = (data as any).inventory || []
        }

        if ((data as any).members != null) {
            this.userList = (data as any).members || {}
        }
        await this.renderData(data as PDF_DATA, "");


        console.log(this.docObj.internal.pages.filter(Boolean))
        for (let index = 0; index < this.docObj.internal.pages.length; index++) {
            if (this.docObj.internal.pages[index] == null) continue;
            this.docObj.setPage(index)
            this._textStyle(
                this.config.font.regular,
                this.config.color.secondary,
                10
            );
            const text = `SEITE ${index} VON ${this.docObj.internal.pages.length - 1}`; 
            const textWidth = this.docObj.getTextWidth(text)
            this.docObj.text(text, this.layout.innerWidth +
        this.config.page.padding.left  -textWidth, this.config.page.padding.top  + 12)
        }


      
        // console.log(this.docObj.internal.pages.length - 1)
        // this.docObj.addPage()

        // this.create(data)

        // console.log("MOIN");

        // this.docObj.addPage()
        // this.create(data)

        // this.docObj.text(String(logo), 100, 100);

        // console.log("ALMOST DONE");
        // this.docObj.setFillColor('#CC2029')
        // this.docObj.roundedRect(this.config.page.padding.left, this.layout.outerHeight - this.layout.innerBottom, this.layout.innerWidth, this.layout.innerBottom, 0, 0, 'F');

        // this.docObj.setFillColor('#808080')
        // this.docObj.roundedRect(this.config.page.padding.left, 0, this.layout.innerWidth, this.layout.innerTop, 0, 0, 'F');

        // this.docObj.setFillColor('#123456')
        // this.docObj.roundedRect(this.config.page.padding.left, this.layout.innerTop, this.layout.innerWidth, this.layout.innerHeight, 0, 0, 'F');
    }
}

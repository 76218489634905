

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import TextButton from '@components/button/TextButton.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue';
import axios from 'axios';
import Papa from 'papaparse'
import TableListRow from '@components/table/TableListRow.vue'
import IconButton from '@components/button/IconButton.vue'

import SelectorInput from '@components/text/SelectorInput.vue'
import DialogTemplate from '@components/dialog/DialogTemplate.vue'
import InventoryElement from './InventoryElement.vue';
@Component({
    components: {
        IconButton,
        DialogTemplate,
        SelectorInput,
        InventoryElement,
        FieldHeader,
        TextInput,
        TextButton,
        TableListRow
    },
})


export default class InventoryTableField extends Vue {

    @Prop() value!: any;
    @Prop() readonly!: boolean;

    onlyRelevant=false;
    loadingInventory=false;
    selectedInventoryGroup=this.inventoryGroups!=null&&this.inventoryGroups.length>0? this.inventoryGroups[0].id:null

    inventoryData: any|null=0;
    inventoryDialog=false;

    inventorySearchValue=''

    itemDialog=false;
    itemData=null;

    mounted(): void {
        if (this.readonly) this.onlyRelevant=true
    }
    openItemInformation(item: any): void {
        this.itemData=item;
        this.itemDialog=true;
    }



    async update(eventHandle: string): Promise<void> {

        // console.log("JS")
        await this.$report.updateField({ ...this.value, value: eventHandle })
    }

    get inventoryGroups(): any {
        if (this.$store.getters.organisation.inventory == null || this.$store.getters.organisation.inventory.file==null||this.$store.getters.organisation.inventory.identifier==null||this.$store.getters.organisation.inventory.identifier.id==null) return [];
        return (this.$store.getters.organisation.inventory.groups || []).map((groupId: string) => ({ text: groupId, id: groupId }))
    }

    get inventoryDataFiltered(): any {

        if (this.$store.getters.organisation.inventory == null || this.$store.getters.organisation.inventory.file==null) return []
        if (this.selectedInventoryGroup!=null&&this.inventoryData[this.selectedInventoryGroup]!=null) {
            return (this.inventoryData[this.selectedInventoryGroup].filter((value: any) => this.onlyRelevant==false||(this.value.value[value[this.$store.getters.organisation.inventory.identifier.id]]!=null)).filter((value: any) => this.inventorySearchValue.length==0||value[this.$store.getters.organisation.inventory.identifier.id].toLowerCase().includes(this.inventorySearchValue.toLowerCase())||value[this.$store.getters.organisation.inventory.identifier.name].toLowerCase().includes(this.inventorySearchValue.toLowerCase()))||[])
        } else {
            return Object.values(this.inventoryData).flat().filter((value: any) => this.onlyRelevant==false||(this.value.value[value[this.$store.getters.organisation.inventory.identifier.id]]!=null)).filter((value: any) => this.inventorySearchValue.length==0||value[this.$store.getters.organisation.inventory.identifier.id].toLowerCase().includes(this.inventorySearchValue.toLowerCase())||value[this.$store.getters.organisation.inventory.identifier.name].toLowerCase().includes(this.inventorySearchValue.toLowerCase()))||[]
        }

    }

    @Watch('inventorySearchValue', { immediate: true })
    onSearchChange(): void {
        if (this.$store.getters.organisation.inventory.file==null||this.$store.getters.organisation.inventory.identifier==null||this.$store.getters.organisation.inventory.identifier.id==null) return;

        if ((this.inventorySearchValue || '').length==0) return;

        this.onlyRelevant==false;
    }



    @Watch('inventoryDialog')
    onDialogChange(): void {
        if (this.inventoryDialog==true) return;
        setTimeout(() => {
            this.inventoryData=null;
            this.selectedInventoryGroup=0;
            this.inventorySearchValue='';
            if (!this.readonly) this.onlyRelevant=false;


            this.update(this.value.value)
        }, 250)
    }

    // async beforeDestroy(): Promise<void> {
    //     if (this.$store.getters.organisation.inventory.file==null||this.$store.getters.organisation.inventory.identifier==null||this.$store.getters.organisation.inventory.identifier.id==null) return;
    //     else await this.update(this.value.value)
    // }

    // @Watch('value.value', {deep: true}) 
    // onValueChange()
    async openInventory(): Promise<void> {
        // console.log("OPEN", this.$store.getters.organisation);

        this.loadingInventory=true;
        this.selectedInventoryGroup=0;
        this.inventorySearchValue=''

        const inventory=this.$store.getters.organisation.inventory.file;

        // console.log(inventory)
        const resp=await this.$firebase.storage.get(inventory.path);

        const csvResponse=await axios.get(resp, { responseType: 'blob' })

        console.log(csvResponse)
        if (csvResponse.status!=200||csvResponse.data==null) throw new Error();

        // console.log(await csvResponse.data.text())
        const data=await Papa.parse(await csvResponse.data.text(), { header: true })

        if (this.$store.getters.organisation.inventory.identifier.group!=null&&this.$store.getters.organisation.inventory.identifier.group.length>0&&this.$store.getters.organisation.inventory.groups!=null&&this.$store.getters.organisation.inventory.groups.length>0) {

            this.inventoryData=this.groupByKey(data.data, this.$store.getters.organisation.inventory.groups, this.$store.getters.organisation.inventory.identifier.group)//data.data;

        } else {
            this.inventoryData={ "unknown": data.data }
        }
        this.inventoryDialog=true;

        this.loadingInventory=false;
        // console.log()
        // this.$store.getters.organisation.inventory.groups

    }


    groupByKey(data: any[], keys: string[], keyId: string): any {
        const bins: any={};

        // console.log(data, keys)
        for (const key of keys) {
            bins[key]=[];
        }

        for (const item of data) {
            const selectedKey=item[keyId];

            if (keys.includes(selectedKey)) {
                bins[selectedKey].push(item);
            }
        }

        return bins;
    }

}

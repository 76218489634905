import { ADDRESS } from "../models/fields/address.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";


const ADDRESS_BREAK_HEIGHT = 40

/**
 * address 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (ADDRESS) address input object and config data
 */
export async function address(this: AlinoPDF, data: ADDRESS):  Promise<void> {

  // address field is always showed as one field; if remaining space less than max field height
  // page is broken and field is displayed on new page
  if (this.position.y + ADDRESS_BREAK_HEIGHT > (this.layout.outerHeight - this.layout.innerBottom)) {
    
    // create new page;
    await this.create(null, true)
    this.position.y = this.layout.innerTop;
  }
  
  this.position.y += 1; 
  this.position.x = this.config.page.padding.left; 

  // field header
  if (data.header != null && data.header.length > 0) {
    this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
    this.docObj.text(data.header.toUpperCase(), this.position.x, this.position.y);

    this.position.y += 7;
  } else {
    this.position.y += 2.75
  }
  

  // street textfield
  await this.textfield(({header: 'Straße & Hausnnummer', value: data.value.streetName} as TEXTFIELD))
  this.position.y -= 2.5; // reduce height between fields in combined field 
  

  const zipCodeYPosition = this.position.y; // save y-position as a row is creeated

  // zip-code textfield
  await this.textfield(({header: 'PLZ', value: data.value.zipCode} as TEXTFIELD), 40)

  
  this.position.y = zipCodeYPosition; // reset to previous row y-position
  this.position.x = this.config.page.padding.left + 40 + SPACER.HORIZONTAL; // offset to zip-code field

  // city textfield
  await this.textfield(({header: 'Stadt', value: data.value.city} as TEXTFIELD), this.layout.innerWidth - 40 - SPACER.HORIZONTAL)

  this.position.y -= 2;

  // add margin to file position
  this.position.y +=  SPACER.VERTICAL;
  this.position.x = this.config.page.padding.left;

}

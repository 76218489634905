<template>
  <div style="position: relative;">
    <v-tooltip :disabled="tooltip == undefined || $vuetify.breakpoint.smAndDown"  :open-delay="tooltipDelay"
      :bottom="tooltipPosition == 'b' || tooltipPosition == undefined" :top="tooltipPosition == 't'"
      :left="tooltipPosition == 'l'" :right="tooltipPosition == 'r'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" :ripple="false" rounded :block="block" :small="dense" :disabled="disabled"
          :loading="loading" depressed v-on:click.stop="$emit('click')" :color="buttonColor">
          <v-row align="center" justify="center" class="ma-0 pr-3 pl-3" :class="`${textColor}--text`">
            <v-icon class="" v-if="icon" :size="dense ? '18' : '21'" >{{ icon }}</v-icon>
            <span v-if="text.length > 0" class="font-weight-bold ml-2 mr-1">{{ text }}</span>
            <v-icon v-if="outerIcon" :size="dense ? '18' : '21'" >{{ outerIcon }}</v-icon>
          </v-row>
        </v-btn>
      </template>
      <div class="sub-text" style="font-size: 12px">{{ tooltip }}</div>
    </v-tooltip>
  </div>
</template>

<script>
// dkjnjskd
export default {
  props: {
    
    block: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: ''
    },

    outerIcon: {
      type: String,
      default: undefined
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    textColor: {
      type: String,
      required: false,
      default: undefined,
    },

    buttonColor: {
      type: String,
      required: false,
      default: undefined,
    },

    tooltipDelay: {
      type: [String, Number],
      required: false,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: "b", // 'l', 'r', 't'
      validator: function (value) {
        return ["b", "l", "r", "t"].indexOf(value) !== -1;
      },
    },
  },
};
</script>



<style scoped>

.sub-text {
  font-size: 13px; 
  color: white;
  user-select: none;
  font-weight: 700;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}

</style>
// import { BOOLEAN } from "../models/fields/boolean.model";
import { SELECTOR } from "../models/fields/selector.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";




/**
 * checkbox 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function user(this: AlinoPDF, value: any):  Promise<void> {

    // console.log("USERLISSSST",this.userList, value.value)
    const userList = [value.value].flat().map((id: string) => (this.userList.user[id] == null ? (this.userList.groups[id] == null ? '' : this.userList.groups[id].header) : this.userList.user[id].displayName))
//   console.log("RES", userList)
    await this.textfield(({header: value.header, value: userList.join(',  ')} as TEXTFIELD), value.value.length == 1 ? 150 : null)

}

import './router/componentHooks' // <-- Needs to be first
import buffer from 'buffer'; // pdfkit
global.Buffer = buffer.Buffer;


import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'

import './assets/fonts/Lato/stylesheet.css'

import './plugins/firebase';
// import './plugins/firebasse/firebase.auth';
import './plugins/api/reports.api';
import './plugins/api/report.api';

import './plugins/api/organisations.api';
import './plugins/api/templates.api';
import './plugins/api/labels.api';
import './plugins/api/changelog.api';

import "./scss/toast.scss";

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA2gURMJptCTSj2p5PiaD8g_FoTwHVXyrI',
    libraries: 'places',
  },
});

import "vue-toastification/dist/index.css";
import Toast,  { POSITION }   from "vue-toastification";

import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 1,
  position: POSITION.BOTTOM_CENTER,
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  icon: true,
  hideProgressBar: true,
  transitionDuration: {

    enter: 100,
    leave: 100
  }
});


import FieldWrapper from '@/components/reports/fields/FieldWrapper.vue'
import VCalendar              from 'v-calendar';
import store from './store'
Vue.use(VCalendar, {
  componentPrefix: 'vc', 
});

import VueKonva from 'vue-konva';

Vue.use(VueKonva)

export const editorEventBus = new Vue(); // creating an event bus.

Vue.component('FieldWrapper', FieldWrapper)
Vue.config.productionTip = false

Vue.prototype.$fontSize = 1
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')


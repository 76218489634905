
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import FieldHeader from '@/components/helper/FieldHeader.vue';

import { TEXTAREA_FIELD } from '@/models/exports/pdf/fields/textarea.model';
@Component({
  components: {
    FieldHeader
  },
})


export default class TextAreaField extends Vue {

  @Prop() value!: TEXTAREA_FIELD
  @Prop() showMissing!: boolean
  @Prop() readonly!: boolean;

  inputValue = ''
  update(): void {
    
    if (this.readonly == true) return;
    console.log("JS")
    this.$report.updateField({...this.value, value: this.inputValue})
  }



}

import _Vue from "vue";
import { uuidv4 } from "@firebase/util";

import {
    getDocs,
    runTransaction,
    where,
    orderBy,
    startAfter,
    limit,
    collection,
    query,
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    doc,
    getDoc,
    setDoc,
    onSnapshot,
    updateDoc,
    arrayUnion,
} from "firebase/firestore";

import { firebaseFirestore } from "../firebase";
import store from "@/store/index";
import { LABEL_ELEMENT } from "@/models/api/labels.model";
import { CHANGELOG } from "@/models/changelog.model";
import router from "@/router";
import { LABEL_ROLE } from "@/config/labels.roles";
import axios from "axios";

declare module "vue/types/vue" {
    export interface Vue {
        $labels: LabelsPlugin;
    }
}

class LabelsPlugin {
    lastTemplateElement: QueryDocumentSnapshot | null = null;
    prevTemplateElement: QueryDocumentSnapshot | null = null;

    groupCollection = collection(
        firebaseFirestore,
        "organisations"
    ).withConverter(new LabelsConverter());

    static install(Vue: typeof _Vue) {
        Vue.prototype.$labels = new LabelsPlugin();
    }

    resetPagination(): void {
        this.lastTemplateElement = null;
        this.prevTemplateElement = null;
    }

    async object(
        organisationId: string,
        labelId: string,
        referenceId: string
    ): Promise<any> {
        console.log(organisationId, labelId);
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new LabelsConverter());

        const snapshot = await getDoc(doc(organisationCollection, referenceId));

        return snapshot.data();
    }

    async objects(organisationId: string, labelId: string): Promise<any> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels",
            labelId,
            "refs"
        ).withConverter(new LabelsConverter());

        const snapshot = await getDocs(organisationCollection);

        return snapshot.docs.map(
            (ele: QueryDocumentSnapshot) => ele.data() as any
        );
    }

    async getMemberLabels(organisationId: string): Promise<void> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels"
        ).withConverter(new LabelsConverter());

        const q = query(organisationCollection, where("user", "==", true));

        const snapshot = await getDocs(q);

        const data = snapshot.docs.map(
            (ele: QueryDocumentSnapshot) => ele.data() as LABEL_ELEMENT
        );
        console.log(data);

        const result = data.reduce(function (r, e) {
            r[e.id] = {};
            Object.keys(e).forEach(function (k) {
                if (k != "id") r[e.id] = Object.assign(r[e.id], { [k]: e[k] });
            });
            return r;
        }, {});

        store.commit("setOrganisationMemberGroups", result);
    }

    async get(
        organisationId: string,
        searchValue: string,
        all = false
    ): Promise<LABEL_ELEMENT[]> {
        console.log("GEEEEEET");
        if (all == true) this.resetPagination();
        this.prevTemplateElement = this.lastTemplateElement;
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels"
        ).withConverter(new LabelsConverter());

        let q = query(
            organisationCollection,
            where("users", "array-contains", _Vue.prototype.$firebase.auth.uid)
        );

        if (searchValue.length > 0) {
            q = query(
                q,
                orderBy("header"),
                where("header", ">=", searchValue),
                where("header", "<=", searchValue + "\uf8ff"),
                orderBy("createdAt", "desc")
            );
        } else if (this.lastTemplateElement == null) {
            q = query(q, orderBy("createdAt", "desc"));
        } else {
            q = query(
                q,
                orderBy("createdAt", "desc"),
                startAfter(this.lastTemplateElement)
            );
        }

        if (all == false) q = query(q, limit(25));
        const snapshot = await getDocs(q);

        if (searchValue.length > 0) {
            this.lastTemplateElement = null;
        } else {
            this.lastTemplateElement =
                snapshot.docs[snapshot.docs.length - 1] || null;
        }

        return snapshot.docs.map(
            (ele: QueryDocumentSnapshot) => ele.data() as LABEL_ELEMENT
        );
    }

    async getById(
        organisationId: string,
        id: string
    ): Promise<LABEL_ELEMENT | null> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels"
        ).withConverter(new LabelsConverter());

        try {
            const snapshot = await getDoc(doc(organisationCollection, id));
            const organisationData = snapshot.data();

            return organisationData || null;
        } catch (error) {
            return null;
        }
    }

    async calendar(labelId: string): Promise<any> {
        console.log("CALENDAR");
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            router.currentRoute.params.organisationId,
            "labels",
            labelId,
            "calendar"
        ).withConverter(new LabelsConverter());

        let q = query(
            organisationCollection,
            where(
                "invitees",
                "array-contains",
                _Vue.prototype.$firebase.auth.uid
            )
        );

        q = query(q, limit(25));
        const snapshot = await getDocs(q);

        console.log(
            snapshot.docs.map((ele: QueryDocumentSnapshot) => ele.data() as any)
        );

        return snapshot.docs.map(
            (ele: QueryDocumentSnapshot) => ele.data() as any
        );
    }

    async calendarEntry(entry: any): Promise<void> {
        const resp = await axios.post(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/${router.currentRoute.params.organisationId}/calendar/${entry.labelId}`,
            {
                ...entry,
            },
            {
                headers: {
                    Authorization:
                        await _Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );

        if ((resp as any).status != 200 || (resp as any).data.error == true) {
            throw new Error();
        }
    }

    watch(
        organisationId: string,
        id: string,
        callback: (label: any | undefined) => void
    ): void {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels"
        ).withConverter(new LabelsConverter());

        onSnapshot(doc(organisationCollection, id), {
            next: (snapshot) => {
                console.log(snapshot.data());
                callback(snapshot.data());
            },
        });
    }

    async update(label: LABEL_ELEMENT): Promise<void> {
        console.log("this.update", label);
        try {
            const labelCollection = collection(
                firebaseFirestore,
                "organisations",
                router.currentRoute.params.organisationId,
                "labels"
            ).withConverter(new LabelsConverter());
            // console.log(resp)
            await setDoc(doc(labelCollection, label.id), label);
        } catch (error) {
            if (error.code == "permission-denied")
                _Vue.prototype.$toast.error("Fehlende Nutzerrechte");
        }
    }

    async changelog(
        organisationId: string,
        labelRefs: any,
        changelog: Partial<CHANGELOG>
    ): Promise<void> {
        console.log("HER", labelRefs);
        await runTransaction(firebaseFirestore as any, async (transaction) => {
            console.log("H");

            const labelsChangelogArr = [];
            for (let index = 0; index < labelRefs.length; index++) {
                const organisationCollection = collection(
                    firebaseFirestore,
                    "organisations",
                    organisationId,
                    "labels",
                    labelRefs[index].id,
                    "refs"
                ).withConverter(new ChangelogConverter());

                for (
                    let refIndex = 0;
                    refIndex < labelRefs[index].refs.length;
                    refIndex++
                ) {
                    const docRef = doc(
                        organisationCollection,
                        labelRefs[index].refs[refIndex]
                    );
                    const docHandle = await transaction.get(docRef);

                    labelsChangelogArr.push({
                        ref: docRef,
                        exists: docHandle.exists(),
                    });
                }
            }

            const changelogObj = {
                ...changelog,
                date: +new Date(),
                editor: _Vue.prototype.$firebase.auth.uid as string,
            };

            for (let index = 0; index < labelsChangelogArr.length; index++) {
                if (labelsChangelogArr[index].exists != true)
                    await transaction.set(labelsChangelogArr[index].ref, {
                        status: changelog.status,
                        lastEdited: +new Date(),
                        createdAt: +new Date(),
                        createdBy: _Vue.prototype.$firebase.auth.uid as string,
                        log: [changelogObj],
                    });
                else {
                    await transaction.update(labelsChangelogArr[index].ref, {
                        status: changelog.status,
                        lastEdited: +new Date(),
                        log: arrayUnion(changelogObj),
                    });
                }
            }
        });
    }

    async create(
        organisationId: string,
        labelObj: LABEL_ELEMENT
    ): Promise<LABEL_ELEMENT> {
        const newLabelObj: any = {
            ...labelObj,

            id: uuidv4(),
            createdBy: _Vue.prototype.$firebase.auth.uid as string,
            createdAt: +new Date(),
            members: {
                [_Vue.prototype.$firebase.auth.uid as string]:
                    LABEL_ROLE.MANAGER,
            },
        };
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "labels"
        ).withConverter(new LabelsConverter());

        await setDoc(doc(organisationCollection, newLabelObj.id), newLabelObj);

        return newLabelObj;
    }

    // async update(project: LABEL_ELEMENT): Promise<void> {
    //   const projectHandle = project;

    //   try {
    //     await setDoc(doc(this.groupCollection, projectHandle.id), projectHandle);

    //     // store.dispatch("organisation", project as LABEL_ELEMENT);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
}

class ChangelogConverter implements FirestoreDataConverter<any> {
    toFirestore(organisation: any): DocumentData {
        const json = { ...organisation } as any;
        delete json.id;
        return json;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): any {
        const json = snapshot.data();
        return { ...json, id: snapshot.id } as any;
    }
}

class LabelsConverter implements FirestoreDataConverter<LABEL_ELEMENT> {
    toFirestore(organisation: LABEL_ELEMENT): DocumentData {
        const json = {
            ...organisation,
            users: Object.keys(organisation.members || {}),
        } as any;
        delete json.id;
        return json;
    }

    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>
    ): LABEL_ELEMENT {
        const json = snapshot.data();
        delete json.users;
        return { ...json, id: snapshot.id } as LABEL_ELEMENT;
    }
}

_Vue.use(LabelsPlugin);

<template>
  <!-- @contextmenu="handler($event)" -->
  <div class="dialog-template  rounded-xl" style="user-select: none; overflow: hidden!important;" >
    <v-row v-show="noHeader != true" align="start" style="width: 100%; position: relative" class="ma-0 pt-4 pb-4 pl-5 pr-4">
      <div class="font-weight-bold" style="position: relative;  width: calc(100% - 45px); font-size: 18px">
        <v-row class="ma-0" align="start" style="width: 100%; position: relative; ">
          <v-icon v-if="icon != null && icon.startsWith('mdi')" size="24" class="mr-2 ml-1 " color="primary" style="margin-top: 6px" >{{ icon }}</v-icon>
          <span v-else class=" ml-1" style="font-size: 24px; margin-right: 7px;">{{icon}}</span>
          <span class="ml-1 mt-1 font-weight-bold header-title primary--text" style="font-size: 18px; letter-spacing: 1.05px; width: calc(100% - 50px); white-space: nowrap; text-overflow: ellipsis;
  overflow: hidden;">{{ title }}</span>

        </v-row>
      </div>
      <slot name="title-button" />

      <v-spacer />
      <slot name="header-action" />
      <div style="height: 36px; width: 36px;" >
      <IconButton class="mt-0" tooltip="Dialog schliessen" tooltipPosition="l" tooltipDelay="1000" buttonColor="grey" v-if="closeable == true || closeable == undefined" icon="mdi-close"
        v-on:click="$emit('close')" />
        </div>
    </v-row>

    <div ref="dialogContent" id="dialog-content"  class="pb-0 ml-1 mr-1 pt-0"
    style="  height: 5%; max-height: calc(90vh - 200px);  overflow-x: hidden!important; position: relative; overflow-y: auto;"
      :class="padding ? 'pl-5 pr-5' : 'pa-0'">
      <slot name="content" />
    </div>

    <div v-if="actions == true" style="position: relative; " class="pl-4  pr-5 pt-3 pb-1 mb-2">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import IconButton from "../button/IconButton.vue";
export default {
  components: {
    IconButton,
  },
  props: {
    actions: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    icon: {
      default: null
    },
    padding: {
      type: Boolean,
      default: true
    }
  },

  //   data() {
  //     return {
  //       isOverflown: false
  //     }
  //   },

  //   mounted() {

  //     const vm = this
  //     const resizeObserver = new ResizeObserver(function() {
  //      console.log("Size changed");
  //        const element = document.getElementById('dialog-content')
  //       console.log(element.getBoundingClientRect())

  //       // console.log("halkloo", this.$refs.dialogContent); 

  //       console.log(element.scrollHeight, element.clientHeight)
  //       vm.isOverflown = element.clientHeight > element.scrollHeight
  //       // console.log(this.isOverflown)
  //   });

  //  resizeObserver.observe(document.getElementById('dialog-content'));


  //   }
};
</script>


<style scss scoped>
::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: white;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: white;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #A0A0A0;
    border-radius: 10px;
    margin-right: 5px;
    border: 2px solid white
}


</style>
<style scoped>
.card-style {
  border-radius: 10px !important;
}

.headline-underline {
  position: absolute;

  bottom: 0px;
  background-color: var(--v-primary-base);
  width: 100%;
  height: 4px;
  border-radius: 2px;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 15px;
}


.dialog-template {
  width: 100%;
  position: relative;
  max-height: 85vh; 
  height: fit-content;
  background-color: white;
  /* border-radius: 20px!important; */
  overflow: hidden !important;
  /* overflow-y: auto; */
}


/* .dialog-template ::-webkit-scrollbar { width: 0 !important; display: none }   */
</style>

import { DATETIME } from "../models/fields/datetime.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";


const DATETIME_BREAK_HEIGHT = 29


const TIMEFIELD_WIDTH = 26
/**
 * datetime 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (DATETIME) datetime/range input object and config data
 */
export async function datetime(this: AlinoPDF, data: DATETIME):  Promise<void> {


  if (this.position.y + DATETIME_BREAK_HEIGHT > (this.layout.outerHeight - this.layout.innerBottom)) {
    
    // create new page;
    await this.create(null, true)
    this.position.y = this.layout.innerTop;
  }
  

  this.position.y += 1; 
  this.position.x = this.config.page.padding.left; 


  // field header
  if (data.header != null && data.header.length > 0) {
    this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
    this.docObj.text(data.header.toUpperCase(), this.position.x, this.position.y);

    this.position.y += 7;

  } else {
    this.position.y += 2.75
  }

  // format date value to either normal date string or date range string
  let dateValue = ''

  // format date string according to value and flag (isRange)
  if (data.value.date == null) {
    dateValue = '-'
  } else if (data.options.isRange == true && data.value.date != null && (data.value.date as any).start != null) {
    dateValue = formatDateRange((data.value.date as any).start, (data.value.date as any).end) // range (short month name)
  } else {
    dateValue = formatDate((+  new Date(data.value.date as Date))) // normal single date (long month name)
  }
  

  


  let fieldHeader = 'Datum'; // default field header
  
  // change header if daterange is set in options
  if (data.options.isRange == true) fieldHeader = 'Zeitraum'

  const initialY = this.position.y; // save initial y-position to restore row starting y-pos

  // date/daterange textfield
  await this.textfield({header: fieldHeader, value: dateValue} as TEXTFIELD, (this.layout.innerWidth/2))


  // time textfield if time is selected in options (hasTime)
  if (data.options.hasTime == true) {
    this.position.y = initialY; 
    this.position.x = this.layout.innerWidth + this.config.page.padding.left - SPACER.HORIZONTAL - 2 * TIMEFIELD_WIDTH;
    
    // time from textfield
    await this.textfield({header: 'Von', options: {suffix: 'Uhr'}, value: ((data.value.time as any).start || '-')} as TEXTFIELD, TIMEFIELD_WIDTH)

    this.position.y = initialY; // reset y-position for row
    this.position.x += TIMEFIELD_WIDTH + SPACER.HORIZONTAL; // add offset


    // time to textfield
    await this.textfield({header: 'Bis', options: {suffix: 'Uhr'}, value: ((data.value.time as any).end || '-')} as TEXTFIELD, TIMEFIELD_WIDTH)

  }

  // add margin to datetime/range
  this.position.y +=  SPACER.VERTICAL/2;

  this.position.x = this.config.page.padding.left;

}




export function formatDate(dateHandle: number, short = false): string {
  const event = new Date(dateHandle);

  const options = {
    year: "numeric",
    month: short ? 'short' : "long",
    day: "numeric",
  };

  

  // if (time == true) {
  //   return event.toLocaleTimeString(
  //     `de-de`,
  //     { ...options, hour: "2-digit", minute: "2-digit" }
  //   )
  // } else {
    return event.toLocaleDateString(
      `de-de`,
      ({ ...options, weekday: "short" } as any)
    );
  // }
}


function formatDateRange(start: number, end:number) {
  let startDate = formatDate(start, true);
  console.log("START", startDate)

  if (new Date(start).getFullYear() === new Date(end).getFullYear()) {
    startDate = startDate.replace(
      String(new Date(start).getFullYear()),
      ""
    );


  }
  


  const endDate = formatDate(end, true);

  return `${startDate} -  ${endDate}`;
}
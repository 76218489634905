

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { INFORMATION_FIELD } from '@/models/exports/pdf/fields/information.model';
import { TEMPLATE_DOC } from '@/models/api/template.model';
import FileList from '@/components/helper/FileList.vue';
import { CONFIGURATOR_MODES } from '@/components/editor/modes/configurator.modes';
import { replaceTextVariables } from '@/plugins/variables.helper';


@Component({
  components: {
    FieldHeader,
    FileList,
  },
})


export default class InformationField extends Vue {

  @Prop() value!: INFORMATION_FIELD

  CONFIGURATOR_MODES = CONFIGURATOR_MODES;
  previewImage: any = null

  openPreviewImage(): void {
    const doc = document.getElementById(`file-list-${this.selectedFile.id}`)
    if (doc != null) doc.click()
  }


  @Watch('value.options.info')
  onInfoChange(): void {
    this.onDescrChange()}

  @Watch('$store.getters.report', {immediate: true, deep: true})
  onValueChange(): void {
    this.onDescrChange()}
  @Watch('value.options.file', { immediate: true, deep: true })
  onDescrChange(): void {

    if (this.value.options.info == null || this.value.options.info.length == 0) {
      const doc = document.getElementById(`descr-${this.value.id}`)

      if (doc != null) (doc as any).replaceChildren()
    }

    

    

    const doc = document.getElementById(`descr-${this.value.id}`)

    if (doc == null) return;

    (doc as any).replaceChildren()
    doc.innerHTML = this.$store.getters.report == null || this.$store.getters.report.id == null ? this.value.options.info : replaceTextVariables(this.$store.getters.report, this.value.options.info)


        const pElements = document.querySelectorAll('p');

for (const pElement of pElements) {
  pElement.style.marginBottom = '5px';
}
  }

  get selectedFile(): any | null {

if (this.value == null || this.value.options == null || this.value.options.file == null || this.$store.getters.report.options.docs == null || this.$store.getters.report.options.docs.length == 0) return null
else return (this.$store.getters.report.options.docs || []).reduce((a: TEMPLATE_DOC, b: TEMPLATE_DOC) => a.id == this.value.options.file as any ? a : b);
}
  created(): void {
    this.$nextTick(() => {

      this.onDescrChange()
    })
  }

  @Watch('value.options.file')
  async onFileChanged(): Promise<void> {
    if (this.selectedFile != null && this.selectedFile.type == 'image/png') {
        this.previewImage = await this.$firebase.storage.get((this.selectedFile as TEMPLATE_DOC).path)
    }
  }

  async mounted(): Promise<void> {
   this.onFileChanged()
  }
}


import Map from '@components/maps/Map.vue';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue'
import { ADDRESS_FIELD } from '@/models/exports/pdf/fields/address.model';
import IconButton from '@components/button/IconButton.vue'
import axios from 'axios';

interface Address {
    streetName: string;
    zipCode: string;
    city: string;
}

@Component({
    components: {
        Map,
        TextInput,
        IconButton,
        FieldHeader
    },
})


export default class AddressField extends Vue {

    @Prop() value!: ADDRESS_FIELD;
    @Prop() showMissing!: boolean;
    @Prop() readonly!: boolean

    loading=false;

    positionLoader: any=null;
    position=null;
    getLocation(): void {
        try {
            if (navigator.geolocation) {
                this.loading=true;

                navigator.geolocation.getCurrentPosition(this.showPosition);

                this.positionLoader=setTimeout(() => {
                    this.$toast.error('Keinen Standort gefunden')

                    this.position=null;
                    this.loading=false;
                }, 7500);
            } else {
                this.$toast.error('Keinen Standort gefunden')

                this.position=null;
            }
        } catch (error) {
            this.$toast.error('Keinen Standort gefunden')

            this.position=null;
            this.loading=false;
        }

        //   this.loading = false;
    }

    async showPosition(position): Promise<void> {

        console.log(">>> GET POSITION")
        try {

            if (this.positionLoader!=null) clearTimeout(this.positionLoader)
            this.position={ lat: position.coords.latitude, lng: position.coords.longitude }
            console.log(this.position)


            const resp=await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.position.lat},${this.position.lng}&key=AIzaSyA2gURMJptCTSj2p5PiaD8g_FoTwHVXyrI`);

            if (resp.status==200&&resp.data!=null&&resp.data.results.length>0) {

                const zipCodeObj=resp.data.results[0].address_components.reduce((a: any, b: any) => a.types.includes("postal_code")? a:b)
                if (zipCodeObj!=null&&zipCodeObj.types.includes('postal_code')) this.value.value.zipCode=zipCodeObj.short_name;



                const cityObj=resp.data.results[0].address_components.reduce((a: any, b: any) => a.types.includes("locality")? a:b)
                if (cityObj!=null&&cityObj.types.includes('locality')) this.value.value.city=cityObj.short_name;

                const streetNameObj=resp.data.results[0].address_components.reduce((a: any, b: any) => a.types.includes("route")? a:b)
                const streetNumberObj=resp.data.results[0].address_components.reduce((a: any, b: any) => a.types.includes("street_number")? a:b)

                if (streetNameObj!=null&&streetNameObj.types.includes("route")) this.value.value.streetName=streetNameObj.long_name+' '+streetNumberObj.long_name;

                this.$toast.info('Geodaten erfolgreich geladen!')
            } else {
                this.$toast.error('Adresse wurde nicht gefunden')
            }

            this.$report.updateField({ ...this.value, value: { ...this.value.value, ...this.position } })
        } catch (err) {
            this.$toast.error('Keinen Standort gefunden')
            console.log(err)
        }

        setTimeout(() => {
            this.loading=false;
        }, 500);
        //   this.loading = false;

    }
    update(keyValue: string, eventHandle: string): void {
        if (keyValue==null || this.readonly == true) return;

        (this.value.value as any)[keyValue]=eventHandle;

        this.$report.updateField({ ...this.value, value: this.value.value })


    }


}

import { SCHEDULE, SCHEDULE_TIMEBLOCK } from "../models/fields/schedule.model";
import { AlinoPDF, SPACER } from "../pdf.class";

/**
 * address
 *
 * @param this (AlinoPDF) class scope
 * @param data (ADDRESS) address input object and config data
 */

function parseFloatTime(time: number): string {
    if (time == 1440) time = 0;
    return `${`0${time / 60}`.slice(-2)}:${`0${(60 * (time / 60 - (time / 60)))}`.slice(-2)}`;
  }

export async function schedule(this: AlinoPDF, data: SCHEDULE): Promise<void> {
  const weekdayNameArr = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];
  // this.docObj.text('LOOLOLOLOL', this.position.x, this.position.y)
  // const OFFSET = 50

  this.position.y += 1;
  if (data.header != null && data.header.length > 0) {
    this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
    this.docObj.text(
      data.header.toUpperCase(),
      this.position.x,
      this.position.y
    );

    this.position.y += 6;
  } else {
    this.position.y += 2.75;
  }

  const relativeRatio = 100 / 1440;
  const initialY = this.position.y;

  const rowWidth = this.layout.innerWidth / 7;
  for (let index = 1; index <= 7; index++) {
    this.position.y = initialY;
    const weekdayArr = data.timeblocks.filter(
      (block: SCHEDULE_TIMEBLOCK) => block.dayOfWeek == index
    );
    // console.log(index, weekdayArr, data.timeblocks);

    this.position.y += 1;
    this._textStyle(this.config.font.regular, this.config.color.secondary, 9);

    this.docObj.text(
      weekdayNameArr[index - 1].toUpperCase(),
      this.position.x,
      this.position.y
    );

    this.position.y += 2.5;
    this.docObj.setDrawColor(this.config.color.grey);
    this.docObj.setLineWidth(0.2);

    const rowWidthCol = rowWidth - (index != 7 ? 6 / 6 : 0);
    this.docObj.roundedRect(
      this.position.x,
      initialY + 2.5,
      rowWidthCol,
      100,
      0.85,
      0.85
    );

    for (
      let timeblockIndex = 0;
      timeblockIndex < weekdayArr.length;
      timeblockIndex++
    ) {
      const startPosition =
        initialY +
        2.5 +
        weekdayArr[timeblockIndex].startTime * relativeRatio +
        0.1;
      const endPosition =
        (weekdayArr[timeblockIndex].endTime -
          weekdayArr[timeblockIndex].startTime) *
          relativeRatio -
        0.2;

      if (weekdayArr[timeblockIndex].type == 2) {
        this.docObj.setFillColor(this.config.color.secondary);
      } else {
        this.docObj.setFillColor(this.config.color.primary);
      }

      console.log(
        endPosition,
        this.layout.innerWidth,
        relativeRatio,
        "3",
        weekdayArr[timeblockIndex].endTime * relativeRatio
      );
      this.docObj.roundedRect(
        this.position.x + 0.1,
        startPosition,
        rowWidthCol - 0.2,
        endPosition,
        0.75,
        0.75,
        "F"
      );
      this._textStyle(this.config.font.regular, this.config.color.grey, 6);

      const timeText =  `${parseFloatTime(weekdayArr[timeblockIndex].startTime)} - ${parseFloatTime(weekdayArr[timeblockIndex].endTime)}`;
      this.docObj.text(
        timeText,
        this.position.x +
          rowWidth / 2 -
          this.docObj.getTextWidth(timeText) / 2 -
          0.5,
        startPosition +
          endPosition / 2 +
          this.docObj.getTextDimensions(timeText).h / 2 -
          0.4
      );
    }

    this.position.x += rowWidth;
  }

  this.position.y += 102;
  this.position.x = this.config.page.padding.left;
  this.docObj.setFillColor(this.config.color.primary);
  this._textStyle(this.config.font.bold, "black", 8);

  const legendStr = "LEGENDE:";
  this.docObj.text("LEGENDE:", this.position.x, this.position.y + 3);

  this.position.x += this.docObj.getTextWidth(legendStr) + 2;
  this._textStyle(this.config.font.regular, this.config.color.grey, 7);

  this.docObj.setFillColor(this.config.color.primary);

  let textStr = "ALARMZEITEN";
  this.docObj.roundedRect(
    this.position.x,
    this.position.y,
    this.docObj.getTextWidth(textStr) + 14,
    4,
    0.85,
    0.85,
    "F"
  );

  this.docObj.text(textStr, this.position.x + 7, this.position.y + 2.9);
  // this.position.y += 10

  this.docObj.setFillColor(this.config.color.secondary);

  this.position.x +=
    this.docObj.getTextWidth(textStr) + 14 + SPACER.HORIZONTAL / 2;
  textStr = "DOKUMENTATION";

  this.docObj.roundedRect(
    this.position.x,
    this.position.y,
    this.docObj.getTextWidth(textStr) + 14,
    4,
    0.85,
    0.85,
    "F"
  );

  this.docObj.text(textStr, this.position.x + 7, this.position.y + 2.9);
  this.position.y += 12;

  // // address field is always showed as one field; if remaining space less than max field height
  // // page is broken and field is displayed on new page
  // if (this.position.y + ADDRESS_BREAK_HEIGHT > (this.layout.outerHeight - this.layout.innerBottom)) {

  //   // create new page;
  //   await this.create(null, true)
  //   this.position.y = this.layout.innerTop;
  // }

  // this.position.y += 1;
  // this.position.x = this.config.page.padding.left;

  // // field header
  // if (data.header != null && data.header.length > 0) {
  //   this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
  //   this.docObj.text(data.header.toUpperCase(), this.position.x, this.position.y);

  //   this.position.y += 7;
  // } else {
  //   this.position.y += 2.75
  // }

  // // street textfield
  // await this.textfield(({header: 'Straße & Hausnnummer', value: data.value.streetName} as TEXTFIELD))
  // this.position.y -= 2.5; // reduce height between fields in combined field

  // const zipCodeYPosition = this.position.y; // save y-position as a row is creeated

  // // zip-code textfield
  // await this.textfield(({header: 'PLZ', value: data.value.zipCode} as TEXTFIELD), 40)

  // this.position.y = zipCodeYPosition; // reset to previous row y-position
  // this.position.x = this.config.page.padding.left + 40 + SPACER.HORIZONTAL; // offset to zip-code field

  // // city textfield
  // await this.textfield(({header: 'Stadt', value: data.value.city} as TEXTFIELD), this.layout.innerWidth - 40 - SPACER.HORIZONTAL)

  // this.position.y -= 2;

  // // add margin to file position
  // this.position.y +=  SPACER.VERTICAL;
  // this.position.x = this.config.page.padding.left;
}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import vueSignature from "vue-signature"
import IconButton from '@components/button/IconButton.vue';
import TextInput from "@components/text/TextInput.vue";

import localforage from 'localforage'
import FieldHeader from '@/components/helper/FieldHeader.vue'
import { SIGNATURE_FIELD } from '@/models/exports/pdf/fields/signature.model';

@Component({
    components: {
        FieldHeader,
        vueSignature,
        IconButton,
        TextInput
    },
})


export default class SignatureField extends Vue {

    @Prop() disclaimer!: Array<string>;
    @Prop() value!: SIGNATURE_FIELD;
    @Prop() showMissing!: boolean; 
    @Prop() readonly!: boolean;

    mouseDown = false;

    signatureImage: string | null = null;
    
    customerName = '';
    customerMail = '';
    loadedSignature = false

    option = {
        penColor: "rgb(14, 2, 115)",
        backgroundColor: 'transparent'
    }


    get savedSignature(): boolean {
        return this.value.value.saved == true
    }

    async mounted(): Promise<void> {

        // tODO DELETE?
        if (this.readonly) return;

        if (this.value.options.customer == false) {
            const userObj = this.$store.getters.members.user[this.$firebase.auth.uid];
            this.customerName = userObj.displayName || '';
            this.customerMail = userObj.mail || '';
        } else {
            this.customerMail = this.value.value.mail || ''; 
            this.customerName = this.value.value.name || ''; 
        }
        // if image is not buffered (option), loading is skipped and data removed
    
            var storageResult: string | null = await localStorage.getItem(this.value.id);
            // no valid signature data
            if (storageResult == null || storageResult.length == 0 || !storageResult.startsWith('data:image/png;base64')) return;

            this.signatureImage = storageResult;
            this.loadedSignature = true;
        

        //         if ((this.$refs.signature as any) == null || (this.$refs.signature as any).isEmpty() == true || this.savedSignature == true) return;


      

    }


    loadSaveTimeout: any = null

    signatureMouseUpEvent(): void {

        if (this.readonly) return;
        console.log("MOUSE UP", this.mouseDown)
        if (this.mouseDown == true && (this.$refs[`signature-${this.value.id}`] as any) != null && (this.$refs[`signature-${this.value.id}`] as any).isEmpty() == false) {
            clearTimeout(this.loadSaveTimeout)

            this.loadedSignature = false;
            this.signatureImage = (this.$refs[`signature-${this.value.id}`] as any).save('image/png') || '';

        
            localStorage.setItem(this.value.id, this.signatureImage as string)
            this.update(true)
            

            this.loadSaveTimeout = setTimeout(() => {
                this.loadedSignature = true
                console.log("H")
                this.signatureImage = (this.$refs[`signature-${this.value.id}`] as any).save('image/png') || '';

        
            localStorage.setItem(this.value.id, this.signatureImage as string)
            this.update(true)
            }, 750)
        }
       


        this.mouseDown = false;
    }

    signatureMouseDownEvent(): void {

        this.mouseDown = true
        clearTimeout(this.loadSaveTimeout)
    }

    clearSignature(): void {
        // if ((this.$refs.signature as any) == null) return;
        // (this.$refs.signature as any).clear();
        localStorage.removeItem(this.value.id)

        this.update(false);
    }

    update(saved: boolean): void {
        console.log("update")
    this.$report.updateField({...this.value, value: {id: this.value.id, saved: saved, name: this.value.value.name, mail: this.value.value.mail}})
    if (saved == false) this.loadedSignature = false;

  }


}



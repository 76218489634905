import { BOOLEAN } from "../models/fields/boolean.model";
import { AlinoPDF, SPACER } from "../pdf.class";




/**
 * boolean 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function boolean(this: AlinoPDF, value: BOOLEAN, prev: boolean):  Promise<void> {


  // display boolean in row if prev flag is true;
  // TODO: handle if header is greater than innerWidth/2 ?
  if (prev == true) {
    this.position.x = this.config.page.padding.left + this.layout.innerWidth/2;
    this.position.y -= 20;
  }
  
    // header text style
    this._textStyle(this.config.font.regular, this.config.color.secondary, 10);

    // add header text
    this.docObj.text(((value.header || '').toUpperCase()), this.position.x, this.position.y);
    this.position.y += 2;





  const initialY = this.position.y // initial y-position to revert to ini
  this.box(value.value == true) // YES-checkbox


  this.position.y = initialY; // reset y-position 
  this.position.x += 10;


  // display text value in bold if true (yes)
  this._textStyle(this.config.font.regular, 'black', 11);

  if (value.value == true) {
    this._textStyle(this.config.font.bold, 'black', 11);
  }

  // YES value
  this.docObj.text('JA', this.position.x, this.position.y + 5.3)
  this.position.x += this.docObj.getTextWidth('JA') + 2 * SPACER.HORIZONTAL; // calc spacing

  this.box(value.value == false) // NO-checkbox
  this.position.y = initialY; 
  this.position.x += 10;


  // display text value in bold if false (no)
  this._textStyle(this.config.font.regular, 'black', 11);

  if (value.value == false) {
    this._textStyle(this.config.font.bold, 'black', 11);
  }
  this.docObj.text('NEIN', this.position.x, this.position.y + 5.3)

  // boolean alwys has a fixed height of 20 (!)
  this.position.y += 18;
  this.position.x = this.config.page.padding.left;

}

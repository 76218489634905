import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { currentUser } from "../plugins/firebase";

import store from "@/store";
import { loadUserOrganisationData } from "./auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "*",
        redirect: { name: "organisations" },
    },

    {
        path: "/",
        beforeEnter: async (to, from, next) => {
            if (to.query.link != null)
                return next(await Vue.prototype.$firebase.auth.isAuthLink());
            else {
                next(false);
                return router.replace({ name: "organisations" });
            }
        },
    },

    {
        path: "/organisations",
        name: "organisations",
        component: () =>
            import("@/views/organisation/OrganisationListView.vue"),

        meta: {
            title: "Organisation auswählen",
            requiresAuth: true,
        },
    },

    {
        path: "/:organisationId",
        name: "organisation",

        redirect: { name: "organisation-inbox" },
        component: {
            render(c: any) {
                return c("router-view");
            },
        },

        beforeEnter: loadUserOrganisationData,
        meta: {
            title: "Übersicht",
            requiresAuth: true,
        },

        children: [
            {
                name: "organisation-member-settings",
                path: "auth/user",
                component: () =>
                    import("@/views/organisation/user/UserAccountView.vue"),

                meta: {
                    title: "Profileinstellungen",
                    requiresAuth: true,
                },
            },

            {
                path: "inbox",
                name: "organisation-inbox",
                component: () =>
                    import(
                        "@/views/organisation/OrganisationDashboardView.vue"
                    ),

                meta: {
                    title: "Inbox",
                    requiresAuth: true,
                },
            },

            {
                path: "settings",
                name: "organisation-settings",
                component: () =>
                    import("@/views/organisation/OrganisationSettingsView.vue"),

                meta: {
                    title: "Einstellungen",
                    requiresAuth: true,
                },
            },

            {
                path: "calendar",
                name: "calendar",
                component: () =>
                    import("@/views/organisation/calendar/CalendarView.vue"),

                meta: {
                    requiresAuth: true,
                },
            },

            {
                path: "report",
                name: "report-group",
                component: {
                    render(c: any) {
                        return c("router-view");
                    },
                },

                redirect: { name: "label-list" },
                meta: {
                    requiresAuth: true,
                },

                children: [
                    {
                        path: "reports",
                        name: "reports-overview",
                        component: () =>
                            import(
                                "@/views/organisation/reports/ReportsListView.vue"
                            ),

                        meta: {
                            title: "Berichte",
                            requiresAuth: true,
                        },
                    },
                ],
            },

            {
                path: "label",
                name: "label-group",
                component: {
                    render(c: any) {
                        return c("router-view");
                    },
                },

                redirect: { name: "label-list" },
                meta: {
                    requiresAuth: true,
                },

                children: [
                    {
                        path: "list",
                        name: "label-list",
                        component: () =>
                            import(
                                "@/views/organisation/labels/LabelsListView.vue"
                            ),

                        meta: {
                            title: "Labels",
                            requiresAuth: true,
                        },
                    },
                    {
                        path: ":labelId",
                        name: "label",
                        component: () =>
                            import("@/views/organisation/labels/LabelView.vue"),

                        meta: {
                            requiresAuth: true,
                        },
                    },

                    {
                        path: ":labelId/ref/:referenceId",
                        name: "label-reference",
                        component: () =>
                            import(
                                "@/views/organisation/labels/LabelChangelogView.vue"
                            ),

                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: "template",
                name: "template-group",
                component: {
                    render(c: any) {
                        return c("router-view");
                    },
                },

                meta: {
                    requiresAuth: true,
                },

                redirect: { name: "template-list" },

                children: [
                    {
                        path: "list",
                        name: "template-list",
                        component: () =>
                            import(
                                "@/views/organisation/editor/TemplatesListView.vue"
                            ),

                        meta: {
                            requiresAuth: true,
                        },
                    },

                    {
                        path: ":templateId",
                        name: "template",
                        component: () =>
                            import(
                                "@/views/organisation/editor/TemplateEditorView.vue"
                            ),

                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
        ],
    },

    {
        path: "/report/:organisationId/:reportId",
        name: "report",
        component: () => import("@/views/ReportEditor.vue"),

        beforeEnter: async (to, from, next) => {
            // store.commit("setLoadingPage", true);
            if (Vue.prototype.$firebase.auth.isPublicEditor) return next();

            loadUserOrganisationData(to, from, next, false);
        },

        meta: {
            requiresAuth: true,
        },
    },
    
    {
        name: "invalid-link",
        path: "/auth/not-found",
        component: () => import("../views/auth/InvalidLinkAuthView.vue"),

        meta: {
            requiresNoAuth: true,
        },
    },

    {
        name: "password-settings",
        path: "/auth/password",

        component: () => import("../views/auth/PasswordAuthView.vue"),

        meta: {
            requiresAuth: true,
        },
    },

    {
        path: "/auth/report",
        name: "report-auth",
        component: () => import("@/views/auth/ReportAuthView.vue"),

        meta: {
            requiresNoAuth: true,
        },
    },

    {
        path: "/auth/organisation",
        name: "login",

        component: () => import("../views/auth/OrganisationAuthView.vue"),

        meta: {
            requiresNoAuth: true,
            title: "Anmeldung",
            favicon:
                "https://firebasestorage.googleapis.com/v0/b/alinoreport.appspot.com/o/assets%2Ffavicons%2Ffavion_auth.webp?alt=media&token=647aa1f2-70c9-465a-b553-f55da208f157",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.meta != null && (to.meta.favicon != null || to.meta.title != null)) {
        // change favicon
        if (to.meta.favicon != null) {
            const favicon = document.getElementById("favicon");
            if (favicon != null)
                (favicon as HTMLLinkElement).href = to.meta.favicon || "";
        }

        if (to.meta.title != null)
            document.title = `${to.meta.title} - ${
                store.getters.organisation != null &&
                store.getters.organisation.general != null
                    ? store.getters.organisation.general.name || "alinoreport"
                    : "alinoreport"
            }`;
        else document.title == "alinoreport";
    }

    if (to.name == "report-auth" || to.name == "unkown-organisation")
        return next();

    // check explicity stated auth rules
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresNoAuth = to.matched.some(
        (record) => record.meta.requiresNoAuth
    );

    if (!requiresAuth && !requiresNoAuth) return next(); // no auth rules stated

    //   console.log(to)
    // fetch user data
    const user = await currentUser().catch(() => null);

    // if ()
    const isPublic = false; //Vue.prototype.$firebase.auth.isPublicEditor;

    if (to.name == "user" && user != null) return next();

    // if user is navigating to login even though they are signed in, they are forwarded
    if (to.name == "login" && user != null) {
        next(false);
        return router.replace({ name: "organisations" });
    }

    // if user is requesting a report but is not signed in, they are forwarded to the report
    // authentication page
    if (to.name == "report" && user == null) {
        next(false);
        return router.replace({
            name: "report-auth",
            query: {
                report: encodeURI(
                    to.fullPath.replace("?invalidActionCode=true", "")
                ),
                invalidActionCode: String(
                    to.fullPath.includes("?invalidActionCode=true")
                ),
            },
        });
    }

    if (requiresAuth && (!user || isPublic)) {
        next(false);
        return router.replace({ name: "login" });
    }

    next();
});

export default router;

import { INVENTORY, INVENTORY_ELEMENT } from "../models/fields/inventory.model";
import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF } from "../pdf.class";


 
/**
 * inventory 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (INVENTORY) inventory input object and config data
 */
export async function inventory(this: AlinoPDF, data: INVENTORY):  Promise<void> {

    console.log("MM", this.inventoryObj, )



  // new page if header + first row do not fit to page
  if (this.position.y + 30 > (this.layout.outerHeight - this.layout.innerBottom) ) {
    await this.create(null, true)
    this.position.y = this.layout.innerTop;
  }

  console.log("INVENTORY PDF: ", data);
  // header field
  if (data.header != null && data.header.length > 0) {
    this._textStyle(this.config.font.bold, this.config.color.secondary, 10);
    this.docObj.text(data.header.toUpperCase(), this.position.x, this.position.y);



    this.position.y += 7;
  } else {
    this.position.y += 2.75
  }


  let inventoryData = Object.keys(data.value).map((val: string) => ({id: val, value: data.value[val as any], entry: this.inventoryObj.elements.length == 0 ? {} : this.inventoryObj.elements.reduce((a: any, b: any) => a[this.inventoryObj.identifier.id] == val ? a : b)}));

  inventoryData = inventoryData.sort((a: any, b: any) => parseInt(a.id.replace(/\D/g,'')) - parseInt(b.id.replace(/\D/g,'')))
  let setHeaderText = true; // flag wether textfield header shall be displayed (index == 0 && new page)
  for (let index = 0; index < inventoryData.length; index++) {

    // filter refered inventory element 
    const inventoryElement = inventoryData[index]
    
      
    if (inventoryElement != null) {

        this.position.x = this.config.page.padding.left;  // reset x-position


        const nameText = (inventoryElement.entry[this.inventoryObj.identifier.name] || '') //+  ' lkdlks lkmds lfkmllkmsdflskdmflmflklskdmflksdflklkmsfdlkmfdlkfmlskfdmlksfdmslk msfd smdkl msfldmflflkmflksdlkfdslksdmflksdmflksdfmlksdfmlkfsdmlksdfmlksdfmlksdmflksdfmlksdfmlkmfsdlkmflksdmflksdfmlksdfmlksdmlksdfm' 

        const rows = this.docObj.splitTextToSize(nameText, 110);

        // console.log("ROWS: ", rows)

  // calculate textfield height based on suffix and text breaks (if needed)
        this._textStyle(this.config.font.regular, 'black', 11); // set used textstyle for correct calc

        const textArrHeight = this.docObj.getTextDimensions(rows).h; // text height (of all rows!)
        const textfieldHeight = textArrHeight + 4;
        const resultingHeight = this.position.y + textfieldHeight + 5

        if (resultingHeight  > (this.layout.outerHeight - this.layout.innerBottom)) {
            
            console.log("PAGE BREAK", inventoryElement)
            // create new page;
            await this.create(null, true)
            this.position.y = this.layout.innerTop;
            
            this.position.x = this.config.page.padding.left; 
            setHeaderText = true
        }


        const initialYPos = this.position.y; // save row y-position


        // identifier textfield
        await this.textfield({header: (setHeaderText == true ? 'Artikel' : ''), value: inventoryElement.id} as TEXTFIELD, 30, false, true);


        this.position.y = initialYPos; // reset y-position
        this.position.x = this.config.page.padding.left + 30 + 1;

        // description/name textfield
        await this.textfield({header: (setHeaderText == true ? 'Artikelbeschreibung' : ''), value: nameText} as TEXTFIELD, 110, false);


        const resultingY = this.position.y

        // console.log(res)
        this.position.y = initialYPos; // reset y-position
        this.position.x = this.config.page.width - this.config.page.padding.right - 20;

        // value textfield
        await this.textfield({options: {suffix: inventoryElement.entry[this.inventoryObj.identifier.type]}, header: (setHeaderText == true ? 'Anzahl' : ''), value: String((inventoryElement.value || 0))} as TEXTFIELD, (this.layout.innerWidth - this.position.x + this.config.page.padding.left), false, true);

        this.position.y = resultingY;
        this.position.y -= 9.2 // remove margin from textfield in table

        this._divider(this.config.color.grey)
        this.position.y -= 3.2 // remove margin from textfield in table

        setHeaderText = false;

        // break page if next row dows not fit + show textfield headers again
        if (this.position.y + 11 > (this.layout.outerHeight - this.layout.innerBottom) ) {

          await this.create(null, true) // create new page
          this.position.y = this.layout.innerTop;
          setHeaderText = true // show textfield header on new page
        }
        
    }

  }

  

  
  this.position.x = this.config.page.padding.left; 
  this.position.y += 10

}

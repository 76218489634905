

import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import AuthCardTemplate from '@/components/auth/AuthCardTemplate.vue';

// import TextButton from "@components/button/TextButton.vue";
// import TextInput from "@components/text/TextInput.vue";
// import Information from "@/components/helper/Information.vue";


@Component({
    components: {
        AuthCardTemplate,

    }
})
export default class UserProfilePicture extends Vue {

    @Prop() highlight!: boolean;
    @Prop() seed!: string


}


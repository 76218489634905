import _Vue from "vue";
import axios from 'axios'

import { initializeApp } from "firebase/app";
import {
  getAuth,
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  User,
  signOut,
  updateProfile,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import {
  getStorage,
  ref,

  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  uploadString,
} from "firebase/storage";
import { getDatabase } from "firebase/database";
import {
  getFirestore,
  enableIndexedDbPersistence,
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
} from "firebase/firestore";
import router from "@/router";
import store from "@/store";

declare module "vue/types/vue" {
  export interface Vue {
    $firebase: FirebasePlugin;
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyCPB4JrTro3hGGVLclx9xLQZsaNnlmBVpg",
  authDomain: "alinoreport.firebaseapp.com",
  projectId: "alinoreport",
  storageBucket: "alinoreport.appspot.com",
  messagingSenderId: "686562612654",
  appId: "1:686562612654:web:b65e1af63d7e487af8243f",
  databaseURL: "https://alinoreport-default-rtdb.europe-west1.firebasedatabase.app",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const database = getDatabase(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
(async () => {
  await setPersistence(firebaseAuth, browserLocalPersistence);
})();

initializeFirestore(firebaseApp, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  ignoreUndefinedProperties: true,

});


export const firebaseFirestore = getFirestore(firebaseApp);

  

export const currentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      (userFirebase) => {
        unsubscribe();
        resolve(userFirebase);
      },
      reject
    );
  });
};
class FirebasePlugin {
  static install(Vue: typeof _Vue) {
    Vue.prototype.$firebase = new FirebasePlugin();
    
  }

 

  async link(link: string, title: string, descr: string) {
    try {
       
        const resp = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`, {
            dynamicLinkInfo: {
              domainUriPrefix: 'https://report.alinocam.com/link',
              link: link,
              socialMetaTagInfo: {
                socialTitle: title || '',
                socialDescription: descr || '',
              },
            },
            suffix: {
                option: 'UNGUESSABLE'
            }
          })
    //   const shortLink = await firebaseDynamicLinks.createLink();

    console.log(resp)
      return resp.status == 200 && resp.data.previewLink != null ? resp.data.shortLink : link;
    } catch {
      return link
    }
  }

  get auth() {
    return new FirebaseAuthPlugin();
  }

  get storage() {
    return new FirebaseStoragePlugin();
  }

  get firestore() {
    return firebaseFirestore;
  }
}

class FirebaseStoragePlugin {
  async get(path: string): Promise<string> {
    // console.log("get", path);
    const storage = getStorage();
    return await getDownloadURL(ref(storage, path));
  }

  async delete(path: string): Promise<any> {
    const storage = getStorage();

    // Create a reference to the file to delete
    const fileRef = ref(storage, path);

    // Delete tdehe file
    return await deleteObject(fileRef);
  }
  async upload(
    organisationId: string,
    filename: string,
    file: Blob,
    callback: CallableFunction
  ): Promise<any> {
    console.log('upload', filename, file)
    const storage = getStorage();
    const storageRef = ref(storage, `${organisationId}/${filename}`);

    console.log("s", storageRef)
    const uploadTask = uploadBytesResumable(storageRef, file);
    // console.log("R", resp)

    uploadTask.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    callback(progress)
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
  }, 
  (error) => {
    // Handle unsuccessful uploads
  }, 
  () => {
    callback(100, uploadTask.snapshot)
  }
);
    return ; // resp ????
  }

  async uploadBase64(
    organisationId: string,
    filename: string,
    fileStr: string
  ): Promise<any> {
    const storage = getStorage();
    const storageRef = ref(storage, `${organisationId}/${filename}`);

    // console.log(`${organisationId}/${filename}`);
    const resp = await uploadString(storageRef, fileStr, "data_url");

    return resp;
  }
}

class FirebaseAuthPlugin {
  async forgotMail(email: string): Promise<void> {
    await sendPasswordResetEmail(firebaseAuth, email);
  }

  async isPasswordLogin(mail: string): Promise<boolean> {
    const resp = await fetchSignInMethodsForEmail(firebaseAuth, mail);

    if (resp.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1)
      return true;
    else return false;
  }
  get isPublicEditor(): boolean {
    let isPublic = true;

    try {
      isPublic = (_Vue.prototype.$firebase.auth.user as any).reloadUserInfo
        .emailLinkSignin;
      if (isPublic != true) isPublic = false;
    } catch (error) {
      isPublic = false;
    }

    return false//isPublic;
  }
  async updateName(name: string): Promise<void> {

    try {
        const userOrganisations = await _Vue.prototype.$organisations.get();
        console.log("U", userOrganisations)
    } catch (error) {
        console.log(error)
    }
}

  async login(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(firebaseAuth, email, password);
  }

  async resetPassword(mail: string): Promise<void> {
    return await axios.get(
        `https://europe-west3-alinoreport.cloudfunctions.net/api/auth/reset?mail=${
            mail
        }`
    );


  }

  async updateProfile(): Promise<void> {
    if (firebaseAuth == null) return;
    await updateProfile(firebaseAuth.currentUser as User, {
      displayName: "Steffen Linßen",
    });
  }

  async logout() {
    await signOut(firebaseAuth);
  }

  async changePassword(newPassword: string): Promise<void> {
    console.log(newPassword);
    // const credentials = EmailAuthProvider.credential('puf@firebaseui.com', 'firebase');

    await updatePassword(this.user as User, newPassword)
  }


  inviteUser(email: string): void {
    createUserWithEmailAndPassword(firebaseAuth, email, 'password')
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log(user)
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorMessage)
    // ..
  });
  }

  
  async isAuthLink(): Promise<any> {
    // console.log("HEJEJE")
    const urlHandle = decodeURIComponent(
      String(window.location.href).split("continueUrl")[1]
    )
      .split("=")[1]
      .split("&")[0];
    // console.log(urlHandle)

    try {
      let email = window.location.href.split('&mail=')[1]
      console.log(window.location.href.split('&mail=')[1])

      if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        if (email == null || email.length == 0) 
          email = window.prompt(
            "Bitte geben Sie Ihre Mail-Adresse an"
          ) as string;
        await signInWithEmailLink(firebaseAuth, email, window.location.href);

        return router.resolve(urlHandle.replace('http://localhost:8080', '')).resolved || ({name: 'invalid-link'})
      }
    } catch (error) {
        
      if ((error as any).code == "auth/invalid-action-code") {
        if (urlHandle.includes('report')) return ({name: 'report-auth', query: {invalidActionCode: 'true', report: urlHandle}})
        else return ({name: 'invalid-link'})
      }
    }

    // console.log("STILL", urlHandle.replace("http://localhost:8080", ""));

   
    // return ({path: urlHandle.replace("http://localhost:8080", "")});
  }



  async authMailLink(mail: string, url: string): Promise<void> {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: String(new URL(url, window.location.origin).href),
      // This must be true.
      handleCodeInApp: true,

      iOS: {
        bundleId: "com.example.ios",
      },
      android: {
        packageName: "com.example.android",
        installApp: true,
        minimumVersion: "12",
      },
      // dynamicLinkDomain: 'example.page.link'
    };

    return await sendSignInLinkToEmail(firebaseAuth, mail, actionCodeSettings);
  }

  get isOrganisationEditor(): boolean {
    try {
      const organisationMembers = store.getters.members;
      // console.log("R: ", organisationMembers[this.uid as string].role);
      if (organisationMembers == null || organisationMembers.lengh == 0)
        return false;
      else return organisationMembers[this.uid as string].role == 1;
    } catch (error) {
      // console.log(error);
      return false;
    }
  }
  get uid(): string | undefined {
    return firebaseAuth.currentUser?.uid;
  }
  get user(): User | null {
    return firebaseAuth.currentUser;
  }

  get mail(): string {
    if (
      firebaseAuth.currentUser == null ||
      firebaseAuth.currentUser.email == null
    )
      return "";
    else return firebaseAuth.currentUser.email;
  }
}

_Vue.use(FirebasePlugin);

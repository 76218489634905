<template>
    <div>
 
        <v-text-field tabindex="0" :maxlength="maxlength" :id="id" :class="monospace==true? 'mono':'no-mono'"
            :style="disabled? 'opacity: .5; pointer-events: none;':(highlight==true&& (inputValue == null || inputValue.length==0)? 'border: 2px solid var(--v-primary-base)':'padding: 2px')"
            autocomplete="false" class="red--text elevation-0"
            :type="password? (showPassword? 'text':'password'):(number? 'number':'text')" :pattern="number ? '\\d*' :(alphanumeric? '[a-zA-Z0-9\\s]+':'')" v-model="inputValue"
            :placeholder="placeholder" height="10" dense flat 
            :hide-details="true" filled :solo-inverted="inverted" rounded :readonly="readonly"
            :counter="maxlength!=null&&maxlength>0? true:false"
            :min="numberBounds!=null? numberBounds.min:null" :max="numberBounds!=null? numberBounds.max:null"
            :prepend-inner-icon="icon" :dark="dark" v-on:focus="$emit('focus', $event)"
            v-on:blur="$emit('blur', inputValue)" v-on:keyup.enter="$emit('enter', $event)" v-on:keydown="$emit('keydown', $event)">


            <template v-slot:prepend-inner="">
                <div v-if="icon!=null&&icon.startsWith('mdi')">
                    <v-icon class="mr-2" style="margin-top: 1px" color="black" size="21">{{ icon }}</v-icon>
                </div>
                <div v-if="prefix!=null&&prefix.length>0" :class="disabled? 'grey--text':''"
                    style="font-size: 14px; padding-top: 1px; letter-spacing: 1.05px"
                    class="black--text mt-1 mr-3 font-weight-bold">{{ prefix.toUpperCase() }}<span v-if="(prefix.length > 1)">:</span></div>
            </template>

            <template v-slot:append>
                <slot name="append" />
                <div v-if="!disabled">
                    <div class="mr-0 ml-1" v-if="suffix!=null&&suffix.length>0"
                        style="color: rgba(0, 0, 0, .7); margin-top: 5px; font-size: 14px"><b>{{ suffix }}</b></div>
                    <div style="cursor: pointer!important" v-on:click="showPassword=!showPassword" v-if="password">
                        <v-icon v-if="showPassword">mdi-eye</v-icon>
                        <v-icon v-else>mdi-eye-off</v-icon>
                    </div>
                    <div v-else-if="validate&&inputValue&&inputValue.length>0&&!disabled">
                        <!-- <v-icon color="success">mdi-checkbox-marked-circle</v-icon> -->
                        <div v-if="!isValid">

                            <v-icon size="18" v-if="!inverseValidation" style="cursor: help!important" color="primary">
                                mdi-close-circle
                            </v-icon>

                        </div>

                        <div v-else-if="isValid">
                            <div v-if="inverseValidation">
                                <v-tooltip :disabled="($vuetify.breakpoint.xsOnly||$vuetify.breakpoint.smOnly)" right
                                    open-delay="250">
                                    <template v-slot:activator="{ on, attrs }">

                                        <v-icon v-if="inverseValidation" v-bind="attrs" v-on="on"
                                            style="margin-top: 1px; cursor: default!important" color="primary"
                                            size="20">mdi-close-circle</v-icon>

                                    </template>
                                    <span style="font-size: 12px" class="sub-text white--text">Ungültiges Format</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <div v-else />
                    </div>

                    <div v-if="clearable&&inputValue!=null&&inputValue.length>0">
                        <v-icon style="margin-top: 2px" v-on:click="clearInput()" size="18">mdi-close</v-icon>
                    </div>
                </div>
            </template>
        </v-text-field>
        <v-row v-if="maxlength!=null&&maxlength>0" style="font-size: 14px" align="center" justify="end"
            class="ma-0 grey--text mr-4">
            {{ inputValue.length }}/{{ maxlength }}
        </v-row>
    </div>

</template>


<script>

export default {
    props: {
        numberBounds: {
            default: null
        },
        highlight: {
            type: Boolean,
            default: false
        },
        id: {
            type: String
        },
        suffix: {
            type: String,
            default: ''
        },
        prefix: {
            type: String,
            default: ''
        },
        alphanumeric: {
            type: Boolean,
            default: false
        },
        inverted: {
            type: Boolean,
            default: false
        },

        monospace: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        password: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false
        },

        icon: {
            type: String,
            default: undefined
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            required: true
        },
        dark: {
            type: Boolean,
            default: false
        },
        maxlength: {
            default: null
        },

        value: {
            required: true
        },

        number: {
            type: Boolean,
            default: false
        },

        validate: {
            type: Boolean,
            default: false
        },
        inverseValidation: {
            type: Boolean,
            default: false
        },

        data: {
            type: Array
        }
    },

    watch: {
        validate: {
            handler() {
                if (this.validate) return this.$emit('valid', this.isValid)
            }, immediate: true
        },
        value: function () {
            this.inputValue = this.value
        },
        inputValue: function () {
            if (this.inputValue != this.value) {
                this.$emit('change', this.inputValue)

                if (this.validate) {
                    this.$emit('valid', this.isValid)
                }
            }
        }
    },

    computed: {
        isValid() {
            if (this.validate && this.data != null) {
                if (this.data.length == 0) return true;
                if (this.number != true) return !this.data.map(device => String(device)).includes(this.inputValue)
                else return !this.data.map(device => parseInt(device)).includes(parseInt(this.inputValue))
            }

            return null;
        }
    },

    data() {
        return {
            showPassword: false,
            inputValue: this.value
        }
    },

    methods: {
        clearInput() {
            this.inputValue = ''
        }
    }


}
</script>


<style scoped>
.mono {
    font-family: monospace;
    font-weight: 700;
    text-transform: uppercase;
}

.no-mono {
    font-family: 'Lato', sans-serif !important;
}
</style>


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FieldHeader from '@/components/helper/FieldHeader.vue';
import { CHECKBOX_FIELD } from '@/models/exports/pdf/fields/checkbox.model';
import { replaceTextVariables } from '@/plugins/variables.helper';

@Component({
  components: {
    FieldHeader
  },
})


export default class CheckboxField extends Vue {

  @Prop() value!: CHECKBOX_FIELD;
  @Prop() showMissing!: boolean;
  @Prop() readonly!: boolean


  mounted(): void {
    if (this.value.value.length == 0) {
        this.value.value = new Array(this.value.options.elements.length).fill(false) as any;
    }
  }



  get elements(): any {
    return this.value.options.elements.map((ele: any) => ({...ele, text: (this.$store.getters.report == null || this.$store.getters.report.id == null ? ele.text : replaceTextVariables(this.$store.getters.report, ele.text))}))
  }
  update(): void {

    // const valueArr: any = [...this.value.value]; 
    // console.log(valueArr)
    // const index = valueArr.indexOf(id) 
    // if (index >= 0) {
    //     valueArr.splice(index, 1)
    // } else {
    //     valueArr.push(id)
    // }
    
    this.$report.updateField({...this.value, value: this.value.value})

  }
}



    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import IconButton from '@components/button/IconButton.vue'
    
    @Component({
        components: {
          IconButton
        },
    })
    
    
    
    export default class DeleteIconButton extends Vue {

        @Prop() status!: boolean
        @Prop() loading!: boolean;
        
        ctrlKeyPressed = false;
        
        mounted(): void {
            window.addEventListener('keydown', (e: KeyboardEvent) => this.listener(e));
            window.addEventListener('keyup', (e: KeyboardEvent) => this.listener(e));

        }

        listener(e: KeyboardEvent): void {
            this.ctrlKeyPressed = e.altKey;// && e.shiftKey;

        }
        @Watch('status')
        onStatusChange(): void {
            window.removeEventListener('keydown', (e: KeyboardEvent) => this.listener(e));
            window.removeEventListener('keyup', (e: KeyboardEvent) => this.listener(e));
        }
    }
    
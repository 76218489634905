

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as  ALINOREPORT_CONFIG from '@/config/alinoreport.config.json'

@Component
export default class AuthCardTemplate extends Vue {

    @Prop() title!: string;
    @Prop() subtitle!: string;
    @Prop() icon!: string;
    @Prop() legal!: boolean


    ALINOREPORT_CONFIG=ALINOREPORT_CONFIG;
    version=process.env.VUE_APP_VERSION // package.json version

}





export const ORGANISATION_ROLE = {
    ADMIN: 1, 
    MANAGER: 2,
    USER: 3,
    EDITOR: 4
}

export const ORGANISATION_ROLES_TEXT = [
    {
        id: ORGANISATION_ROLE.ADMIN,
        text: 'Administrator',
        disabled: false,
        descr: 'Editor:innggen haben Schreib- und Leserechte für eine Vorlage. Sie können alle Berichte von der Vorlage ansehen. Ferner können Editor:innen andere Mitarbeiter:innen einladen. '
    },
    {
        id: ORGANISATION_ROLE.MANAGER,
        text: 'Manager:in',
        descr: 'Betrachter:innen habeng lediglich Lesezugriff auf die Vorlage. Sie können alle Berichte von der Vorlage ansehen.'
    },
    {
        id: ORGANISATION_ROLE.EDITOR,
        text: 'Editor:in',
        descr: 'Betrachter:innen fhaben lediglich Lesezugriff auf die Vorlage. Sie können alle Berichte von der Vorlage ansehen.'
    },
    {
        id: ORGANISATION_ROLE.USER,
        text: 'Nutzer:in',
        descr: 'Betrachter:dinnen haben lediglich Lesezugriff auf die Vorlage. Sie können alle Berichte von der Vorlage ansehen.'
    }
]
import _Vue from "vue";
import {
  getDocs,
  orderBy,
  startAfter,
  limit,
  collection,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  query,
  where,
  deleteDoc,
//   updateDoc,
} from "firebase/firestore";
import { firebaseFirestore } from "../firebase";
import { TEMPLATE_ELEMENT } from "@/models/api/template.model";
import { User } from "@firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { BASE_FIELD } from "@/models/exports/pdf/fields/field";
import store from "@/store";
import router from "@/router";
import axios from "axios";

declare module "vue/types/vue" {
  export interface Vue {
    $reports: ReportPlugin;
  }
}

class ReportPlugin {
  projectsCollection = collection(firebaseFirestore, "reports").withConverter(
    new ReportConverter()
  );



  lastTemplateElement: QueryDocumentSnapshot | null = null;
  static install(Vue: typeof _Vue) {
    Vue.prototype.$reports = new ReportPlugin();
  }


  

  
  async getActiveReports(): Promise<any[]> {
    const organisationCollection = collection(
        firebaseFirestore,
        "organisations",
        router.currentRoute.params.organisationId,
        "reports"
      ).withConverter(new ReportConverter());


      // filter only labels where user has role
      const labelGroups = Object.keys(store.getters.members.groups).filter((groupId: string) => store.getters.members.groups[groupId].members[_Vue.prototype.$firebase.auth.uid] != null); 

      const reqGroups = [_Vue.prototype.$firebase.auth.uid, ...labelGroups].flat();

      let data = []
      for (let index = 0; index < reqGroups.length; index += 10) {
        const groups = reqGroups.slice(index, index+10)
        // console.log(groups)
        const queryHandle = query(organisationCollection, where("status.complete", "==", false),  
        where("status.owner", "in", groups), orderBy("meta.lastEdited", "desc"), limit(50));

        // queryHandle = query(queryHandle, where("archived", "!=", true), 
        //  )
  
        const snapshot = await getDocs(queryHandle);
        console.log("RESP", snapshot)
        data = [...data, ...(snapshot.docs.map((doc) => doc.data()))].flat()
      }
  
  
  
      console.log("RE", data)
      return data


  }


  async calendar(
    organisationId: string, 
    complete: boolean, 
    labels: string[]
    ): Promise<any> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            organisationId,
            "reports"
          ).withConverter(new ReportConverter());


          console.log(labels, complete)
        let q = query(organisationCollection, 
            where('meta.creationLabel', "in", labels)
            );


        if (complete == true) {
            // console.log("CHECK TEMPLATe", templateId);
            q = query(
              q,
              where("status.complete", "==", complete)
            );
          }


          const snapshot = await getDocs(q);

          return snapshot.docs.map((doc) => ({
            timed: doc.data().status.complete,
            name: doc.data().reference || '-', 
            start: doc.data().status.complete == true ? new Date(doc.data().meta.createdAt) : (new Date(doc.data().status.resubmission)),
            end: doc.data().status.complete == false ? null : doc.data().meta.finishedAt || (+ new Date((new Date()).setTime(23))), 
            color: doc.data().status.complete ? 'grey' : 'red',
            labelId: doc.data().meta.creationLabel,
            report: {
                status: doc.data().status,
                title: doc.data().meta.title,
                reference: doc.data().reference,
                step: doc.data().meta.step,
                lastEditor: doc.data().meta.lastEditor,
                lastEdited: doc.data().meta.lastEdited,
                id: doc.data().id,
            }
          }));

    }
  
  async get(
    organisationId: string,
    searchValue: string,
    templateId: null | string = null,
    userId: null | string = null
  ): Promise<any[]> {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "reports"
    ).withConverter(new ReportConverter());

    let q = query(organisationCollection);

    if (templateId != null) {
      // console.log("CHECK TEMPLATe", templateId);
      q = query(q, where("templateId", "==", templateId));
    }

    if (userId != null) {
      // console.log("CHECK TEMPLATe", templateId);
      q = query(
        q,
        where("meta.createdBy", "==", userId),
        where("isComplete", "==", false)
      );
    }

    if (searchValue.length > 0) {
      console.log("a");
      q = query(
        q,
        orderBy("identifierValue"),
        where("identifierValue", ">=", searchValue),
        where("identifierValue", "<=", searchValue + "\uf8ff"),
        orderBy("meta.lastEdited", "desc")
      );
    } else if (this.lastTemplateElement != null) {
      console.log("b");
      q = query(
        q,

        orderBy("meta.lastEdited", "desc"),
        startAfter(this.lastTemplateElement)
      );
    } else {
      q = query(
        q,

        orderBy("meta.lastEdited", "desc")
        // where('status', '==', isComplete ? 2 : 1),
        // limit(25)
      );
    }

    q = query(
      q,

      // orderBy("meta.lastEdited", "desc"),
      // where('status', '==', isComplete ? 2 : 1),
      limit(25)
    );

    const snapshot = await getDocs(q);

  

    if (searchValue.length > 0) {
      this.lastTemplateElement = null;
    } else {
      this.lastTemplateElement =
        snapshot.docs[snapshot.docs.length - 1] || null;
    }

    return snapshot.docs.map((doc) => ({
      status: doc.data().status,
      title: doc.data().meta.title,
      isComplete: doc.data().status.complete,
      refValue: doc.data().reference,
      step: doc.data().step,
      owner: doc.data().status.owner,
      lastEditor: doc.data().meta.lastEditor,
      lastEdited: doc.data().meta.lastEdited,
      id: doc.data().id,
    }));
  }

  resetPagination(): void {
    this.lastTemplateElement = null;
  }
  async getByTemplateId(
    organisationId: string,
    templateId: string,
    searchValue: string,
    isComplete = false
  ): Promise<any[]> {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "templates",
      templateId,
      "reports"
    ).withConverter(new ReportConverter());

    console.log("HERER", isComplete, organisationId, templateId, searchValue);
    let q = query(
      organisationCollection,
      where("templateId", "==", templateId)
    );

    if (searchValue.length > 0) {
      console.log("a");
      q = query(
        q,
        orderBy("identifierValue"),
        where("identifierValue", ">=", searchValue),
        where("identifierValue", "<=", searchValue + "\uf8ff")
      );
    } else if (this.lastTemplateElement != null) {
      console.log("b");
      q = query(
        q,

        orderBy("lastEdited", "desc"),
        startAfter(this.lastTemplateElement)
      );
    }

    q = query(
      q,

      orderBy("lastEdited", "desc"),
      // where('status', '==', isComplete ? 2 : 1),
      limit(25)
    );
    // if (searchValue.length > 0) {

    //   q = query(organisationCollection,
    //     where('isComplete', '==', isComplete),
    //     orderBy("identifierValue"),
    //     where('identifierValue', '>=', searchValue),
    //     where('identifierValue', '<=', searchValue+ '\uf8ff'),
    //     orderBy('lastEdited', 'desc'),
    //     limit(25)
    //   );

    // } else if (this.lastTemplateElement == null) {
    //   q = query(organisationCollection,

    //     where('isComplete', '==', isComplete),
    //     where('templateId', '==', templateId),
    //     orderBy("lastEdited", 'desc'),
    //     limit(25)
    //   );

    // } else {
    //   q = query(organisationCollection,
    //     where('isComplete', '==', isComplete),
    //     where('templateId', '==', templateId),
    //     orderBy("lastEdited", 'desc'),
    //     startAfter(this.lastTemplateElement),
    //     limit(25)
    //   );
    //   }

    // console.log(q)
    const snapshot = await getDocs(q);

    console.log(
      snapshot.docs.map((doc) => doc.data()),
      "moin"
    );
    if (searchValue.length > 0) {
      this.lastTemplateElement = null;
    } else {
      this.lastTemplateElement =
        snapshot.docs[snapshot.docs.length - 1] || null;
    }

    return snapshot.docs.map((doc) => doc.data());

    // const snapshot = await getDocs(q);
    // return snapshot.docs.map((doc) => doc.data());
  }

  async getById(id: string, organisationId: string): Promise<any | undefined> {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "reports"
    ).withConverter(new ReportConverter());

    const snapshot = await getDoc(doc(organisationCollection, id));
    store.commit("report", snapshot.data());
    console.log("C;OM");

    return snapshot.data();
  }

  async delete( id: string, archive = false): Promise<void> {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      router.currentRoute.params.organisationId,
      "reports"
    ).withConverter(new ReportConverter());

    if (archive == false) await deleteDoc(doc(organisationCollection, id));
    else setDoc(doc(organisationCollection, id), {archived: true, status: {complete: true, resubmission: null, owner: _Vue.prototype.$firebase.auth.uid, state: 'Bericht archiviert'}}, {merge: true})
    return;
  }

  watchProjectById(
    id: string,
    organisationId: string,
    callback: (project: any | undefined) => void
  ): void {
    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "reports"
    ).withConverter(new ReportConverter());

    onSnapshot(doc(organisationCollection, id), {
      next: (snapshot) => {
        console.log(snapshot.data());
        callback(snapshot.data());
      },
    });
  }

  flatten(array: any): any {
    let result: any = [];
    const vm = this;
    array.forEach(function (a: any) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(vm.flatten(a.children));
      }
    });
    return result;
  }

  getElementVariables(ele: any): any {
    if (ele.children != null && ele.children.length > 0) {
      return ele.children.map((element: any) =>
        this.getElementVariables(element)
      );
    } else if (ele.variable != null) {
      return { header: ele.header, type: ele.type, variable: ele.variable };
    }
  }

  async create(organisationId: string, templateId: string, labelId: null | string = null): Promise<any> {
    const resp = await axios.post(`https://europe-west3-alinoreport.cloudfunctions.net/api/${organisationId}/create/${templateId}`, {owner: _Vue.prototype.$firebase.auth.uid, label: (labelId as string | ''), resubmission: (new Date())}, 
    {headers: {
        Authorization: await _Vue.prototype.$firebase.auth.user.getIdToken() //the token is a variable which holds the token
    }})
//   console.log(resp)

  return resp.data
    // const user = _Vue.prototype.$firebase.auth.user;
    // const template = await _Vue.prototype.$templates.getById(
    //   organisationId,
    //   templateId
    // );

    // // console.log(this.getElementVariables({children: (template as any).steps}))

    // const report = {
    //   id: uuidv4(),
    //   templateId: template.id,

    //   labels: template.labels,

    //   steps: template.steps,

    //   refValue: "",

    //   status: template.options.status || "",
    //   step: 0,
    //   isComplete: false,

    //   meta: {
    //     title: template.options.title,
    //     share: template.options.share,
    //     identifier: template.options.identifier,
    //     docs: template.options.docs,

    //     lastEditor: user.uid,
    //     lastEdited: +new Date(),

    //     createdAt: +new Date(),
    //     createdBy: user.uid,
    //   },

    //   exports: template.exports,
    // };

    // const organisationCollection = collection(
    //   firebaseFirestore,
    //   "organisations",
    //   organisationId,
    //   "reports"
    // ).withConverter(new ReportConverter());

    // await setDoc(doc(organisationCollection, report.id), report);
    // return report;
  }

  isDebounced = false;

  async updateProject(report: any, organisationId: string): Promise<void> {
    if (this.isDebounced == true) return;

    const organisationCollection = collection(
      firebaseFirestore,
      "organisations",
      organisationId,
      "reports"
    ).withConverter(new ReportConverter());

    const projectHandle = report;

    // projectHandle.meta.lastEdited = (+ new Date());

    // projectHandle.updatedAt = Date.now();

    const projectTempHandle = JSON.parse(JSON.stringify(projectHandle));

    console.log("UPR", projectTempHandle);
    projectTempHandle.meta.lastEditor =
      _Vue.prototype.$firebase.auth.isPublicEditor != true
        ? _Vue.prototype.$firebase.auth.uid
        : _Vue.prototype.$firebase.auth.user.email;

    projectTempHandle.meta.lastEdited = +new Date();
    try {
      await setDoc(
        doc(organisationCollection, projectHandle.id),
        projectTempHandle
      );
      // store.commit('report', projectHandle)
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      this.isDebounced = false;
    }, 500);
  }
}

class ReportConverter implements FirestoreDataConverter<any> {
  toFirestore(project: any): DocumentData {
    const json = { ...project } as any;
    delete json.id;
    return json;
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): any {
    return { ...snapshot.data(), id: snapshot.id } as any;
  }
}

_Vue.use(ReportPlugin);

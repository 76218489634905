
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'


import { TEMPLATE_DOC } from '@/models/api/template.model';
import prettyBytes from 'pretty-bytes';

import IconButton from '@components/button/IconButton.vue'
import TextButton from '@components/button/TextButton.vue'

import FileViewer from '@/components/helper/viewer/FileViewer.vue'
import DialogTemplate from '@components/dialog/DialogTemplate.vue'
import { NavigationGuardNext, RawLocation, Route } from 'vue-router';
import DeleteIconButton from './DeleteIconButton.vue';
import { uuidv4 } from '@firebase/util';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';


@Component({
    components: {
        TextButton,
        DeleteIconButton,
        DialogTemplate,
        IconButton,
        TextInput,
        FileViewer,
        SwipeList
    },
})





export default class FileList extends Vue {

    @Prop() files!: TEMPLATE_DOC[];
    @Prop() allowUpload!: boolean;
    @Prop() deletable!: boolean;
    @Prop() selectable!: boolean;
    @Prop() selected!: string
    @Prop() uploadTarget!: string|null
    @Prop() accept!: string[]
    @Prop() singleUpload!: boolean;

    @Prop() highlight!: boolean


    get selectedIndex(): number {
        return this.files.findIndex((file: TEMPLATE_DOC) => file.id==this.selected)
    }

    loadingFile=false;


    uploadProgress = 0;
    loadingFileName: (null|string)=null;

    fileDeleteIndex=-1;
    loadingDelete=false;


    get getPlaceholderText(): string {
        if (!(this.accept==null||this.accept.length==0||this.accept.length>1)) {
            if (this.accept[0]=='image/*') return `Bild hochladen`
            else if (this.accept[0]=='video/*') return 'Video hochladen'
            else if (this.accept[0]=='audio/*') return 'Audiodatei hochladen'
            else if (this.accept[0]=='.pdf') return 'PDF-Dokument hochladen'
        }


        return 'Datei hochladen'
    }

    get getPlaceholderIcon(): string {
        if (!(this.accept==null||this.accept.length==0||this.accept.length>1)) {
            if (this.accept[0]=='image/*') return 'mdi-image-plus'
            else if (this.accept[0]=='video/*') return 'mdi-video-plus'
            else if (this.accept[0]=='audio/*') return 'mdi-volume-high'
            else if (this.accept[0]=='.pdf') return 'mdi-file-pdf-box'
        }


        return 'mdi-upload'
    }


    previewFile(index: number, id: string): void {
        if (this.selectable==true) {
            this.$emit('selected', id);
            return;
        }
        if (this.loadingDelete==true) return;



        (this.$refs.viewer as FileViewer).open(this.files[index])


    }





    async deleteFile(index: number): Promise<void> {

        // const index = this.fileDeleteIndex;
        this.loadingDelete=true;

        const fileHandle = this.files[index]
        try {
            await this.$firebase.storage.delete(fileHandle.path);
            this.files.splice(index, 1)
            

        } catch (error) {
            console.log(error)
            if ((error as any).code=='storage/object-not-found') {
                this.files.splice(index, 1)
            }

        }

        if (fileHandle != null) this.$emit('delete', {list: this.files, deleted: fileHandle})



        setTimeout(() => {
this.$emit('delete')
            this.loadingDelete=false;
            this.fileDeleteIndex=-1;
        }, 250);





    }

    bytesString(byte: number): string {
        return prettyBytes(byte)
    }




    // async deleteFile(index: number): Promise<void> {
    //   if (this.files.length == 0) return;


    //   console.log("DELETE")
    //   await this.$firebase.storage.delete(this.files[index].path);
    //   this.files.splice(index)
    // }

    shortenFileName(name: string, type: string, length: number): string {
        const extension=type.split('/')[1];
        const filename=name.replace(`.${extension}`, '');

        if (filename.length+extension.length>length) {
            return `${filename.substring(0, length-6)}...${filename.slice(-3)}.${extension}`
        } else {
            return `${filename}.${extension}`
        }
    }

    async onFileSelection(fileArr: File): Promise<void> {
        // console.log(fileArr)        
        console.log("f", fileArr, this.uploadTarget, fileArr.name, fileArr)
        if (fileArr==null) return;
        this.loadingFile=true

        


        try {
            // todo: if upload target is null?
            this.loadingFileName=this.shortenFileName(fileArr.name, fileArr.type, 15)+` (${this.bytesString(fileArr.size)})`

            const path=await this.$firebase.storage.upload(this.uploadTarget as string, `${(+ new Date())}-${fileArr.name}`, (fileArr as Blob), async (progress: any, path: any) => {
                console.log("---", progress, path);
                this.uploadProgress = Math.ceil(progress/5)*5;
                if (path!=null&&progress==100) {
                    
                    if (path==null||path.ref==null) throw new Error();

                    let uploadDocObj={
                        id: uuidv4(),
                        name: fileArr.name,
                        path: path.ref.fullPath,
                        type: fileArr.type,
                        size: (fileArr.size as number),
                        author: this.$firebase.auth.uid as string

                    }
                    console.log(uploadDocObj)
                    if (fileArr.type.startsWith('image/')) {
                        const img: HTMLImageElement=await new Promise((resolve, reject) => {
                            const img=new Image();
                            img.onload=() => resolve(img);
                            img.onerror=reject;
                            img.src=URL.createObjectURL(fileArr);

                        });


                        console.log("IM", img)
                        if (img!=null) {

                            uploadDocObj={ ...uploadDocObj, width: img.width, height: img.height } as any;
                        }
                        console.log(uploadDocObj)

                    }
                    this.$emit('upload', uploadDocObj)



                    this.uploadProgress = 0;
                this.loadingFile=false;
                this.loadingFileName=null;


                }
            })
        } catch (error) {
                    this.$toast.error('Datei konnte nicht hochgeladen werden')
                    // console.log(error)

                this.loadingFile=false;
                this.loadingFileName=null;
                }

            }



    
}

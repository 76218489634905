

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import TextButton from '@components/button/TextButton.vue'
import IconButton from '@components/button/IconButton.vue'

import draggable from "vuedraggable";
import { IMAGES_FIELD } from '@/models/exports/pdf/fields/images.model';
import FieldHeader from '@/components/helper/FieldHeader.vue';
import FileList from '@/components/helper/FileList.vue';

@Component({
  components: {
    FileList,
    FieldHeader,
    IconButton,
    TextButton,
    TextInput,
    draggable
  },
})


export default class UploadImageField extends Vue {

  @Prop() value!: IMAGES_FIELD;
  @Prop() showMissing!: boolean; 
  @Prop() readonly!: boolean;
  

  update(eventHandle: any): void {
  this.$report.updateField({...this.value, value: (this.value as any).options.multiple? [...this.value.value, eventHandle] : [eventHandle]}, {actionType: 20,status: this.$store.getters.report.status,  exports: [eventHandle], reference: this.$store.getters.report.reference || '',  templateId:  this.$store.getters.report.templateId, template: this.$store.getters.report.meta.title})
  }

  updateDeletion(fileList: any): void {
    this.$report.updateField({...this.value, value: fileList.list}, {actionType: 21, deleted: fileList.deleted, status: this.$store.getters.report.status, reference: this.$store.getters.report.reference || '', exports: [], templateId:  this.$store.getters.report.templateId, template: this.$store.getters.report.meta.title,})

  }
}



import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectorInput from '@components/text/SelectorInput.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue'
import { SELECTOR_FIELD } from '@/models/exports/pdf/fields/selector.model';
import { replaceTextVariables } from '@/plugins/variables.helper';

@Component({
  components: {
    SelectorInput,
    FieldHeader
  },
})


export default class SelectorField extends Vue {

  @Prop() value!: SELECTOR_FIELD;
  @Prop() showMissing!: boolean;
  @Prop() readonly!: boolean




  get elements(): any {
    return this.value.options.elements.map((ele: any) => ({...ele, text: (this.$store.getters.report == null || this.$store.getters.report.id == null ? ele.text : replaceTextVariables(this.$store.getters.report, ele.text))}))
  }

  update(eventHandle: any): void {
    this.$report.updateField({...this.value, value: eventHandle})

  }
}

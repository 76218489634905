import _Vue from "vue";
import {
    collection,
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    doc,
    setDoc,
    onSnapshot,
    arrayUnion,
} from "firebase/firestore";

import { uuidv4 } from '@firebase/util';

import { firebaseFirestore } from "../firebase";
import router from "@/router";
import { editorEventBus } from "@/main";
import store from "@/store";
import axios from "axios";
import { FIELDS } from "@/fields.types";
import { EXPORT } from '@/plugins/export/report.export';

declare module "vue/types/vue" {
    export interface Vue {
        $report: ReportPlugin;
    }
}



class ReportPlugin {

    debounced = false;
    unsubscribeWatcher = null;
    projectsCollection = collection(firebaseFirestore, "reports").withConverter(
        new ReportConverter()
    );

    static install(Vue: typeof _Vue) {
        Vue.prototype.$report = new ReportPlugin();


    }

    isDebounced = false;


    async reopen(reportId: string, report: any): Promise<void> {
        const resp = await axios.get(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/${router.currentRoute.params.organisationId}/${reportId}/reopen`,
            
            {
                headers: {
                    Authorization:
                        await _Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );
        
        if (resp.status == 200) {
            await this.changelog(EXPORT.REOPEN, {status: report.status,reference: report.reference || '', exports: [], templateId:  report.templateId, template: report.meta.title})
        }
        else throw new Error();
    }
    unsubscribe(): void {
        console.log("> Remove subscription", this.unsubscribeWatcher != null)

        if (this.unsubscribeWatcher != null) this.unsubscribeWatcher(); 
    }
    watch(id: string, callback: (project: any | undefined) => void): void {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            router.currentRoute.params.organisationId,
            "reports"
        ).withConverter(new ReportConverter());

        this.unsubscribe();

        this.unsubscribeWatcher = onSnapshot(doc(organisationCollection, id), {
            next: (snapshot) => {
                console.log(snapshot.data());
                callback(snapshot.data());
            },
            error: (error) => {
                _Vue.prototype.$toast.error('Bericht kann nicht geladen werden')
                console.log("WATCH REPORT: ", error)
            }
        });

    }


    async updateLocation(geolog: any): Promise<void> {
    
  
        if (router.currentRoute.name == 'template' || router.currentRoute.params.templateId != null) return;

        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            router.currentRoute.params.organisationId,
            "reports"
        ).withConverter(new ReportConverter());

        const dataObj = {
            meta: {
                lastEdited: +new Date(),
                lastEditor: _Vue.prototype.$firebase.auth.uid,
                // step: fieldData.step || 0
            },
            geolocations: arrayUnion(geolog)
        };
   
        
        try {
            await setDoc(
                doc(
                    organisationCollection,
                    router.currentRoute.params.reportId
                ),
                dataObj,
                { merge: true }
            );
            // store.commit('report', projectHandle)
        } catch (error) {
            _Vue.prototype.$toast.error('Daten können nicht gespeichert werden')
            console.log("2", error);
        }

    }
    async updateField(
        fieldData: any,
        changelog: any | null = null
    ): Promise<void> {
    
        console.log(">>> UPDATE FiELD", fieldData)
        if (fieldData.local == true) {
            editorEventBus.$emit("update-local-field", fieldData);
            return;
        }

        if (router.currentRoute.name == 'template' || router.currentRoute.params.templateId != null) return;

        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            router.currentRoute.params.organisationId,
            "reports"
        ).withConverter(new ReportConverter());

        let fieldDataContent = {}; 
        
        if (fieldData.type == FIELDS.GROUP) {
            fieldDataContent =  {
                children: fieldData.children,
            }
        } else {
            fieldDataContent =  {
                value: fieldData.value,
            }
        }

        let dataObj = {
            meta: {
                lastEdited: +new Date(),
                lastEditor: _Vue.prototype.$firebase.auth.uid,
                // step: fieldData.step || 0
            },
            data: {
                [fieldData.id]: {
                    ...fieldDataContent,
                    step: router.currentRoute.query.step || 0,

                    lastEdited: +new Date(),
                    lastEditor: _Vue.prototype.$firebase.auth.uid,
                },
            },
        };
        // console.log(">>>>", store.getters.report.options.identifier, fieldData)
        if (fieldData.id == store.getters.report.options.identifier) {
            dataObj = { ...dataObj, reference: fieldData.value as any } as any;
            // console.log("Dlkmnfdkjlnsdlfn")
        }
        
        try {
            await setDoc(
                doc(
                    organisationCollection,
                    router.currentRoute.params.reportId
                ),
                dataObj,
                { merge: true }
            );

            if (changelog != null && changelog.actionType != null) await this.changelog(changelog.actionType, changelog)


            // changelog == null
            //         ? dataObj
            //         : { ...dataObj, ...this.createChangelog(changelog) },
            // store.commit('report', projectHandle)
        } catch (error) {
            _Vue.prototype.$toast.error('Daten können nicht gespeichert werden')
            console.log("2", error);
        }

    }


    
    async update(
        data: any,
        changelog: any | null = null,
        id: string | null = null
    ): Promise<void | string> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            router.currentRoute.params.organisationId,
            "reports"
        ).withConverter(new ReportConverter());

        console.log(">>>>> UPDATE:", id, data);
        const dataObj = {
            meta: {
                // step: router.currentRoute.query.step || 0,
                lastEdited: +new Date(),
                lastEditor: _Vue.prototype.$firebase.auth.uid,
            },
            ...data,
        };
        try {
            await setDoc(
                doc(
                    organisationCollection,
                    id || router.currentRoute.params.reportId
                ),
         dataObj,
                { merge: true }
            );

            if (changelog != null) return await this.changelog(changelog.actionType, changelog)

            // changelog == null
            // ? dataObj
            // : { ...dataObj, ...this.createChangelog(changelog) },
            // store.commit('report', projectHandle)
        } catch (error) {
            console.log("3", error);
        }
    }

    async forward(reportId, ownerId: string, resubmission: Date): Promise<void> {

        console.log("---> FORWARD")
        const resp = await axios.post(
            `https://europe-west3-alinoreport.cloudfunctions.net/api/${router.currentRoute.params.organisationId}/report/${reportId}/forward`,
            {
                owner: ownerId, 
                resubmission: resubmission
            },
            {
                headers: {
                    Authorization:
                        await _Vue.prototype.$firebase.auth.user.getIdToken(), //the token is a variable which holds the token
                },
            }
        );
        
    }

    createChangelog(data: any): any {
        return {
           
                date: +new Date(),
                step: parseInt(
                    (router.currentRoute.query.step as string) || ""
                ) || null,
                editor: _Vue.prototype.$firebase.auth.uid,
                ...data,
           
        };
    }

    async changelog(type: number, data: any): Promise<void | string> {
        const organisationCollection = collection(
            firebaseFirestore,
            "organisations",
            router.currentRoute.params.organisationId,
            "reports", router.currentRoute.params.reportId, "actions"
        ).withConverter(new ReportConverter());

        const logId = uuidv4();
        console.log(type, data)
        try {
            await setDoc(
                doc(
                    organisationCollection,
                    logId
                ),
                this.createChangelog({actionType: type, ...data}),
                { merge: true }
            );

            return logId;
            // store.commit('report', projectHandle)
        } catch (error) {
            console.log("1", error);
            return;
        }
    }

    
}

class ReportConverter implements FirestoreDataConverter<any> {
    toFirestore(project: any): DocumentData {
        const json = { ...project } as any;
        if (project.generated != null) delete project.generated
        delete json.id;
        return json;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): any {
        // console.log("FROM F_IRESTORE")
        return { ...snapshot.data(), id: snapshot.id } as any;
    }
}

_Vue.use(ReportPlugin);




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
    },
})



export default class Title extends Vue {
    @Prop() title!: string; 
    @Prop() highlight!: boolean
}

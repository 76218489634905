

import { replaceTextVariables } from '@/plugins/variables.helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RequiredField from './RequiredField.vue';
// import TextInput from '@components/text/TextInput.vue'
// import SwitchSubtext from '@/components/helper/SwitchSubtext.vue';
// import IconButton from '@components/button/IconButton.vue';

@Component({
  components: {
    RequiredField
  },
})


export default class FieldHeader extends Vue {

  @Prop() title!: string;
  @Prop() required!: boolean;
  @Prop() group!: boolean


  get header(): string {
    return this.$store.getters.report != null && this.$store.getters.report.id != null ? replaceTextVariables(this.$store.getters.report, this.title || '') : this.title
  }
}

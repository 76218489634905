<template>
<div>
  <v-tooltip
    :disabled="disabled || tooltip == undefined || (disabled && tooltipDisabled == undefined) || $vuetify.breakpoint.smAndDown"
    
    :open-delay="tooltipDelay"
    :bottom="tooltipPosition == 'b' || tooltipPosition == undefined"
    :top="tooltipPosition == 't'"
    :left="tooltipPosition == 'l'"
    :right="tooltipPosition == 'r'"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs"
        v-on="on">

      <v-btn
        :dark="dark"
        :small="dense"
        :ripple="false"
        rounded
        :loading="loading"
        depressed
        :disabled="disabled"
        :color="buttonColor"
        v-on:click="$emit('click', $event)"
        icon
      >
          <v-icon :size="size == null ? (dense ? '18' : '22') : size" :color="color">{{ icon }}</v-icon>
      </v-btn>

      </div>
    </template>
    <div class="sub-text"  style="font-size: 12px" v-if="!disabled && tooltipDisabled != null">{{ tooltip }}</div>
    <div v-else>{{tooltipDisabled}}</div>
  </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    buttonColor: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      required: false,
      default: undefined,
    },

    size: {
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },


    tooltipDelay: {
      type: [String, Number],
      required: false,
      default: 200,
    },

    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },
    tooltipDisabled: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: "b", // 'l', 'r', 't'
      validator: function (value) {
        return ["b", "l", "r", "t"].indexOf(value) !== -1;
      },
    },
  },
};
</script>


<style scoped>
.sub-text {
  font-size: 13px; 
  color: white;
  user-select: none;
  font-weight: 700;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}

</style>



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { TEMPLATE_DOC } from '@/models/api/template.model';
import IconButton from '@components/button/IconButton.vue'
import PDFViewer from './PDFViewer.vue';
import { download } from '@/plugins/helper'
import ImageViewer from './ImageViewer.vue';
import axios from 'axios';
import Papa from 'papaparse'
// import csvdata from 'csvdata'
@Component({
    components: {
        IconButton,
        PDFViewer,
        ImageViewer
    },
})




export default class FileViewer extends Vue {

    doc: TEMPLATE_DOC|null=null

    zoomLevel=1

    loadingDownload=false;
    viewerDialog=false;
    fileUrl: (string|null)=null;
    loadingFile=true;
    errorLoading=false;

    errorImage=false;


    loadingTimeout: any=null;

    async downloadFile(): Promise<void> {
        // this.$toast.info('Datei wird heruntergeladen...')
        if (this.doc==null||this.fileUrl==null) return;
        download(this.fileUrl, (this.doc as TEMPLATE_DOC).name, (this.doc as TEMPLATE_DOC).type)

    }
    renderedFile(): void {
        console.log("RENDERED!!!")
        setTimeout(() => {
            this.loadingFile=false
        }, 150);
    }

    keyboardEvent(event: KeyboardEvent): void {
        console.log(event)
        if (this.viewerDialog==true&&this.loadingFile==false&&this.errorLoading==false) {
            if (event.key=='+') this.updateZoom(false)
            else if (event.key=='-') this.updateZoom(true)
        }
    }



    beforeDestroy(): void {
        if (this.doc!=null&&this.doc.type=='application/pdf') window.removeEventListener('keydown', this.keyboardEvent)
    }

    updateZoom(out=true): void {
        if (out==false) this.zoomLevel+=.1;
        else this.zoomLevel-=.1

        if (this.zoomLevel>2) this.zoomLevel=2;
        else if (this.zoomLevel<1) this.zoomLevel=1

    }

    @Watch('viewerDialog', { immediate: true })
    onDialogChange(): void {

        if (this.viewerDialog==true) return;

        if (this.doc!=null&&this.doc.type=='application/pdf') window.removeEventListener('keydown', this.keyboardEvent)

        clearTimeout(this.loadingTimeout)

        setTimeout(() => {
            this.loadingFile=true;
            this.doc=null;
            this.fileUrl=null;
        }, 250);
    }

    async open(doc: TEMPLATE_DOC): Promise<void> {
        // console.log("OPEN")
        this.zoomLevel=1

        if (doc==null||doc.path==null) return;
        if (doc.type=='application/pdf') window.addEventListener('keydown', this.keyboardEvent)

        this.loadingFile=true;
        this.errorLoading=false;
        this.doc=doc;



        this.viewerDialog=true;

        this.loadingTimeout=setTimeout(async () => {
            try {
                const resp=await this.$firebase.storage.get(doc.path);

                console.log("RESP", resp)
                this.fileUrl=resp;

                if (this.doc != null && this.doc.type.startsWith('text/csv')) {

                    const csvResponse = await axios.get(resp, { responseType: 'blob'})
                    // csvdata.load(resp, {})
                    if (csvResponse.status != 200 || csvResponse.data == null) throw new Error(); 

                    // console.log(await csvResponse.data.text())
                    // const data = await Papa.parse(await csvResponse.data.text(), {header:true})
                    this.loadingFile = false;
                    // console.log(data)

                } else if (this.doc != null &&( this.doc.type.startsWith('video/'))) {
                    this.loadingFile = false;
                }

            } catch (error) {
                console.log(error)
                this.errorLoading=true;
                this.loadingFile=false;

                this.fileUrl=null;
            }
        }, 250);


    }




}

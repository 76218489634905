

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@components/text/TextInput.vue'
import TextButton from '@components/button/TextButton.vue'
import FieldHeader from '@/components/helper/FieldHeader.vue';

import TableListRow from '@components/table/TableListRow.vue'
import IconButton from '@components/button/IconButton.vue'

import SelectorInput from '@components/text/SelectorInput.vue'
import DialogTemplate from '@components/dialog/DialogTemplate.vue'
@Component({
    components: {
        IconButton,
        DialogTemplate,
        SelectorInput,
        FieldHeader,
        TextInput,
        TextButton,
        TableListRow
    },
})


export default class InventoryElement extends Vue {

    @Prop() item!: any; 
    @Prop() index!: number;
    @Prop() value!: any;
    @Prop() readonly!: boolean;

    

    itemInputBlur(): void {
        console.log("Nmddm", this.value.value);
        this.$emit('change', Object.fromEntries(
            Object.entries(this.value.value as Record<string, string>)
                .filter(([key, value]) => (value as string).length>0&&!Number.isNaN(+(value as string)))
        ));
    }
}



import IconButton from '@components/button/IconButton.vue'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import draggable from "vuedraggable";
// import { isUuid } from 'uuidv4';
import { editorEventBus } from '@/main';
import ConditionText from '@/components/helper/ConditionText.vue';
import { TEMPLATE_ELEMENT } from '@/models/api/template.model';
import NavigationPageTemplate from '@/components/helper/NavigationPageTemplate.vue';


@Component({
    components: {
        draggable,
        ConditionText,
        IconButton
    },
})


export default class GroupArea extends Vue {



    @Prop() group!: string
    @Prop() children!: any;
    @Prop() disabled!: boolean
    @Prop() selectable!: boolean;
    @Prop() isStep!: boolean;
    @Prop() template!: TEMPLATE_ELEMENT;
    // lastClickedItem = false
    @Prop() groupId!: string;

    selectedElements: string[]=[];
    // @Watch('list', { immediate: true, deep: true })
    // onListUpdate(): void {
    //     this.$emit('change', this.list)

    // }
    selected: string|null=null;
    // clickDebounce = false;

    openSidebarActions() {
        const sidebar=document.getElementById('navigation-sidebar')
        console.log(sidebar)
        if (sidebar==null) {
            console.log("HS")
            const doc=document.getElementById('navigation-button');
            console.log(doc)
            if (doc!=null) doc.click();
        }
    }
    clickElement(event: MouseEvent, id: string, type: string): void {


        if (this.selectable==true&&type=='group') return;
        event.stopImmediatePropagation()
        event.preventDefault();

        // console.log("EMIT SELECTED!", id, event)

        this.selected=id;
        editorEventBus.$emit('selected', id);
        this.setSelected(event)


    }

    @Watch('$route.query.full', { immediate: true })
    onRouteChange(): void {
        if (this.selected!=null) editorEventBus.$emit('selected', this.selected)
    }


    beforeDestroy(): void {
        // editorEventBus.$off('selected')
        // editorEventBus.$off('export-selection')
        // document.removeEventListener('keydown', (e: KeyboardEvent) => this.keyboardEvent(e))

    }

    setSelected(e: MouseEvent): void {


        if (this.selectable==true||this.isDragging==true) return;



        // console.log("CHANGE SELECTION", this.selected)
        try {
            const targetDimensions=(e.target as HTMLElement).getBoundingClientRect();
            // console.log(targetDimensions.top)
            const isOverflown=((window.innerHeight-targetDimensions.top-targetDimensions.height)<10);
            if (targetDimensions.top<10||(isOverflown==true&&targetDimensions.height>window.innerHeight)) (e.target as HTMLElement).scrollIntoView({ block: 'start', bottom: 10, behavior: 'smooth' } as any);

            else if (isOverflown) (e.target as HTMLElement).scrollIntoView({ block: 'end', bottom: 10, behavior: 'smooth' } as any);
            // console.log(isOverflown)
        } catch (error) {
            console.error(error)
        }

    }

    isDebounced=false;
    exportSelectionEvent(selectedData: string[]|null): void {
        // if (this.isDebounced==true) return;
      
        console.log("EXPORT SELECTION ELEMENT")
        if (this.selectable==false) {
            this.selectedElements=[];
            return
        }

        // console.log("> SET SELECTION", selectedData)
        if (selectedData!=null) this.selectedElements=selectedData;


        this.isDebounced=false;
        editorEventBus.$once('export-selection', this.exportSelectionEvent)


    }

    setSelection(selectionId: string | null): void {
        this.selectable!=true&&(selectionId==null||selectionId!=this.selected)? this.selected=null:null;

        editorEventBus.$once('selected', this.setSelection)
    }

    mounted(): void {

        editorEventBus.$once('selected', this.setSelection)
        editorEventBus.$once('export-selection', this.exportSelectionEvent)

        // this.$nextTick(() => {

        // })

        // console.log("M", document.getElementById('config.content'))

        // document.getElementsByTagName('group-click')[0].addEventListener('click', (e: MouseEvent) => this.setSelected(e))
        // document.addEventListener('keydown', (e: KeyboardEvent) => this.keyboardEvent(e))
        // editorEventBus.$on("selected", (id: string) => {this.focusChanged(id)});

        // this.exportSelectionEvent([]);
        // if (this.selectable == true) editorEventBus.$once('export-selection', (e: string[]) => this.exportSelectionEvent(e))
    }

    onDragEnd(e: any): void {
        if (this.disabled==true||this.selectable==true) return;

        if (e.item!=null&&e.item.id!=null&&e.item.id.length>0) this.focusElement(e.item.id.replace('element-', ''))

    }


    isDragging=false



    focusElement(id: string): void {
        console.log("FOCUS ELEMENT")
        const doc=document.getElementById(id);
        if (id==null||doc==null) return;

        doc.click()

        this.$nextTick(() => {
            this.isDragging=false;

        })

    }
    deleteElement(index: number): void {
        this.children.splice(index, 1)
    }




    get dragOptions(): any {
        return {
            animation: 200,
            group: "groups",
            disabled: this.selectable==true||this.disabled==true,
        };
    }
}

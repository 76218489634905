import { TEXTFIELD } from "../models/fields/textfield.model";
import { AlinoPDF, SPACER } from "../pdf.class";




/**
 * textfield 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function textfield(this: AlinoPDF, data: TEXTFIELD, width: (null | number) = null, border = true, bold = false):  Promise<void> {

  // in order to specify whether a page break is required the correct height and 
  // width of the component (textfield) need to be calculated; 
  // textfields are page-breaked with the header so no splitting up

  // calculate suffix width; 

  const TEXTFIELD_PADDING = {
    LEFT: border == true ? 3 : 0, 
    RIGHT: border == true ? 3 :0 
  }
  let suffixWidth = 0;
  if (data.options != null && data.options.suffix != null) {
    this._textStyle(this.config.font.light, this.config.color.secondary, 11);
    suffixWidth = this.docObj.getTextWidth(data.options.suffix || '' );
    if (suffixWidth > 0) suffixWidth += TEXTFIELD_PADDING.RIGHT; 
  }


  // calculate textfield width
  let textfieldWidth = 0; 
  if (width != null && width < this.layout.innerWidth) textfieldWidth = width; // user specified width
  else textfieldWidth = this.layout.innerWidth; // block textfield

 
  // calculate textfield height based on suffix and text breaks (if needed)
  this._textStyle(this.config.font.regular, 'black', 11); // set used textstyle for correct calc

  const effectiveMaxText = (textfieldWidth -  TEXTFIELD_PADDING.LEFT - TEXTFIELD_PADDING.RIGHT - suffixWidth);
  const textValueArr = this.docObj.splitTextToSize((data.value || '-'), effectiveMaxText);
  const textArrHeight = this.docObj.getTextDimensions(textValueArr).h; // text height (of all rows!)
  const textfieldHeight = textArrHeight + 4;

  

 
  const headerValueArr = this.docObj.splitTextToSize((data.header || '-').toUpperCase(), this.layout.innerWidth + this.config.page.padding.left);

  // calculate header height if header is specified (note: no page break possible)
  let headerHeight = 0; 
  if (data.header != null && data.header.length > 0) {

    // header style
    this._textStyle(this.config.font.regular,this.config.color.secondary, 10);

    const headerValueHeight = this.docObj.getTextDimensions(headerValueArr).h; // text height (of all rows!)
  
    headerHeight = headerValueHeight + 2;

  }




  // based on textfield height decision whether a page break is needed or not

  const resultingHeight = this.position.y + textfieldHeight + headerHeight + SPACER.VERTICAL;

  if (resultingHeight > (this.layout.outerHeight - this.layout.innerBottom)) {
    
    // create new page;
    await this.create(null, true)
    this.position.y = this.layout.innerTop;
  }
  

  /**
   * As now either a new page was added or not, the data can be added to the pdf 
   * using the according positions; this process is splitted in four parts: 
   * 
   * (1) adding single-line header to pdf, 
   * (2) drawing input field rectangle, 
   * (3) adding content text and 
   * (4) adding suffix if defined
   */


  // --- PART 1: header

  // add header if specified
  if (data.header != null && data.header.length > 0) {
    
    // header text style
    this._textStyle(this.config.font.regular, this.config.color.secondary, 10);

    // add header text
    this.docObj.text(headerValueArr, this.position.x, this.position.y);
    this.position.y += headerHeight -3;
  }



  // --- PART 2: rectangle 
  this.docObj.setDrawColor(border == true ? this.config.color.grey : 'white');
  this.docObj.setLineWidth(0.2);

  // draw rectangle (no fill)
  this.docObj.roundedRect(this.position.x, this.position.y, textfieldWidth , (textfieldHeight), .75, .75);
  this.position.y += 3.3




  // --- PART 3: content 

  // content/value text style
  this._textStyle(bold == true ? this.config.font.bold : this.config.font.regular , 'black', 11); // set used textstyle for correct calc

  this.docObj.text(
    textValueArr,
    this.position.x + TEXTFIELD_PADDING.RIGHT,
    this.position.y + (textfieldHeight - textArrHeight) / 2
  );
   

  // --- PART 5: suffix (if defined) 

  if (data.options != null && data.options.suffix != null) {
    // suffix style
    this._textStyle(this.config.font.light, this.config.color.secondary, 11);

    this.docObj.text(
      data.options.suffix,
      textfieldWidth + this.position.x - suffixWidth,
      (this.position.y + (textfieldHeight - textArrHeight) / 2 ) 
    );
  }
  
  
  // add margin to file position
  this.position.y += textfieldHeight + SPACER.VERTICAL;

}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { FIELDS } from '@/fields.types';

import TextInputField from '@/components/reports/fields/text/TextInputField.vue';
import TextAreaField from '@/components/reports/fields/textarea/TextAreaField.vue';
import GroupEditField from './group/GroupEditField.vue';

import GroupField from './group/GroupField.vue';
import InformationField from './information/InformationField.vue';
import BooleanSelectionField from './boolean/BooleanSelectionField.vue';
import RadioSelectionField from './radios/RadioSelectionField.vue';
import UserSelectionField from './user/UserSelectionField.vue'
import CheckboxField from './checkbox/CheckboxField.vue';
import UploadImageField from './image/UploadImageField.vue';
import DateSelectorField from './date/DateSelectorField.vue';
import InventoryTableField from './inventory/InventoryTableField.vue';
import ContactField from './contact/ContactField.vue';
import AddressField from './address/AddressField.vue';
import { isMissingData } from '@/plugins/helper';
import ChecklistElementField from './checklist/ChecklistElementField.vue';
import SignatureField from './signature/SignatureField.vue';
import SelectorField from './selector/SelectorField.vue';
import TimeSelectorField from './date/TimeSelectorField.vue';
import { TEMPLATE_ELEMENT } from '@/models/api/template.model';
import { CONDITION } from '@/models/condition.model';
import { checkCondition, getById } from '@/plugins/variables.helper';
import LogDateAuthor from '@/components/labels/LogDateAuthor.vue';

@Component({

  name: 'FieldWrapper',
  components: {
    LogDateAuthor,
    TimeSelectorField,
    UserSelectionField,
    SignatureField,
    ChecklistElementField,
    ContactField,
    InventoryTableField,
    UploadImageField,
    DateSelectorField,
    GroupEditField,
    RadioSelectionField,
    GroupField,
    SelectorField,
    CheckboxField,
    AddressField,

    TextInputField,
    TextAreaField,

    InformationField,
    BooleanSelectionField
  },
})




export default class FieldWrapper extends Vue {

  FIELDS = FIELDS;
  @Prop() value!: any
  @Prop() isReadonly!: boolean;

  @Prop() editor!: boolean;
  @Prop() disableGroup!: boolean
  @Prop() showMissing!: boolean

  @Prop() template!: TEMPLATE_ELEMENT;


  @Prop() selectable!: boolean;

  prevValue = null;

  get isMissing(): boolean {
    if (!this.showMissing) return false;
    return isMissingData(this.value);
  }

  get isReview(): boolean {
    return (this.$route.query.review === 'true')
  }


  get condition(): CONDITION {
    return this.value.condition
  }

  get fitsCondition(): boolean {
    
    if (this.editor == true) return true;

    return checkCondition(this.condition)
  }


  get fieldStyling() {
    const baseStyling = this.isReadonly ? ' user-select: none!important;' : '';
    return baseStyling;

  }

}

import { CHECKLIST } from "../models/fields/checklist.model";
import { AlinoPDF } from "../pdf.class";



/**
 * checklist
 *
 * @param this (AlinoPDF) class scope
 * @param data (CHECKLIST) checklist input and config data
 * @param width (number | null) custom component width
 */
export async function checklist(this: AlinoPDF, data: CHECKLIST): Promise<void> {

  // initially all the heights must be calculated in order to determine, wether it fits onto the page or not

  let fieldHeight = 0; // field height to determine if field fits to remaining page; 


  // set text to bold if checklist is "checked" (aka true)
  this._textStyle(this.config.font.regular, "black", 11);
  if (data.value == true) {
    this._textStyle(this.config.font.bold, "black", 11);
  }

  // split header to fit to page
  const headerArr = this.docObj.splitTextToSize(data.header,  this.layout.innerWidth - 12);
  const headerHeight = this.docObj.getTextDimensions(headerArr).h;

  // if header has only one row of text, text is aligned to box center; otherwise text is aligned
  // to top of box 
  if (headerArr.length == 1) fieldHeight += 5.3;
  else fieldHeight += 3;

  fieldHeight += headerHeight + 2.5; // margin between header and descr

  // descr text style 
  // TODO: parse markdown
  this._textStyle(this.config.font.regular, this.config.color.secondary, 10);

  // likewise split descr array to fit to page
  const descrArr = this.docObj.splitTextToSize(data.options.descr, this.layout.innerWidth - 12);
  const descrHeight = this.docObj.getTextDimensions(descrArr).h;

  // bottom padding of field
  if (data.options != null && data.options.descr.length > 0) {
    fieldHeight += descrHeight + 2;
  } else {
    fieldHeight += 6;
  }

  // check if field still fits onto page
  if ((this.position.y + fieldHeight) > (this.layout.outerHeight - this.layout.innerBottom)) {
    await this.create(null, true);
    this.position.y = this.layout.innerTop;
    this.position.x = this.config.page.padding.left;
  }


  const initialY = this.position.y; // initial y-position
  this.box(data.value == true);

  this.position.y = initialY; // reset y-position to prev position
  this.position.x += 12; // offset to (including) box;

  // set text to bold if checklist is "checked" (aka true)
  this._textStyle(this.config.font.regular, "black", 11);
  if (data.value == true) {
    this._textStyle(this.config.font.bold, "black", 11);
  }

  // if header has only one row of text, text is aligned to box center; otherwise text is aligned
  // to top of box 
  if (headerArr.length == 1) this.position.y += 5.3;
  else this.position.y += 3;

  // header text
  this.docObj.text(headerArr, this.position.x, this.position.y);

  this.position.y += headerHeight + 2.5;
  this._textStyle(this.config.font.regular, this.config.color.secondary, 10);


  // only display header if set;
  if (
    data.options != null &&
    data.options.descr != null &&
    data.options.descr.length > 0
  ) {

    // add header
    this.docObj.text(descrArr, this.position.x, this.position.y - 1);

    this.position.y += descrHeight + 5; // bottom padding
  } else {
    this.position.y += 6; // padding to bottom without header
  }

  this.position.x = this.config.page.padding.left;
}

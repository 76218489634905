var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","width":"100%","position":"relative"},style:(_vm.readonly ? '  user-drag: none!important' : '')},[_c('v-overlay',{attrs:{"opacity":".7","absolute":"","value":!_vm.center}},[_c('NoDataTemplate',{attrs:{"iconColor":"white","text":"Keine Kartendaten vorhanden.","icon":"mdi-map-marker-off"}})],1),_c('GmapMap',{ref:"mapRef",staticStyle:{"border-radius":"10px!important","height":"100%","width":"100%"},style:(!_vm.center ? 'opacity: .3' : ''),attrs:{"center":_vm.centerPos == null ? { lat: 48.135029, lng: 11.581935 } : _vm.centerPos,"zoom":_vm.zoomLevel,"options":{
                  draggable: !_vm.readonly,
                  scrollwheel: !_vm.readonly,
                  fullscreenControl: !_vm.readonly,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                disableDefaultUi: false,
                styles: _vm.mapStyle,
              },"map-type-id":"terrain"}},[(_vm.path != null && _vm.path.length > 0)?_c('gmap-polyline',{ref:"polyline",attrs:{"options":{ strokeColor:'#CC202955'},"path":_vm.path,"editable":false}}):_vm._e(),_vm._l(((_vm.path || [])),function(center,index){return _c('gmap-custom-marker',{directives:[{name:"show",rawName:"v-show",value:(_vm.path.length > 0),expression:"path.length > 0"}],key:index,staticClass:"mt-2 ml-0",attrs:{"marker":center}},[_c('v-menu',{attrs:{"offset-x":"","offset-y":"","nudge-bottom":"10","open-on-hover":"","nudge-right":"10"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"primary white--text font-weight-bold path-marker",on:{"click":function($event){return _vm.focusPathMarker(center)}}},'div',attrs,false),on),[_c('div',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"}},[_vm._v(" "+_vm._s(index + 1)+" ")])])]}}],null,true)},[_c('div',{staticClass:"ma-0 white",staticStyle:{"max-width":"250px"}},[_vm._t("tooltip",null,{"item":center})],2)])],1)}),((_vm.path == null || _vm.path.length == 0) && _vm.marker == true)?_c('gmap-custom-marker',{attrs:{"marker":_vm.center}},[_c('div',{staticClass:"primary",staticStyle:{"height":"30px","width":"30px","border-radius":"15px","cursor":"default"}})]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { FIELDS } from "@/fields.types";
import axios from "axios";
import Vue from "vue";
import { Buffer } from 'buffer';

export function missingDataCheck(node: any): boolean {
  let isMissingField = false;
  if (node == null || node.length == 0) return false;
  node.children.forEach((field: any) => {
    // console.log(field.type, isMissingData(field))
    if (field.children != null && field.children.length > 0) {
      if (missingDataCheck(field) == true) isMissingField = true;
    } else if (isMissingData(field) == true) {
        console.log("MISSING: ", field)
        isMissingField = true
    }
    // console.log(field.type, isMissingData(field))
  });

  // console.log(isMissingField)

  return isMissingField;
}

function _clipboardFallback(textString: string): void {
  const textArea = document.createElement("textarea");
  textArea.value = textString;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }
  document.body.removeChild(textArea);
}

export function copy2Clipboard(textString: string): void {
  if (!navigator.clipboard) {
    return _clipboardFallback(textString);
  }
  navigator.clipboard.writeText(textString);
}

export function getRegFromString(str: string): RegExp {
  const a = str.split("/");
  const modifiers = a.pop();
  a.shift();
  const pattern = a.join("/");
  return new RegExp(pattern, modifiers);
}

export async function imageBase64(url: string, type: string) {
  try {
    const base64 = await axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) =>
        Buffer.from(response.data, "binary").toString("base64")
      );
    return `data:${type};base64,${base64}`; //Image Base64 Goes here
  } catch (err) {
    console.error(err)
    return;
  }
}


export async function download(url: string, name: string, fileType: string): Promise<void> {
    let base64 = url 
    console.log(url.startsWith(`data:${fileType};base64,`))
    if (!url.startsWith(`data:${fileType};base64,`)) {
    base64 = await axios
      .get(url, {responseType: 'arraybuffer'})
      .then((response) =>
        Buffer.from(response.data, 'binary').toString('base64'),
      );
    } else {
        base64 = base64.replace(`data:${fileType};base64,`, '')
    }

    console.log(base64)
    const a = document.createElement('a'); //Create <a>
    a.href = `data:${fileType};base64,${base64}`; //Image Base64 Goes here
    a.download = `${name}`; //File name Here
    a.click(); //Downloaded file
    a.remove();
  }

export function isMissingData(value: any): boolean {
  if (value.required == false || value.type == FIELDS.GROUP) return false;

  if (value.value == null) return true;
  // console.log(value.type, value.value)
  switch (value.type) {
    case FIELDS.USER:
      return value.value == null || value.value.length == 0
    case FIELDS.TEXTAREA:
      return value.value.length == 0;
    case FIELDS.TEXTFIELD: {
      if (value.options.regex.length > 0) {
        const res = value.value.match(getRegFromString(value.options.regex));
        if (res == null) return true;
        else return false;
      }
      return value.value.length == 0;
    }
    case FIELDS.BOOLEAN:
      return !(value.value == false || value.value == true);
    case FIELDS.RADIOS:
      return value.value == null;
    case FIELDS.CHECKBOX:
      return value.value == null || value.value.length == 0;
    case FIELDS.CHECKLIST:
      return !value.value;
    case FIELDS.ADDRESS:
      return (
        value.value.streetName.length == 0 ||
        value.value.zipCode.length == 0 ||
        value.value.city.length == 0
      );
    case FIELDS.CONTACT:
      if (value.options.multiple == true) {
        return value.value.some(
          (contact: any) =>
            contact.contactName.length == 0 ||
            (contact.mail.length == 0 && contact.phone.length == 0)
        );
      } else {
        return (
          value.value.contactName.length == 0 ||
          (value.value.mail.length == 0 && value.value.phone.length == 0)
        );
      }

      case FIELDS.IMAGE: 
        return value.value == null || value.value.length == 0
    case FIELDS.SELECTOR:
      return value.value == null;
    case FIELDS.SIGNATURE:
      return (
        value.value.saved != true ||
        (value.options.customer == true &&
          (value.value.name.length == 0 || value.value.mail.length == 0))
      );
    case FIELDS.DATE:
      if (value.options.hasTime == true) {
        if (
          value.value.time.start == null ||
          value.value.time.start.length == 0 ||
          value.value.time.end == null ||
          value.value.time.end.length == 0
        )
          return true;
      }
      // console.log("§H$ekjfnkejrf")
      if (value.options.isRange == true) {
        // console.log("lllooooH$kdldmflkm", value.value)
        return (
          value.value.date.start == null ||
          isNaN(value.value.date.start) ||
          value.value.date.start.length == 0 ||
          value.value.date.end == null ||
          isNaN(value.value.date.end) ||
          value.value.date.end.length == 0
        );
      } else {
        return value.value.date == null || value.value.date.length == 0;
      }
    default:
      return false;
  }
}


import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import TextInput from '@components/text/TextInput.vue'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VerifyDialog from '../dialog/VerifyDialog.vue';

import * as PinchZoom from '../../../../node_modules/pinch-zoom-js/dist/pinch-zoom.min.js';



@Component({
    components: {
        TextInput,
        VerifyDialog,
        VuePdfEmbed
    },
})




export default class PDFViewer extends Vue {
    @Prop() src!: string
    @Prop() zoom!: number

    @Prop() padding!: boolean
    password=''
    retryCount=0

    mobileZoom: any|null=null

    beforeDestroy() {
        if (this.mobileZoom != null) this.mobileZoom.destroy()
    }
    rendered(): void {
        this.$emit('rendered')

        this.$nextTick(() => {
            this.mobileZoom=new PinchZoom.default(document.getElementById('pinch-zoom-pdf'), {
                draggableUnzoomed: false,
            });

        })
    }


    async passwordRequest(data: any, retry: boolean): Promise<void> {
        this.password='';
        const response=await (this.$refs.verifyDialog as VerifyDialog).open();

        if (this.retryCount>5||response!=true||this.password.length==0) {
            this.$emit('error')
            this.retryCount=1;
            this.password='';
            return;
        }
        if (retry==true) this.retryCount+=1

        data(this.password)
    }

}

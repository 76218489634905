import { FIELDS } from "@/fields.types";
import { fieldExamples } from "@/input.examples";
import { TEMPLATE_ELEMENT } from "@/models/api/template.model";

import Papa from 'papaparse'

import emailjs from "emailjs-com";
import store from "@/store";
import { PDF_CONFIG } from "@pdf/models/config.model";
import { PDF_DATA } from "@pdf/models/data.model";
import Vue from "vue";
import { AlinoPDF } from "@pdf/pdf.class";
import { BASE_FIELD } from "@/models/exports/pdf/fields/field";
import axios from "axios";

export const fileConfig: PDF_CONFIG = {
  header: "test ",
  subheader: "neuer test2",
  color: {
    primary: "#cc2029",
    secondary: "#808080",
    grey: "#d0d0d0",
  },

  font: {
    bold: "Lato-Bold",
    regular: "Lato-Regular",
    light: "Lato-Light",
  },

  encryption: {
    allowEncryption: false,
    passwords: {
      user: "",
      master: "",
    },
  },

  page: {
    width: 210,
    height: 297,

    padding: {
      left: 20,
      right: 10,
      bottom: 10,
      top: 10,
    },

    header: {
      logo: {
        render: true,
        url: "https://firebasestorage.googleapis.com/v0/b/alinoreport.appspot.com/o/9566d676-ab65-4a01-ba06-94b8f9f07200%2Fgeneral%2Fcompany-logo?alt=media&token=db875e32-0ade-42f8-9f9b-f4f8f28f7b4b",
        fileType: "image/png",
        width: 100, 
        height: 10
      },
      pagination: true,
    },
    footer: {
      fontSize: 8,
      legal: [
        {
          header: "Gesellschaft",
          data: [
            "alinoTec GmbH & Co KG",
            "Hessenring 22, 64572 Büttelborn",
            "",
            "Dipl.-Ing (FH) Reinhardt Mähn",
            "Amtsgericht DA HRA 83682",
          ],
        },
        {
          header: "Kommunikation",
          data: [
            "Tel.: 06152 / 80798 - 0",
            "Fax.: 06152 / 80798 - 29",
            "",
            "Mail: info@alinotec.de",
            "Web: www.alinotec.de",
          ],
        },
        {
          header: "Komplementär",
          data: [
            "Reinhardt Mähn Verwaltungs-GmbH",
            "Spelzengasse 18",
            "65474 Bischhofsheim",
            "",
            "Amtsgericht DA HRB 86965",
          ],
        },
        {
          header: "Bankverbindung",
          data: [
            "Volksbank Südhessen-Darmstadt eG",
            "IBAN: DE44 5089 0000 0091 1371 19",
            "",
            "BIC: GENODEF1VBD",
            "USt-IdNr.: 259822458",
          ],
        },
      ],
    },
  },
};

export const EXPORT = {
  DOWNLOAD: 1,
  SHOW: 2,
  MAIL: 3,
  REQUEST_REVIEW: 4,
  FORWARD: 5,
  STATUS: 6,
  READONLY: 7,
  FINISH: 8,
  REOPEN: 50
};

function replaceVariable(template: TEMPLATE_ELEMENT, text: string): string {
  let textOutput = text;

  if (text.includes("{{")) {
    const splitList = text.split("{{");

    for (let eleIndex = 0; eleIndex < splitList.length; eleIndex++) {
      if (splitList[eleIndex].includes("}}")) {
        const varName = splitList[eleIndex].split("}}")[0];
        let varValue = null;

        try {
          const varResult = getVariableValue(template, varName) as any;

          if (varResult != null && varResult.value != null) {
            varValue = varResult.value;
          }
        } catch (error) {
          console.log(error);
        }

        if (varValue == null) varValue = "";

        textOutput = textOutput.replaceAll(`{{${varName}}}`, String(varValue));
      }
    }
  }

  return textOutput;
}




export async function exportReport(
  automations: Array<any>,
  template: any,
  preview = false
): Promise<string | null> {
    
  if (
    automations == null ||
    automations.length == 0 ||
    template.exports == null ||
    template.exports.length == 0
  )
    return null;

  for (let index = 0; index < automations.length; index++) {
    // console.log(automations[index])
    const exportFile = template.exports.reduce((a: any, b: any) =>
      a.id == automations[index].data.export ? a : b
    );
    // console.log("E", exportFile)

    let fileOutput: any = null;
    let fileSuffix = "";
    switch (exportFile.type) {
      case 1:
        if (!(exportFile.elements == null || exportFile.elements.length == 0)) {
            fileOutput = await createExportFile(exportFile, template, preview);
            fileSuffix = "pdf";
        }
      

        break;

      case 2:
        fileOutput = createJSONExport(exportFile, template, preview);

        if (automations[index].type != EXPORT.SHOW) {
          fileOutput =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(fileOutput));
        }
        fileSuffix = "json";
        break;

      default:
        break;
    }


    const link = document.createElement("a") as HTMLAnchorElement;
    let toMail = "";

    switch (automations[index].type) {
      case EXPORT.DOWNLOAD:
        (link as any).download = `${exportFile.name}.${fileSuffix}`;
        link.href = fileOutput;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        break;

      case EXPORT.SHOW:
        return fileOutput;

      case EXPORT.MAIL:
        if (automations[index].data.toVariable != null) {
          const contactObj = getVariableValue(
            template,
            automations[index].data.toVariable
          ) as any;
          // console.log(contactObj.value.mail, automations[index].data.toVariable)
          if (
            contactObj != null &&
            contactObj.value != null &&
            contactObj.value.mail != null
          ) {
            toMail = contactObj.value.mail || "";
          }
        } else {
          toMail = automations[index].data.to || "";
        }

        automations[index].data.subject = replaceVariable(
          template,
          automations[index].data.subject
        );
        automations[index].data.content = replaceVariable(
          template,
          automations[index].data.content
        );

        if (toMail == null || toMail.length == 0) {
          const resp = window.prompt(
            "Bitte geben Sie eine gültige Empfänger E-Mail an:"
          );
          if (resp == null) return fileOutput;
          else {
            toMail = resp;
          }
        }

        try {
          //  console.log("l", automations[index].data.subject)
          await emailjs.send(
            "service_x6o78aq",
            "template_3dx7web",
            {
              subject: automations[index].data.subject,
              to: toMail,
              cc: automations[index].data.cc,
              content: automations[index].data.content,
              bcc: automations[index].data.bcc,
              filename: `${exportFile.name.toLowerCase()}.${fileSuffix}`,

              // attachment: fileOutput,
              jsonAttachment: fileSuffix == "json" ? fileOutput : null,
              pdfAttachment: fileSuffix == "pdf" ? fileOutput : null,
            },
            "2HDzevv9wlRiWaHkJ"
          );
        } catch (error) {
          Vue.prototype.$toast.error("Mail konnte nicht versendet werden");
        }

        break;

      default:
        break;
    }
  }

  console.log(automations);
  console.log("T", template);

  return "templateId";
}

function createJSONExport(
  exportTemplate: any,
  template: any,
  preview: boolean
): any {
  const exportElements =
    getElementVariables(
      JSON.parse(JSON.stringify(template)),
      {},
      exportTemplate,
      preview
    ) || {};

  if (exportElements == null || exportElements.length == 0) return null;
  return exportElements;
}



function listChildren(node: any, exportTemplate: any): Array<any> | null {
  if (node.children == null || node.children.length == 0) return null;
  else {
    return node.children.map((child: any) => {
      if (child.type == FIELDS.GROUP) {
        const groupResp = listChildren(child, exportTemplate);

        if (groupResp == null || groupResp.length == 0) return null;
        else return { ...child, children: groupResp.flat().filter((a) => a) };
      }
      if (exportTemplate.elements.some((ele: any) => ele == child.id) == true)
        return child;
      else return null;
    });
  }
}

function getElementVariables(
  ele: any,
  handle: any,
  exportTemplate: any,
  preview: boolean
): any {
  if (ele.children != null && ele.children.length > 0) {
    ele.children.forEach((element: any) => {
      handle = getElementVariables(element, handle, exportTemplate, preview);
    });
  } else if (ele.variable != null && exportTemplate.elements.includes(ele.id)) {
    if (preview == true) {
      handle[ele.variable] = mapExampleValues(ele).value;
    } else {
      handle[ele.variable] = ele.value;
    }
  }

  return handle;
}

export function getVariableValue(node: any, varId: string): any {
    if (node == null || node.children == null) return []
  return node.children
    .map((child: any) => {
      if (child.type == FIELDS.GROUP && child.children.length > 0)
        return getVariableValue(child, varId);

      return child;
    })
    .flat()
    .reduce((a: any, b: any) => (a.variable == varId ? a : b));
}

export async function createExportFile(
  exportTemplate: any,
  template: any,
  preview: boolean
): Promise<string | null> {

    console.log("EXPORT ")
    // console.log("ORGANISATION: ", store.getters.organisation.general)
    // console.log(store.getters.logo);
//   const image = await localStorage.getItem(`organisations-logo`);

  // fileConfig. = (imageObj as any);
  // fileConfig.page.colors = store.getters.colors;


//   const logo = store.getters.organisation.general.logo
  // console.log(logo); 
//   const logoStr = 
const stylingObj = store.getters.styling;

fileConfig.header = exportTemplate.options.title
fileConfig.subheader = exportTemplate.options.subtitle
  fileConfig.page.header.logo.url = await Vue.prototype.$firebase.storage.get(stylingObj.logo.path) || stylingObj.logo.url;
  fileConfig.page.header.logo.fileType = stylingObj.logo.type;
  fileConfig.page.header.logo.width = stylingObj.logo.width;
  fileConfig.page.header.logo.height = stylingObj.logo.height;

  fileConfig.page.footer.legal = (stylingObj.legal.map((ele: any) => ({header: ele.header, data: (Object.values(ele)).slice(0, (Object.values(ele).length - 1))})));
  fileConfig.color = stylingObj.colors;
//   console.log(fileConfig.page.footer.legal)
  const exportElements = JSON.parse(
    JSON.stringify(listChildren({children: template.steps}, exportTemplate) || [])
  )
    .filter((a: any) => a)
    .filter(
      (a: any) =>
        a.type != FIELDS.GROUP ||
        (a.type == FIELDS.GROUP && a.children.length > 0)
    );

    // console.log("M")
    // console.log(exportElements, exportTemplate, template.steps, template.steps)
  if (exportElements == null || exportElements.length == 0) return null;

  // console.log("M2")
  if (
    exportTemplate.options.encryption.enabled == true &&
    exportTemplate.options.encryption.variable != null &&
    exportTemplate.options.encryption.variable.length > 0
  ) {
    // console.log("ENV");
    const varValue = getVariableValue(
      template,
      exportTemplate.options.encryption.variable
    );

    fileConfig.encryption = {
      allowEncryption: !preview,
      passwords: {
        user:
          varValue.length == 0
            ? exportTemplate.options.encryption.master
            : varValue,
        master: exportTemplate.options.encryption.master,
      },
    };
  } else {
    fileConfig.encryption = {
      allowEncryption: false,
      passwords: {
        user: "",
        master: "",
      },
    };
  }


  
  if (preview == true) {
    exportElements.map((ele: any) => mapExampleValues(ele));
    // console.log("E", exportElements)
  }

  // console.log("HEREr")
  const reportObj = {
    children: exportElements,
  };


  const hasInventory = (tree: any) => {
    let result = false
    if (tree.type == FIELDS.INVENTORY) {
      return true
    } else {
      if (tree.children) {
        tree.children.some((node: BASE_FIELD) => result = hasInventory(node));
      }
      return result;
    }
  }


  let inventoryArr = {elements: [], identifier: []}
  if (hasInventory({children: exportElements})) {
    const inventory=store.getters.organisation.inventory.file;

        // console.log(inventory)
        const resp=await Vue.prototype.$firebase.storage.get(inventory.path);

        const csvResponse=await axios.get(resp, { responseType: 'blob' })

        if (csvResponse.status!=200||csvResponse.data==null) throw new Error();

        // console.log(await csvResponse.data.text())
        const data=await Papa.parse(await csvResponse.data.text(), { header: true })
        console.log("ele", data )
        inventoryArr = {elements: (data as any).data as any, identifier: store.getters.organisation.inventory.identifier};

  }
//   console.log("ccccc", fileConfig)
  const pdfHandle = new AlinoPDF("te", fileConfig);

  console.log(reportObj);
  await pdfHandle.create({...reportObj, inventory: inventoryArr} as any);
  const exportT = pdfHandle.export(false);
  return exportT
}

function mapExampleValues(element: any): any {
  // console.log("MA", element.type)
  switch (element.type) {
    case FIELDS.TEXTFIELD:
      if (element.options.isNumber == true) {
        element.value == Math.floor(Math.random() * 500);
      } else {
        element.value = (fieldExamples as any)[element.type as string][
          Math.floor(
            Math.random() *
              (fieldExamples as any)[element.type as string].length
          )
        ];
      }
      break;

    case FIELDS.TEXTAREA:
      element.value = (fieldExamples as any)[element.type as string][
        Math.floor(
          Math.random() * (fieldExamples as any)[element.type as string].length
        )
      ];
      console.log(element.value);
      break;

    case FIELDS.BOOLEAN:
      element.value = Math.random() < 0.5;

      break;

    case FIELDS.SELECTOR:
      element.value = 0;
      break;

    case FIELDS.RADIOS:
      element.value = 0;
      break;

    case FIELDS.CHECKBOX:
      element.value = [0, 1, 2, 3, 4, 5, 6];
      break;

    case FIELDS.GROUP:
      if (element.options.repeatable != true) break;
      element.children = [...element.pattern, ...element.pattern];
      break;

    case FIELDS.INVENTORY:
      element.value = { "A0000002": 2,
      "A0000240": 2,
          
      }
      break;

    case FIELDS.SIGNATURE:
      element.value = { name: "Max Mustermann", id: "dead-beef" };
      localStorage.setItem(
        "dead-beef",
        (fieldExamples as any)[FIELDS.SIGNATURE][
          Math.floor(
            Math.random() *
              (fieldExamples as any)[element.type as string].length
          )
        ]
      );
      break;

    case FIELDS.IMAGE:
      element.value = [
        {
            type: 'image/jpg',
            width: 1260, 
            height: 750,
            url: "https://images.pexels.com/photos/6442461/pexels-photo-6442461.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        },
        {
            type: 'video/mp4',
            url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4?http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        }

      ];
      break;
    default:
      if ((fieldExamples as any)[element.type as string] != null) {
        element.value = (fieldExamples as any)[element.type as string][
          Math.floor(
            Math.random() *
              (fieldExamples as any)[element.type as string].length
          )
        ];
      }

      break;
  }

  if (element.children == null) return element;

  return {
    ...element,
    children: element.children.map((child: any) => mapExampleValues(child)),
  };
}

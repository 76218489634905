

    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import TextButton from '@components/button/TextButton.vue'
    import DialogTemplate from '@components/dialog/DialogTemplate.vue'
    import IconButton from '@components/button/IconButton.vue'
    @Component({
        components: {
            IconButton,
            DialogTemplate,
            TextButton
        },
    })
    
    
    
    export default class VerifyDialog extends Vue {

        @Prop() continueText!: string
        @Prop() discardText!: string
        @Prop() text!: string
        @Prop() title!: string
        @Prop() persistent!: boolean;
        @Prop() icon!: string
        @Prop() discardIcon!: boolean
   
        dialogStatus = false; 
        verified = false;

        open(): Promise<any | null> {
    this.dialogStatus = true;

    return new Promise((resolve) => {
      var unwatch = this.$watch("dialogStatus", (value) => {
        if (!value) {
          resolve(this.verified);
          unwatch();
        }
      });
    });
  }
    }
    
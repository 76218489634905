import { render, staticRenderFns } from "./InventoryElement.vue?vue&type=template&id=4d6d603a&scoped=true&"
import script from "./InventoryElement.vue?vue&type=script&lang=ts&"
export * from "./InventoryElement.vue?vue&type=script&lang=ts&"
import style0 from "./InventoryElement.vue?vue&type=style&index=0&id=4d6d603a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d6d603a",
  null
  
)

export default component.exports
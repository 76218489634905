import { AlinoPDF, SPACER } from "../pdf.class";


const TEXTFIELD_PADDING = {
  LEFT: 3, 
  RIGHT: 3
}

/**
 * textfield 
 * 
 * @param this (AlinoPDF) class scope
 * @param data (TEXTFIELD) textfield input and config data
 * @param width (number | null) custom component width
 */
export async function box(this: AlinoPDF, value: boolean):  Promise<void> {



    this.docObj.setDrawColor(this.config.color.grey);
  this.docObj.setLineWidth(0.2);

  const widthHeight = 7.88


  // draw square box rectangle (no fill)
  this.docObj.roundedRect(this.position.x, this.position.y, widthHeight , widthHeight, .75, .75);
  this.position.y += 3.3

  
  if (value == true) {
    this._textStyle(this.config.font.light, 'black', 20);

    this.docObj.text('x', this.position.x + widthHeight/2 - 1.6, this.position.y - 1.6  + widthHeight/2)
  }

  this.position.y += widthHeight;


}



import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


import IconButton from '@components/button/IconButton.vue';
//   import OptionsTemplate from '@/components/helper/OptionsTemplate.vue';

@Component({
    components: {
        IconButton
    },
})


export default class Information extends Vue {

    @Prop() error!: boolean;
    @Prop() warning!: boolean;
    @Prop() closable!: boolean
    @Prop() title!: string;
    @Prop() dark!: boolean
    @Prop() text!: string;
    @Prop() highlight!: boolean
    @Prop() icon!: string// = 'mdi-information'


    closed = false;

    mounted(): void {
        this.closed = false;
    }

    @Watch('closable', {immediate: true})
    onCloseableChange():void {
        this.closed = false
    }
}
